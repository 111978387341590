import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Banner from "../../../components/molecules/banner/Banner";
import Breadcrumbs from "../../../components/molecules/breadcrumbs/Breadcrumbs";
import FiltersGroup from "../../../components/organisms/filtersGroup/FiltersGroup";
import AccountSubscriptionBills from "./tabs/accountSubscriptionBills/AccountSubscriptionBills";
import AccountProfile from "./tabs/accountProfile/AccountProfile";
import AccountFormations from "./tabs/accountFormations/AccountFormations";
import AccountTools from "./tabs/accountTools/AccountTools";
import AccountFolders from "./tabs/accountFolders/AccountFolders";
import AccountTeams from "./tabs/accountTeams/AccountTeams";
import FolderDetail from "./tabs/accountFolders/FolderDetail";
import FolderInfo from "./tabs/accountFolders/tabs/folderInfo/FolderInfo";
import { Link } from "react-router-dom";
import ArrowSm from "../../../components/atoms/icons/general/arrowSm/ArrowSm";
import IncrustedVideo from "../../../components/organisms/inscrustedVideo/IncrustedVideo";
import AppContext from "../../../context/AppContext";

function MonCompte() {
  const [currentTab, setCurrentTab] = useState(0);
  const [pageInfos, stePageInfos] = useState({ title: "", subtitle: "" });
  const [activeTabIndexChanger, setActiveTabIndexChanger] = useState(undefined);
  const [isTutoVideoVisible, setIsTutoVideoVisible] = useState(false);

  const { createNotification } = useContext(AppContext);
  const navbarRef = useRef();
  const navigate = useNavigate();
  const { tabParam, subTabParam } = useParams();
  const { setBannerConfig, setBreadcrumbsSpecialValue } = useContext(AppContext);
  const location = useLocation();

  useEffect(() => {
    setBannerConfig({ title: <>Mon compte</> });
    CheckIfShowAccountTutoModal();
  }, []);

  useEffect(() => {
    let tab = 0;

    if (tabParam) {
      switch (tabParam) {
        case "mes-outils":
          tab = 0;
          // setBreadcrumbsSpecialValue({
          //   to: "/mes-outils",
          //   name: "Mes outils",
          // });
          break;
        case "mes-dossiers":
          tab = 1;
          setBreadcrumbsSpecialValue({
            to: "/mes-dossiers",
            name: "Mes dossiers",
          });
          break;
        case "mes-formations":
          tab = 2;
          setBreadcrumbsSpecialValue({
            to: "/mes-formations",
            name: "Mes formations",
          });
          break;
        case "mon-equipe":
          tab = 3;
          setBreadcrumbsSpecialValue({
            to: "/mon-equipe",
            name: "Mon équipe",
          });
          break;
        case "mon-profil":
          tab = 4;
          setBreadcrumbsSpecialValue({
            to: "/mon-profil",
            name: "Mon profil",
          });
          break;
        case "mes-abonnements-factures":
          tab = 5;
          setBreadcrumbsSpecialValue({
            to: "/mes-abonnements-factures",
            name: "Mes abonnements & factures",
          });
          break;
      }
    } else {
      tab = 0;
      setBreadcrumbsSpecialValue({
        title: "Mes dossiers",
        to: "/mes-dossiers",
      });
    }

    setCurrentTab(tab);
    setActiveTabIndexChanger(tab);
  }, [location.pathname]);

  function changeTab(tabId, url) {
    setCurrentTab(tabId);
    navigate(`/mon-compte/${url}`);
  }

  function onWatchTutoLater() {
    setIsTutoVideoVisible(false);
    localStorage.setItem("has_watched_account_tuto", "0");
  }

  function onCloseTutoVideo() {
    setIsTutoVideoVisible(false);
    localStorage.setItem("has_watched_account_tuto", "1");
  }

  function CheckIfShowAccountTutoModal() {
    if (localStorage.getItem("has_watched_account_tuto") !== "1") setIsTutoVideoVisible(true);
  }

  return (
    <div className='page-container'>
      <div className='sticky-container' ref={navbarRef}>
        <FiltersGroup
          changeActiveTabIndex={activeTabIndexChanger}
          data={[
            {
              name: "Mes outils",
              onClick: () => changeTab(0, "mes-outils"),
              params: [0],
            },
            {
              name: "Mes dossiers",
              onClick: () => changeTab(1, "mes-dossiers"),
              params: [1],
            },
            {
              name: "Mes formations",
              onClick: () => changeTab(2, "mes-formations"),
              params: [2],
            },
            {
              name: "Mon équipe",
              onClick: () => changeTab(3, "mon-equipe"),
              params: [3],
            },
            {
              name: "Mon profil",
              onClick: () => changeTab(4, "mon-profil"),
              params: [4],
            },
            {
              name: "Mes abonnements & factures",
              onClick: () => changeTab(5, "mes-abonnements-factures"),
              params: [5],
            },
          ]}
        />
      </div>
      <IncrustedVideo
        isVideoVisible={isTutoVideoVisible}
        onWatchLater={onWatchTutoLater}
        onCloseVideo={onCloseTutoVideo}
        videoUri={require("../../../uploads/videos/files/account_tuto_video.mp4")}
      />
      {currentTab === 0 && (
        <AccountTools setIsTutoVideoVisible={setIsTutoVideoVisible} isVideoVisible={isTutoVideoVisible} />
      )}
      {currentTab === 1 && (subTabParam ? <FolderDetail /> : <AccountFolders />)}
      {currentTab === 2 && <AccountFormations />}
      {currentTab === 3 && <AccountTeams />}
      {currentTab === 4 && <AccountProfile navbarRef={navbarRef} />}
      {currentTab === 5 && <AccountSubscriptionBills />}
    </div>
  );
}

export default MonCompte;
