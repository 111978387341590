import { Link } from "react-router-dom";
import { normalizeString } from "../../../../../utils/Utils";
import Pen from "../../../../../components/atoms/icons/account/pen/Pen";
import Download from "../../../../../components/atoms/icons/general/download/Download";
import Duplicate from "../../../../../components/atoms/icons/account/duplicate/Duplicate";
import Trash from "../../../../../components/atoms/icons/general/trash/Trash";
import Unarchive from "../../../../../components/atoms/icons/account/unarchive/Unarchive";
import Archive from "../../../../../components/atoms/icons/account/archive/Archive";

export const CreditSimulationTabColumns = [
  {
    id: 1,
    label: "UUID",
    key: "uuid",
    default: 0,
  },
  {
    id: 2,
    label: "Intitulé",
    key: "name",
    default: 0,
    showBracket: true,
  },
  {
    id: 4,
    label: "Création",
    key: "date_creation",
    default: 992,
    showBracket: true,
  },
  {
    id: 5,
    label: "Mise à jour",
    key: "date_last_update",
    default: 992,
    showBracket: true,
  },
  {
    id: 7,
    label: "",
    key: "archived",
    default: 576,
  },
];

export function getCreditSimulationColumnContent(key, item) {
  switch (key) {
    case "date_last_update":
    case "date_creation":
      return item[key] || "-";
    default:
      return item[key];
  }
}

export function filterCreditSimulations(items, searchValue) {
  let normalizedSearchValue = normalizeString(searchValue);

  return items?.filter(item => {
    if (item.uuid === searchValue || normalizeString(item.name).includes(normalizedSearchValue)) return true;

    return false;
  });
}
export const CreditSimulationDropdownMenu = ({
  activeItem,
  downloadItemPdf,
  showDuplicateItemModal,
  archiveItem,
  removeFromFolder,
  removeFromDeliverable,
}) => (
  <>
    <li>
      <Link
        to={`/simulateur-credit/${activeItem?.uuid}`}
        onClick={e => {
          e.preventDefault();
          window.open(`/simulateur-credit/${activeItem?.uuid}`);
        }}>
        <Pen width='18px' />
        Modifier
      </Link>
    </li>
    {downloadItemPdf && (
      <li>
        <button onClick={() => downloadItemPdf(activeItem?.uuid)}>
          <Download color='var(--dark-blue)' width='18px' />
          Télécharger
        </button>
      </li>
    )}
    {showDuplicateItemModal && (
      <li>
        <button onClick={() => showDuplicateItemModal(activeItem?.uuid, activeItem?.name)}>
          <Duplicate width='18px' />
          Dupliquer
        </button>
      </li>
    )}
    {removeFromFolder && (
      <li>
        <button onClick={() => removeFromFolder(activeItem?.uuid)}>
          <Trash width='18px' />
          Retirer du dossier
        </button>
      </li>
    )}
    {removeFromDeliverable && (
      <li>
        <button onClick={() => removeFromDeliverable(activeItem?.uuid)}>
          <Trash width='18px' />
          Retirer du livrable
        </button>
      </li>
    )}
    {archiveItem &&
      (activeItem?.archived ? (
        <li>
          <button onClick={() => archiveItem(activeItem?.uuid, false)}>
            <Unarchive width='18px' />
            Désarchiver
          </button>
        </li>
      ) : (
        <li>
          <button onClick={() => archiveItem(activeItem?.uuid, true)}>
            <Archive width='18px' />
            Archiver
          </button>
        </li>
      ))}
  </>
);
