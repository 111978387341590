import axiosInstance from "./AxiosConfig";

export const fetchPricesWithUuidApi = async userUuid => axiosInstance.get(`/payment/get-offers/${userUuid}`);

export const fetchPricesApi = async () => axiosInstance.get(`/payment/get-offers/all`);

export const createPaymentLink = async (userUuid, offerUuid, redirection) =>
  axiosInstance.post("/payment/create-checkout-session", {
    user_id: userUuid,
    cart_item: {
      quantity: 1,
      offer_id: offerUuid,
    },
    success_url: redirection,
  });
