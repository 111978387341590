import { Link } from "react-router-dom";
import Duplicate from "../../../../../components/atoms/icons/account/duplicate/Duplicate";
import Pen from "../../../../../components/atoms/icons/account/pen/Pen";
import Download from "../../../../../components/atoms/icons/general/download/Download";
import FilledButton from "../../../../../components/molecules/buttons/filledButton/FilledButton";
import { normalizeString } from "../../../../../utils/Utils";
import Eye from "../../../../../components/atoms/icons/general/eye/Eye";
import Unarchive from "../../../../../components/atoms/icons/account/unarchive/Unarchive";
import Archive from "../../../../../components/atoms/icons/account/archive/Archive";

export const FolderTabColumns = [
  {
    id: 1,
    label: "UUID",
    key: "uuid",
    default: 0,
  },
  {
    id: 6,
    label: "Intitulé",
    key: "folder_name",
    default: 0,
    showBracket: true,
  },
  {
    id: 9,
    label: "Nb estim°",
    key: "estimations_count",
    default: 1200,
    showBracket: true,
  },
  {
    id: 2,
    label: "Nb plans",
    key: "financing_plans_count",
    showBracket: true,
  },
  {
    id: 3,
    label: "Nb sim° crédit",
    key: "credit_simulators_count",
    showBracket: true,
  },
  {
    id: 4,
    label: "Nb livrables",
    key: "total_deliverables",
    default: 1200,
    showBracket: true,
  },
  {
    id: 5,
    label: "Création",
    key: "date_creation",
    default: 992,
    showBracket: true,
  },
  {
    id: 10,
    label: "Mise à jour",
    key: "date_last_update",
    showBracket: true,
  },
  {
    id: 7,
    label: "",
    key: "archived",
    default: 576,
  },
];

export function getFolderColumnContent(key, item) {
  switch (key) {
    case "date_last_update":
    case "date_creation":
      return item[key] || "-";
    default:
      return item[key];
  }
}

export function filterFolders(items, searchValue) {
  let normalizedSearchValue = normalizeString(searchValue);

  return items?.filter(item => {
    if (item.uuid === searchValue || normalizeString(item.folder_name).includes(normalizedSearchValue)) return true;

    return false;
  });
}

export const folderDropdownMenu = ({ activeItem, downloadItemPdf, showDuplicateItemModal, archiveItem }) => (
  <>
    <li>
      <Link
        to={`/mon-compte/mes-dossiers/${activeItem?.uuid}`}
        onClick={e => {
          e.preventDefault();
          window.open(`/mon-compte/mes-dossiers/${activeItem?.uuid}`);
        }}>
        <Pen width='18px' />
        Modifier
      </Link>
    </li>
    {downloadItemPdf && (
      <li>
        <button onClick={() => downloadItemPdf(activeItem?.uuid)}>
          <Download color='var(--dark-blue)' width='18px' />
          Télécharger
        </button>
      </li>
    )}
    {showDuplicateItemModal && (
      <li>
        {console.log(activeItem, activeItem?.folder_name)}
        <button onClick={() => showDuplicateItemModal(activeItem?.uuid, activeItem?.folder_name)}>
          <Duplicate width='18px' />
          Dupliquer
        </button>
      </li>
    )}
    {archiveItem &&
      (activeItem?.archived ? (
        <li>
          <button onClick={() => archiveItem(activeItem?.uuid, false)}>
            <Unarchive width='18px' />
            Désarchiver
          </button>
        </li>
      ) : (
        <li>
          <button onClick={() => archiveItem(activeItem?.uuid, true)}>
            <Archive width='18px' />
            Archiver
          </button>
        </li>
      ))}
  </>
);
