export const breadcrumbsCases = [
  // Estimer
  {
    pattern: "/philosophie",
    value: [
      {
        name: "Estimer : Méthode & philosophie",
        to: "/philosophie",
      },
    ],
  },
  {
    pattern: "/exemples-rapports",
    value: [
      {
        name: "Estimer : Exemples de rapports",
        to: "/exemples-rapports",
      },
    ],
  },
  {
    pattern: "/liste-activites",
    value: [
      {
        name: "Estimer : Liste des activités estimables",
        to: "/liste-activites",
      },
    ],
  },
  {
    isSpecial: true,
    pattern: "/debut-parcours",
    condition: ["/mon-compte/mes-outils/mes-estimations"],
    conditionalValue: [
      {
        name: "Mon compte",
        to: "/mon-compte/mes-outils/mes-estimations",
      },
      {
        name: "Mes outils : Estimations",
        to: "/mon-compte/mes-outils/mes-estimations",
      },
    ],
    value: [
      {
        name: "Estimer : Créer une estimation",
        to: "/debut-parcours",
      },
    ],
  },
  {
    isSpecial: true,
    useSpecialValue: true,
    pattern: "/detail-parcours",
    condition: ["/synthese"],
    conditionalValue: [
      {
        name: "Mon compte",
        to: "/mon-compte/mes-outils/mes-estimations",
      },
      {
        name: "Mes outils : Estimations",
        to: "/mon-compte/mes-outils/mes-estimations",
      },
    ],
    value: [
      {
        name: "Estimer : Mon estimation",
        to: "/detail-parcours",
      },
    ],
  },
  {
    isSpecial: true,
    useSpecialValue: true,
    pattern: "/synthese",
    condition: ["/mon-compte/mes-outils/mes-estimations"],
    conditionalValue: [
      {
        name: "Mon compte",
        to: "/mon-compte/mes-outils/mes-estimations",
      },
      {
        name: "Mes outils : Estimations",
        to: "/mon-compte/mes-outils/mes-estimations",
      },
    ],
    value: [
      {
        name: "Synthèse : ",
        to: "/synthese/",
      },
    ],
  },
  // Outils
  {
    pattern: "/outils-pratiques",
    value: [
      {
        name: "Outils : Outils pratiques",
        to: "/outils-pratiques",
      },
    ],
  },
  {
    pattern: "/calculateur-cotisations",
    value: [
      {
        name: "Outils : Calculateur de cotisations",
        to: "/calculateur-cotisations",
      },
    ],
  },
  {
    pattern: "/lexique",
    value: [
      {
        name: "Outils : Lexique commercial",
        to: "/lexique",
      },
    ],
  },
  {
    pattern: "/faq",
    value: [
      {
        name: "Outils : Foire aux questions",
        to: "/faq",
      },
    ],
  },
  // Conseils
  {
    useSpecialValue: true,
    pattern: "/conseils-pros/",
    value: [
      {
        name: "Conseils : Conseils de pros",
        to: "/conseils-pros",
      },
      {
        name: "",
        to: "/conseils-pros/",
      },
    ],
  },
  {
    pattern: "/conseils-pros",
    value: [
      {
        name: "Conseils : Conseils de pros",
        to: "/conseils-pros",
      },
    ],
  },
  {
    pattern: "/guides-agents-immos/",
    useSpecialValue: true,
    value: [
      {
        name: "Conseils: Nos guides pour agents immos",
        to: "/guides-agents-immos",
      },
      {
        name: "",
        to: "/guides-agents-immos/",
      },
    ],
  },
  {
    pattern: "/guides-agents-immos",
    value: [
      {
        name: "Conseils: Nos guides pour agents immos",
        to: "/guides-agents-immos",
      },
    ],
  },
  // Tarifs
  {
    pattern: "/tarifs",
    value: [
      {
        to: "/tarifs",
        name: "Tarifs",
      },
    ],
  },
  // Financement
  {
    pattern: "/simulateur-credit",
    isSpecial: true,
    useSpecialValue: true,
    condition: ["/mon-compte/mes-outils/mes-tableaux-d-amortissement"],
    conditionalValue: [
      {
        name: "Mes outils : Simulations de prêt",
        to: "/mon-compte/mes-outils/mes-tableaux-d-amortissement",
      },
    ],
    value: [
      {
        to: "/simulateur-credit",
        name: "Financement : Simulateur de crédit",
      },
    ],
  },
  {
    pattern: "/plan-financement",
    isSpecial: true,
    useSpecialValue: true,
    condition: ["/mon-compte/mes-outils/mes-plans-de-financement"],
    conditionalValue: [
      {
        name: "Mon compte",
        to: "/mon-compte/mes-outils/mes-estimations",
      },
      {
        name: "Mes outils : Plans de financement",
        to: "/mon-compte/mes-outils/mes-plans-de-financement",
      },
    ],
    value: [
      {
        to: "/plan-financement",
        name: "Financement : Plan de financement",
      },
    ],
  },
  {
    pattern: "/obtenir-financement",
    value: [
      {
        to: "/obtenir-financement",
        name: "Financement : Obtenir un financement",
      },
    ],
  },
  // Compte
  {
    pattern: "/mon-compte",
    useSpecialValue: true,
    value: [
      {
        to: "/mon-compte",
        name: "Mon compte : ",
      },
    ],
  },
  // Légal
  {
    pattern: "/politique-confidentialite",
    value: [
      {
        to: "/politique-confidentialite",
        name: "Liens légaux : Politique de confidentialité",
      },
    ],
  },
  {
    pattern: "/cgu",
    value: [
      {
        to: "/cgu",
        name: "Liens légaux : Conditions générales d'utilisation",
      },
    ],
  },
  {
    pattern: "/cgv",
    value: [
      {
        to: "/cgv",
        name: "Liens légaux : Conditions générales de vente",
      },
    ],
  },
  {
    pattern: "/mentions-legales",
    value: [
      {
        to: "/mentions-legales",
        name: "Liens légaux : Mentions légales",
      },
    ],
  },
  {
    pattern: "/parametrage-cookies",
    value: [
      {
        to: "/parametrage-cookies",
        name: "Cookies : Gérer des cookies",
      },
    ],
  },
  {
    pattern: "/contact",
    value: [
      {
        to: "/contact",
        name: "Nous contacter",
      },
    ],
  },
  // Connexion / Inscription
  {
    pattern: "/connexion",
    value: [
      {
        to: "/connexion",
        name: "Connexion",
      },
    ],
  },
  {
    pattern: "/inscription",
    value: [
      {
        to: "/inscription",
        name: "Inscription",
      },
    ],
  },
  // Panier / Commande
  {
    pattern: "/panier",
    value: [
      {
        name: "Panier",
        to: "/panier",
      },
    ],
  },
  {
    pattern: "/commande",
    value: [
      {
        name: "Panier",
        to: "/panier",
      },
      {
        name: "Ma commande",
        to: "/commande",
      },
    ],
  },
  // Admin
  {
    pattern: "/admin/editeur-articles",
    value: [
      {
        to: "/admin/crm",
        name: "CRM",
      },
      {
        to: "/admin/crm/editeur-articles",
        name: "Editeur d'articles",
      },
    ],
  },
];
