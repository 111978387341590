import axiosInstance from "./AxiosConfig";

export const cancelAutoRenewalApi = async subscriptionUuid =>
  axiosInstance.put(`/crm/users/subscriptions/cancel-auto-renewal/${subscriptionUuid}`);

export const cancelSubscriptionApi = async subscriptionUuid =>
  axiosInstance.put(`/crm/users/subscriptions/cancel/${subscriptionUuid}`);

export const fetchClientBillingLinkApi = async (clientUuid, redirection) =>
  axiosInstance.post(`/payment/create-billing-session`, {
    user_id: clientUuid,
    return_url: redirection,
  });

export const offerSubscriptionApi = async values => axiosInstance.post(`/crm/users/subscriptions/gift`, values);

export const updateFreeSubscriptionApi = async (subscriptionUuid, values) =>
  axiosInstance.put(`/crm/users/subscriptions/gift/edit/${subscriptionUuid}`, values);

export const deleteFreeSubscriptionApi = async subscriptionUuid =>
  axiosInstance.delete(`/crm/users/subscriptions/gift/delete/${subscriptionUuid}`);
