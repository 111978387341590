import React, { useContext, useState, useRef, useEffect } from "react";
import "./FolderCreditSimulation.css";
import FilledButton from "../../../../../../../components/molecules/buttons/filledButton/FilledButton";
import Add from "../../../../../../../components/atoms/icons/general/add/Add";
import Download from "../../../../../../../components/atoms/icons/general/download/Download";
import { useWindowSize } from "@uidotdev/usehooks";
import Tuto from "../../../../../../../components/atoms/icons/general/tuto/Tuto";
import Rapport from "../../../../../../../components/atoms/icons/header/rapport/Rapport";
import { useNavigate } from "react-router-dom";
import UnfilledButton from "../../../../../../../components/molecules/buttons/unfilledButton/UnfilledButton";
import AppContext from "../../../../../../../context/AppContext";
import {
  deleteAllCreditSimulatorsFromFolderApi,
  deleteCreditSimulatorFromFolderApi,
} from "../../../../../../../api/FolderApi";
import Bracket from "../../../../../../../components/atoms/icons/general/bracket/Bracket";
import Pen from "../../../../../../../components/atoms/icons/account/pen/Pen";
import Trash from "../../../../../../../components/atoms/icons/general/trash/Trash";
import ImportCreditSimulatorsFolderModal from "../../../../../../../components/forms/modals/account/importCreditSimulatorsFolderModal/ImportCreditSimulatorsFolderModal";
import { useAuth } from "../../../../../../../context/AuthContext";
import { Link } from "react-router-dom";
import { downloadCreditSimulatorPdfApi } from "../../../../../../../api/DownloadApi";
import { copyToClipboard, createDownloadLink } from "../../../../../../../utils/Utils";
import DuplicateCreditSimulatorModal from "../../../../../../../components/forms/modals/account/duplicateCreditSimulatorModal/DuplicateCreditSimulator";
import {
  CreditSimulatorsTabDropdownMenu,
  CreditSimulatorsTabFilters,
  CreditSimulatorTabRowItem,
} from "../../../accountsToolsTab/CreditSimulatorsTabComponents";
import Chart from "../../../../../../../components/atoms/icons/header/chart/Chart";

function FolderCreditSimulation({ folder, triggerFolderUpdate }) {
  const { setModalContent, setModalVisible, createNotification, setIsDownloadNotificationLoading } =
    useContext(AppContext);
  const [openedItemDropdown, setOpenedItemDropdown] = useState(false);
  const [itemsContainerScroll, setItemsContainerScroll] = useState(0);
  const itemsContainerRef = useRef(null);
  const dropdownMenuRef = useRef(null);
  const { getUuid } = useAuth();

  const { width } = useWindowSize();

  useEffect(() => {
    document.addEventListener("click", handleCloseDropdownMenu);

    return () => {
      document.removeEventListener("click", handleCloseDropdownMenu);
    };
  }, [dropdownMenuRef.current]);

  useEffect(() => {
    if (!itemsContainerRef.current) return;

    onItemsContainerScroll();

    itemsContainerRef.current?.addEventListener("scroll", onItemsContainerScroll);

    return () => {
      itemsContainerRef.current?.removeEventListener("scroll", onItemsContainerScroll);
    };
  }, [openedItemDropdown]);

  function handleCloseDropdownMenu() {
    if (!dropdownMenuRef.current) return;

    setOpenedItemDropdown(false);
  }

  function onItemsContainerScroll() {
    if (!openedItemDropdown) return setItemsContainerScroll(itemsContainerRef.current.scrollTop);

    const elementOffsetBottom =
      itemsContainerRef.current.children[openedItemDropdown.key]?.offsetTop ??
      0 + itemsContainerRef.current.children[openedItemDropdown.key]?.offsetHeight ??
      0;

    const containerOffsetBottom = itemsContainerRef.current.scrollTop + itemsContainerRef.current.offsetHeight;
    let dropdownMenuHeight = 20;
    for (const child of dropdownMenuRef.current.children) dropdownMenuHeight += child.offsetHeight + 10;

    if (elementOffsetBottom + dropdownMenuHeight + 25 > containerOffsetBottom)
      setItemsContainerScroll(itemsContainerRef.current.scrollTop + dropdownMenuHeight + 15);
    else setItemsContainerScroll(itemsContainerRef.current.scrollTop);

    if (
      (openedItemDropdown && elementOffsetBottom < itemsContainerRef.current.scrollTop) ||
      elementOffsetBottom > containerOffsetBottom
    )
      setOpenedItemDropdown(false);
  }

  function toggleOpenedItemDropdown(e, key) {
    e.preventDefault();

    if (!dropdownMenuRef.current)
      return createNotification(<>Une erreur est survenue. Veuillez réessayer.</>, "var(--red)", "var(--dark-blue)");

    const tmp = openedItemDropdown;
    const top = itemsContainerRef.current.children[key].offsetTop + 60;

    if (openedItemDropdown) setOpenedItemDropdown(false);

    setTimeout(() => {
      if (tmp.key !== key)
        setOpenedItemDropdown({
          item: folder.credit_simulators[key],
          key,
          top,
        });
    }, 10);
  }

  function openImportCreditSimulatorModal() {
    setModalContent({
      title: "Importer une simulation de prêt",
      content: (
        <ImportCreditSimulatorsFolderModal
          folderId={folder.data.folder_id}
          getUuid={getUuid}
          folder={folder}
          onCreditSimulatorAdded={triggerFolderUpdate}
        />
      ),
      className: "big-modal",
    });
    setModalVisible(true);
  }

  function handleDeleteCreditSimulator(creditSimulatorId) {
    setModalContent({
      title: "⚠️ Attention",
      content: (
        <div>
          <p>
            Êtes-vous sûr de vouloir retirer ce simulation de prêt de votre dossier ?<br />
            Cette action est irréversible.
          </p>
          <div className='modal-buttons-row'>
            <UnfilledButton padding='10px 25px' onClick={() => setModalVisible(false)}>
              Annuler
            </UnfilledButton>
            <FilledButton
              padding='10px 25px'
              onClick={() => {
                setModalVisible(false);
                confirmDeleteCreditSimulator(creditSimulatorId);
              }}>
              Retirer
            </FilledButton>
          </div>
        </div>
      ),
    });
    setModalVisible(true);
  }

  async function confirmDeleteCreditSimulator(creditSimulatorId) {
    try {
      await deleteCreditSimulatorFromFolderApi(folder.data.folder_id, creditSimulatorId, true);

      triggerFolderUpdate();

      createNotification(<>La simulation de prêt a été retiré avec succès</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors du retrait de la simulation de prêt. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  function handleDeleteAllCreditSimulators() {
    setModalContent({
      title: "⚠️ Attention",
      content: (
        <div>
          <p>
            Êtes-vous sûr de vouloir retirer tous les simulations de prêt de votre dossier ?<br />
            Cette action est irréversible.
          </p>
          <div className='modal-buttons-row'>
            <UnfilledButton padding='10px 25px' onClick={() => setModalVisible(false)}>
              Annuler
            </UnfilledButton>
            <FilledButton
              padding='10px 25px'
              onClick={() => {
                setModalVisible(false);
                confirmDeleteAllCreditSimulators();
              }}>
              Retirer
            </FilledButton>
          </div>
        </div>
      ),
    });
    setModalVisible(true);
  }

  async function confirmDeleteAllCreditSimulators() {
    try {
      await deleteAllCreditSimulatorsFromFolderApi(folder.data.folder_id, true);

      triggerFolderUpdate();

      createNotification(<>Tous les simulations de prêt ont été retirés avec succès</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors du retrait des simulations de prêt. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  async function downloadCreditSimulation(simulatorId) {
    try {
      setIsDownloadNotificationLoading(true);

      const res = await downloadCreditSimulatorPdfApi(simulatorId);

      const link = createDownloadLink(res.data);
      if (!link) throw new Error("");

      link.click();
      link.remove();

      createNotification(<>La simulation de prêt a été téléchargée avec succès.</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors du téléchargement de la simulation de prêt. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    } finally {
      setIsDownloadNotificationLoading(false);
    }
  }
  function getDuplicateCreditSimulationName(creditId) {
    const creditName = folder.credit_simulators.find(
      creditSimulation => creditSimulation.credit_id === creditId,
    ).credit_name;
    const regex = /#\d+$/;

    if (regex.test(creditName)) {
      const match = creditName.match(/\d+$/);

      return creditName.replace(regex, `#${parseInt(match[0]) + 1}`);
    } else {
      return `${creditName} #1`;
    }
  }

  function showDuplicateCreditSimulationModal(creditId) {
    setModalContent({
      title: "Dupliquer la simulation de prêt",
      content: (
        <DuplicateCreditSimulatorModal
          defaultValue={getDuplicateCreditSimulationName(creditId)}
          fetchCreditSimulatorsFunction={() => {}}
          creditSimulatorId={creditId}
        />
      ),
    });

    setModalVisible(true);
  }

  function copyIdToClipboard(e, id) {
    e.preventDefault();
    e.stopPropagation();

    copyToClipboard(id, <>La référence a été copiée dans votre presse-papier avec succès.</>, createNotification);
  }

  return (
    <div>
      <div className='tab-content-header'>
        <h2>
          <Chart />
          Simulation de prêt
        </h2>
        <p>Retrouvez ici tous les simulations de prêt liés à votre dossier.</p>
      </div>
      <div className='folder-simulateur-buttons'>
        <FilledButton to='/simulateur-credit'>
          <Add width={width > 576 ? "30px" : "20px"} /> Créer une simulation de prêt
        </FilledButton>
        <FilledButton onClick={openImportCreditSimulatorModal}>
          <Download width={width > 576 ? "30px" : "20px"} /> Importer une simulation de prêt
        </FilledButton>
      </div>
      <div className='folder-table-estimations items-table credit-simulators-table'>
        <div className='items-filters'>
          <CreditSimulatorsTabFilters />
        </div>
        <ul
          ref={dropdownMenuRef}
          className={`dropdown-menu-item ${openedItemDropdown ? "show" : ""}`}
          style={{ "--top": openedItemDropdown?.top - itemsContainerScroll + "px" }}>
          <CreditSimulatorsTabDropdownMenu
            openedItemDropdown={openedItemDropdown}
            downloadItemPdf={downloadCreditSimulation}
            showDuplicateItemModal={showDuplicateCreditSimulationModal}
            removeFromFolder={handleDeleteCreditSimulator}
          />
        </ul>
        <div className='items-table-body' ref={itemsContainerRef}>
          {folder.credit_simulators && folder.credit_simulators.length ? (
            folder.credit_simulators.map((creditSimulator, index) => (
              <CreditSimulatorTabRowItem
                key={creditSimulator.credit_id}
                index={index}
                item={creditSimulator}
                copyIdToClipboard={copyIdToClipboard}
                date={creditSimulator.date_creation?.substring(0, 10)}
                toggleOpenedItemDropdown={toggleOpenedItemDropdown}
                openedItemDropdown={openedItemDropdown}
              />
            ))
          ) : (
            <div className='info-block-simulateur'>
              <p>Aucune simulation de prêt n'a été ajouté à ce dossier.</p>
            </div>
          )}
        </div>
        <div className='folder-table-simulateur-footer mt-sm'>
          {folder.credit_simulators.length > 0 && (
            <UnfilledButton bgColor='var(--dark-red)' onClick={handleDeleteAllCreditSimulators}>
              {folder.credit_simulators.length > 1
                ? "Retirer tous les simulations de prêt"
                : "Retirer la simulation de prêt"}
            </UnfilledButton>
          )}
        </div>
      </div>
    </div>
  );
}

export default FolderCreditSimulation;
