import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useWindowSize } from "@uidotdev/usehooks";
import "./ParcoursStarter.css";
import Breadcrumbs from "../../../../components/molecules/breadcrumbs/Breadcrumbs";
import Banner from "../../../../components/molecules/banner/Banner";
import FilledButton from "../../../../components/molecules/buttons/filledButton/FilledButton";
import Card from "../../../../components/molecules/cards/card/Card";
import Shop from "../../../../components/atoms/icons/header/shop/Shop";
import Factory from "../../../../components/atoms/icons/header/factory/Factory";
import Building from "../../../../components/atoms/icons/header/building/Building";
import Bracket from "../../../../components/atoms/icons/general/bracket/Bracket";
import AppContext from "../../../../context/AppContext";

function ParcoursStarter() {
  const { width } = useWindowSize();
  const [currentIndex, setCurrentIndex] = useState(0);
  const { setBannerConfig } = useContext(AppContext);
  const navigate = useNavigate();

  const parcours = [
    {
      name: "Fonds de commerce",
      description:
        "Le fonds de commerce regroupe les éléments matériels et immatériels d'une activité, tels que la clientèle, le droit au bail, et les marques, mais exclut la reprise de la structure juridique de la société.",
      url: "/detail-parcours/fonds-de-commerce",
    },
    {
      name: "Murs commerciaux",
      description:
        "Les murs commerciaux désignent le(s) bâtiment(s) utilisé(s) pour une activité commerciale, qu'il soit loué ou possédé par l'exploitant.",
      url: "/detail-parcours/murs-commerciaux",
    },
    {
      name: "Titre de société",
      description:
        "Les titres de société représentent la propriété d'une entreprise, incluant son actif et son passif, et leur cession englobe l'entité juridique entière, pas seulement le fonds de commerce.",
      url: "/detail-parcours/titres-de-societe",
    },
  ];

  const iconMap = {
    "Fonds de commerce": Shop,
    "Murs commerciaux": Building,
    "Titre de société": Factory,
  };

  useEffect(() => {
    setBannerConfig({
      title: <>Estimer</>,
      subtitle: <>Avant de commencer, choisissez le type d'estimation que vous souhaitez créer.</>,
    });
  }, []);

  function renderIcon(name) {
    const IconComponent = iconMap[name];
    return IconComponent ? <IconComponent width='50px' color='var(--dark-blue)' className='icon' /> : null;
  }

  function handleStartEstimation(url) {
    if (url && url !== "#") {
      navigate(url);
    }
  }

  function toNextArticle(reverse = false) {
    const maxIndex = parcours.length - 1;
    if (reverse) {
      setCurrentIndex(prevIndex => (prevIndex === 0 ? maxIndex : prevIndex - 1));
    } else {
      setCurrentIndex(prevIndex => (prevIndex === maxIndex ? 0 : prevIndex + 1));
    }
  }

  function isAtFirstPosition() {
    return currentIndex === 0;
  }

  function isAtLastPosition() {
    return currentIndex === parcours.length - 1;
  }

  return (
    <section className='page-container container'>
      {width > 992 ? (
        <div className='parcours-starter-container'>
          {parcours.map((parcour, index) => (
            <Card key={index} className='parcours-card'>
              <div className='parcours-card-content'>
                {renderIcon(parcour.name)}
                <h3>{parcour.name}</h3>
                <p>{parcour.description}</p>
                <FilledButton onClick={() => handleStartEstimation(parcour.url)} padding='10px 30px' fontSize='1rem'>
                  Démarrer
                </FilledButton>
              </div>
            </Card>
          ))}
        </div>
      ) : (
        <div className='carousel-container'>
          <Card className='parcours-card'>
            <div className='parcours-card-content'>
              {renderIcon(parcours[currentIndex].name)}
              <h3>{parcours[currentIndex].name}</h3>
              <p>{parcours[currentIndex].description}</p>
              <FilledButton
                onClick={() => handleStartEstimation(parcours[currentIndex].url)}
                padding='10px 30px'
                fontSize='1rem'>
                Démarrer
              </FilledButton>
            </div>
          </Card>
          <FilledButton
            className={"carousel-bracket-left " + (isAtFirstPosition() ? "d-none" : "")}
            onClick={() => toNextArticle(true)}
            padding='12px 10px'>
            <Bracket rotation='270deg' marginLeft='0' />
          </FilledButton>
          <FilledButton
            className={"carousel-bracket-right " + (isAtLastPosition() ? "d-none" : "")}
            onClick={() => toNextArticle()}
            padding='12px 10px'>
            <Bracket rotation='90deg' marginLeft='0' />
          </FilledButton>
        </div>
      )}
    </section>
  );
}

export default ParcoursStarter;
