import React, { useContext, useEffect, useState } from "react";
import "./CrmClientSubscription.css";
import FilledButton from "../../../molecules/buttons/filledButton/FilledButton";
import { stringToDate } from "../../../../utils/Utils";
import Copy from "../../../atoms/icons/account/copy/Copy";
import { useCrm } from "../../../../context/CrmContext";
import { Link, useParams } from "react-router-dom";
import {
  cancelAutoRenewalApi,
  cancelSubscriptionApi,
  deleteFreeSubscriptionApi,
} from "../../../../api/CrmSubscriptionApi";
import AppContext from "../../../../context/AppContext";
import UnfilledButton from "../../../molecules/buttons/unfilledButton/UnfilledButton";
import OfferSubscriptionModal from "../../../forms/modals/crm/offerSubscriptionModal/OfferSubscriptionModal";
import { set } from "react-hook-form";
import { blockClientApi, unblockClientApi } from "../../../../api/CrmClientsApi";

function CrmClientSubscription({
  clientSubscription,
  updateActiveClientTrigger,
  isClientBlocked,
  updateClientInfosTrigger,
}) {
  const { createNotification, setModalVisible, setModalContent } = useContext(AppContext);
  const { copyIdToClipboard, getClientPaymentStatus } = useCrm();

  const { uuid } = useParams();

  const [subscriptionRows, setSubscriptionRows] = useState([]);
  const [subEndDate, setSubEndDate] = useState("");

  const currentSubscription = clientSubscription?.current;

  useEffect(() => {
    let subEndDate;
    if (currentSubscription?.is_active) {
      if (currentSubscription?.is_free_trial) {
        if (currentSubscription?.tools_access) {
          const endEstimationsDate = stringToDate(currentSubscription?.date_end_sub + ":00");
          const endToolsDate = stringToDate(currentSubscription?.date_end_tools_access + ":00");

          subEndDate =
            endEstimationsDate > endToolsDate
              ? currentSubscription?.date_end_sub
              : currentSubscription?.date_end_tools_access;
        } else subEndDate = currentSubscription?.date_end_sub;
      } else subEndDate = currentSubscription?.date_end_sub;
    }
    subEndDate = subEndDate?.substr(0, 16);

    setSubEndDate(subEndDate);

    if (currentSubscription?.is_free_trial && currentSubscription?.is_active)
      setSubscriptionRows([
        {
          id: 1,
          label: "Statut",
          value: currentSubscription?.is_active
            ? "Actif"
            : currentSubscription?.is_active === 0
              ? `Résilié` +
                (+calcSubscriptionRemainingTime(currentSubscription?.date_end_sub)
                  ? `(actif jusqu'au ${currentSubscription?.date_end_sub})`
                  : "")
              : "-",
        },
        {
          id: 2,
          label: "Date de souscription",
          value: currentSubscription?.date_start_sub ?? "-",
        },
        {
          id: 10,
          label: "Date de mise à jour",
          value: currentSubscription?.date_update_sub ?? "-",
        },
        {
          id: 3,
          label: "Fin d’engagement",
          value: subEndDate ?? "-",
          bold: true,
        },
        {
          id: 4,
          label: "Temps restant (estim°)",
          value: calcSubscriptionRemainingTime(currentSubscription?.date_end_sub) ?? "-",
        },
        currentSubscription?.tools_access && {
          id: 14,
          label: "Temps restant (outils)",
          value: calcSubscriptionRemainingTime(currentSubscription?.date_end_tools_access) ?? "-",
        },
        {
          id: 11,
          space: true,
        },
        {
          id: 12,
          label: "Nombre d'estimations",
          value: currentSubscription?.nb_estimations === -1 ? "Illimité" : currentSubscription?.nb_estimations,
        },
        {
          id: 13,
          label: "Accès aux outils",
          value: currentSubscription?.tools_access ? "Oui" : "Non",
        },
        {
          id: 5,
          space: true,
        },
        {
          id: 6,
          label: "Type",
          value: currentSubscription?.plan ?? "-",
        },
        {
          id: 7,
          label: "ID abonnement Stripe",
          value: currentSubscription?.sub_id,
          copy: true,
        },
        {
          id: 8,
          label: "ID client Stripe",
          value: clientSubscription?.customer_id,
          copy: true,
        },
        {
          id: 9,
          label: "Renouvellement auto",
          value: currentSubscription?.is_auto_renewed ? "Oui" : "Non",
        },
      ]);
    else
      setSubscriptionRows([
        {
          id: 1,
          label: "Statut",
          value: currentSubscription?.is_active
            ? "Actif"
            : currentSubscription?.is_active === 0
              ? `Résilié` +
                (+calcSubscriptionRemainingTime(currentSubscription?.date_end_sub)
                  ? `(actif jusqu'au ${currentSubscription?.date_end_sub})`
                  : "")
              : "-",
        },
        {
          id: 2,
          label: "Date de souscription",
          value: currentSubscription?.date_start_sub ?? "-",
        },
        {
          id: 10,
          label: "Date de mise à jour",
          value: currentSubscription?.date_update_sub ?? "-",
        },
        {
          id: 3,
          label: "Fin d’engagement",
          value: currentSubscription?.date_end_sub ?? "-",
          bold: true,
        },
        {
          id: 4,
          label: "Temps restant",
          value: calcSubscriptionRemainingTime(currentSubscription?.date_end_sub) ?? "Terminé",
        },
        {
          id: 5,
          space: true,
        },
        {
          id: 6,
          label: "Type",
          value: currentSubscription?.plan ?? "-",
        },
        {
          id: 7,
          label: "ID abonnement Stripe",
          value: currentSubscription?.sub_id,
          copy: true,
        },
        {
          id: 8,
          label: "ID client Stripe",
          value: clientSubscription?.customer_id,
          copy: true,
        },
        {
          id: 9,
          label: "Renouvellement auto",
          value: currentSubscription?.is_auto_renewed ? "Oui" : "Non",
        },
      ]);
  }, [clientSubscription]);

  async function cancelAutoRenewal(subscriptionUuid) {
    setModalVisible(false);

    try {
      await cancelAutoRenewalApi(subscriptionUuid);
      updateActiveClientTrigger();
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la résiliation de l'abonnement. Veuillez réessayer.</>,
        "var(--red)",
        "var(--darke-blue)",
      );
    }
  }

  async function cancelSubscription(subscriptionUuid) {
    setModalVisible(false);

    try {
      await cancelSubscriptionApi(subscriptionUuid);
      updateActiveClientTrigger();
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la résiliation de l'abonnement. Veuillez réessayer.</>,
        "var(--red)",
        "var(--darke-blue)",
      );
    }
  }

  async function deleteFreeSubscription(subscriptionUuid) {
    setModalVisible(false);

    try {
      await deleteFreeSubscriptionApi(subscriptionUuid);
      updateActiveClientTrigger();
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la suppression de l'abonnement gratuit. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  async function blockClient(clientUuid) {
    setModalVisible(false);

    try {
      await blockClientApi(clientUuid, { non_payment: true });
      updateClientInfosTrigger();
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors du bloquage de l'utilisateur. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  async function unblockClient(clientUuid) {
    setModalVisible(false);

    try {
      await unblockClientApi(clientUuid);
      updateClientInfosTrigger();
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors du débloquage de l'utilisateur. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  function calcSubscriptionRemainingTime(subscriptionEnd) {
    if (!subscriptionEnd) return;

    const now = new Date();
    const end = stringToDate(subscriptionEnd + ":00");
    const diff = end - now;

    if (diff < 0) return;

    const totalMinutes = Math.floor(diff / (1000 * 60));
    const minutes = totalMinutes % 60;
    const totalHours = Math.floor(totalMinutes / 60);
    const hours = totalHours % 24;
    const totalDays = Math.floor(totalHours / 24);
    const months = Math.floor(totalDays / 30);
    const days = totalDays % 30;

    return `${months ? months + " mois" : ""} ${days || months ? days + " jours" : ""} ${hours} heures ${minutes} minutes`;
  }

  function getSubscriptionTagClassName() {
    switch (getClientPaymentStatus(clientSubscription)) {
      case "Echec paiement":
        return "red-background crm-tag";
      case "Résiliation en cours":
        return "orange-background crm-tag";
      case "Résilié":
        return "yellow-background crm-tag";
      case "-":
        return "grey-background crm-tag";
      case "Actif":
        return "green-background crm-tag";
    }
  }

  function getSubscriptionTagContent(currentSubscription, subEndDate) {
    if (!currentSubscription) return "Aucun abonnement";

    let result = getClientPaymentStatus(clientSubscription);
    let remainingTime = calcSubscriptionRemainingTime(subEndDate);

    if (remainingTime) result += ` (actif jusqu'au ${subEndDate})`;

    return result;
  }

  function showConfirmationModal(type, ...params) {
    let config = {
      title: "Confirmation",
    };
    switch (type) {
      case 1:
        config = {
          title: "Résilier l'abonnement",
          message: "Êtes-vous sûr de vouloir résilier l'abonnement ?",
          function: () => cancelSubscription(params),
        };
        break;
      case 2:
        config = {
          title: "Annuler le renouvellement auto de l’abonnement en cours",
          message: "Êtes-vous sûr de vouloir annuler le renouvellement auto de l’abonnement en cours ?",
          function: () => cancelAutoRenewal(params),
        };
        break;
      case 3:
        config = {
          title: "Supprimer l'abonnement gratuit",
          message: "Êtes-vous sûr de vouloir supprimer l'abonnement gratuit en cours ?",
          function: () => deleteFreeSubscription(params),
        };
        break;
      case 4:
        config = {
          title: "Bloquer pour non-paiement",
          message: "Êtes-vous sûr de vouloir bloquer le client pour non-paiement ?",
          function: () => blockClient(params),
        };
        break;
      case 5:
        config = {
          title: "Débloquer l'utilisateur",
          message: "Êtes-vous sûr de vouloir débloquer le client ?",
          function: () => unblockClient(params),
        };
        break;
    }

    setModalContent({
      title: config.title,
      content: (
        <>
          <p>{config.message}</p>
          <div className='modal-buttons-row'>
            <UnfilledButton padding='10px 25px' onClick={() => setModalVisible(false)}>
              Annuler
            </UnfilledButton>
            <FilledButton padding='10px 25px' onClick={config.function}>
              Confirmer
            </FilledButton>
          </div>
        </>
      ),
    });

    setModalVisible(true);
  }

  function showOfferSubscriptionModal(subscription) {
    setModalContent({
      title: "Offrir un abonnement",
      content: (
        <OfferSubscriptionModal
          subscription={subscription}
          clientUuid={uuid}
          triggerUpdate={updateActiveClientTrigger}
        />
      ),
      className: "big-modal",
    });

    setModalVisible(true);
  }

  return (
    <>
      <h2 className='crm-h2'>
        Dernier abonnement
        <span className={`crm-payment-status ml-sm crm-${getSubscriptionTagClassName(currentSubscription)}`}>
          {getSubscriptionTagContent(currentSubscription, subEndDate)}
        </span>
      </h2>
      <div className='subscription-infos-container'>
        {currentSubscription ? (
          <div className='client-infos-block subscription-infos-block'>
            {subscriptionRows.map(row =>
              !row ? null : row.space ? (
                <div key={row.id} className='client-infos-space' />
              ) : (
                <div key={row.id} className='client-infos-row'>
                  <div>{row.label}&nbsp;:</div>
                  <div {...(row.bold && { className: "outfit-medium" })}>
                    {row.value}
                    {row.copy && <Copy width='20px' className='ml-xs' onClick={e => copyIdToClipboard(e, row.value)} />}
                  </div>
                </div>
              ),
            )}
          </div>
        ) : (
          <p className='centered text-xl'>Aucun abonnement actif</p>
        )}
        <div className='client-subscription-buttons'>
          {!currentSubscription?.is_active && (
            <FilledButton className='crm-button' onClick={() => showOfferSubscriptionModal()}>
              Offrir un abonnement
            </FilledButton>
          )}
          {!!currentSubscription?.is_free_trial && !!currentSubscription?.is_active && (
            <FilledButton className='crm-button' onClick={() => showOfferSubscriptionModal(currentSubscription)}>
              Modifier l'abonnement offert
            </FilledButton>
          )}
          {!!currentSubscription?.is_free_trial && !!currentSubscription?.is_active && (
            <FilledButton className='crm-button' onClick={() => showConfirmationModal(3, currentSubscription?.sub_id)}>
              Supprimer l'abonnement offert
            </FilledButton>
          )}
          {!!currentSubscription?.is_active && (
            <FilledButton className='crm-button' onClick={() => showConfirmationModal(1, currentSubscription?.sub_id)}>
              Résilier l'abonnement
            </FilledButton>
          )}
          <FilledButton className='crm-button' target='_blank' to={clientSubscription?.billingLink}>
            Moyens de paiement et factures
          </FilledButton>
          {isClientBlocked ? (
            <FilledButton className='crm-button' onClick={() => showConfirmationModal(5, uuid)}>
              Débloquer l'utilisateur
            </FilledButton>
          ) : (
            <FilledButton className='crm-button' onClick={() => showConfirmationModal(4, uuid)}>
              Bloquer pour non-paiement
            </FilledButton>
          )}
          {!!currentSubscription?.is_auto_renewed && (
            <FilledButton className='crm-button' onClick={() => showConfirmationModal(2, currentSubscription?.sub_id)}>
              Annuler le renouvellement auto de l’abonnement
            </FilledButton>
          )}
          <FilledButton className='crm-button' disabled>
            Envoyer un mail
          </FilledButton>
        </div>
      </div>
      <h2 className='crm-h2'>Historique des abonnements</h2>
      {clientSubscription?.history?.length ? (
        <div className='client-subscription-history'>
          <div className='client-subscription-history-header crm-thead'>
            <div>ID Stripe</div>
            <div>Type</div>
            <div>Début</div>
            <div>Fin</div>
          </div>
          <div className='crm-tbody'>
            {clientSubscription?.history.map(subscription => (
              <Link to='' className='crm-table-row' key={subscription.sub_id}>
                <div
                  className='crm-row-column crm-column-uuid crm-clickable-column'
                  onClick={e => copyIdToClipboard(e, subscription.sub_id)}>
                  <Copy width='18px' color='var(--white)' onClick={e => copyIdToClipboard(e, subscription.sub_id)} />
                </div>
                <div className='crm-row-column'>{subscription.plan}</div>
                <div className='crm-row-column'>{subscription.date_start_sub}</div>
                <div className='crm-row-column'>{subscription.date_end_sub}</div>
              </Link>
            ))}
          </div>
        </div>
      ) : (
        <p className='centered text-xl mt-lg'>Aucun historique d'abonnement</p>
      )}
    </>
  );
}

export default CrmClientSubscription;
