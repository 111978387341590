import { removeSpaces } from "../Utils";

export const phoneValidation = {
  validate: value => {
    if (!value.length) return "Ce champ est obligatoire";
    return (
      !!value.match(/^[0-9]{2}[\s][0-9]{2}[\s][0-9]{2}[\s][0-9]{2}[\s][0-9]{2}$/) || "Numéro de téléphone invalide"
    );
  },
};

export const emailValidation = {
  required: "Ce champ est obligatoire",
  pattern: {
    value: /^[\w.+-]+@([\w-]+\.)+[\w]{2,8}$/,
    message: "Adresse mail invalide",
  },
};

export const selectValidation = {
  validate: (value, formValues) => value !== "_null" || "Ce champ est obligatoire",
};

export const numberValidation = {
  required: "Ce champ est obligatoire",
  pattern: {
    value: /^-?[0-9\s]*$/,
    message: "Ce champ n'accepte que les chiffres",
  },
};

export const max365Validation = {
  required: "Ce champ est obligatoire",
  validate: value => {
    value = removeSpaces(value);
    return (value > 0 && value < 366) || "La valeur doit être comprise entre 1 et 365";
  },
};

export const max100Validation = {
  required: "Ce champ est obligatoire",
  validate: value => {
    value = removeSpaces(value);
    return (value > 0 && value < 101) || "La valeur doit être comprise entre 1 et 100";
  },
};

export const dateValidation = {
  required: "Ce champ est obligatoire",
  valueAsDate: true,
};

export const decimalValidation = {
  required: "Ce champ est obligatoire",
  pattern: {
    value: /^-?[0-9\s]*[,|.]?[0-9]*$/,
    message: "Ce champ n'accepte que les nombres décimaux",
  },
};

export const loyerValidation = {
  required: "Ce champ est obligatoire",
  validate: value =>
    removeSpaces(value) > 0 || "Vous devez saisir un montant positif pour obtenir un résultat d’estimation",
};

export const passwordValidation = {
  required: "Ce champ est obligatoire",
  pattern: {
    value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w\s]).{8,}$/,
    message: "Votre mot de passe ne respecte pas les critères de sécurité",
  },
};

export const confirmPasswordvalidation = {
  validate: (value, formValues) => value === formValues.password || "Les mots de passe ne correspondent pas",
};

export const rate5Validation = {
  pattern: {
    value: /\b[0-5](?:[.,]\d+)?$/,
    message: "La note doit être comprise entre 0 et 5",
  },
};

export const rate10Validation = {
  pattern: {
    value: /\b(10(?:[.,]0+)?|[0-9](?:[.,][0-9]+)?)\b/,
    message: "La note doit être comprise entre 0 et 10",
  },
};

export const defaultValidation = {
  required: "Ce champ est obligatoire",
  maxLength: {
    value: 255,
    message: "Ce champ ne doit pas dépasser 255 caractères",
  },
};

export const max2250Validation = {
  maxLength: {
    value: 2250,
    message: "Ce champ ne doit pas dépasser 2250 caractères",
  },
};

export const max2250RequiredValidation = {
  required: "Ce champ est obligatoire",
  maxLength: {
    value: 2250,
    message: "Ce champ ne doit pas dépasser 2250 caractères",
  },
};
