import html2canvas from "html2canvas";
import { Fragment } from "react";
import Check from "../components/atoms/icons/general/check/Check";

export function toggleCollapseElement(element, visible, bonusHeight = 0) {
  if (!visible) {
    element.style.overflowY = "hidden";
    setTimeout(() => {
      element.style.overflowY = "hidden";
    }, 400);
    element.style.maxHeight = 0;
  } else {
    setTimeout(() => {
      element.style.overflowY = "unset";
    }, 400);
    element.style.maxHeight = element.scrollHeight + bonusHeight + "px";
  }
}

export function toggleCollapseInput(element, visible, bonusHeight = 0) {
  if (!visible) {
    element.style.overflowY = "hidden";
    element.style.maxHeight = 0;
  } else {
    let clone = element.cloneNode(true);
    clone.style.maxHeight = "none";
    clone.style.maxWidth = element.getBoundingClientRect().width + "px";
    clone.style.position = "absolute";
    clone.style.top = "-9999px";
    clone.style.left = "-9999px";
    document.body.appendChild(clone);
    element.style.maxHeight = clone.offsetHeight + bonusHeight + "px";
    document.body.removeChild(clone);
  }
}

export function getScrollbarWidth() {
  const test = document.createElement("div");
  test.style.visibility = "hidden";
  test.style.width = "100vw";
  document.body.appendChild(test);

  const result = test.offsetWidth - document.body.offsetWidth;

  test.parentNode.removeChild(test);

  return result;
}

export function toInt(str) {
  return str ? Number(str) : 0;
}

export function formatNumberWithSpaces(str, allowZero = false, icon = "") {
  if (str == 0 && allowZero) return "0" + (icon.length ? " " + icon : "");
  if (!str) return allowZero ? "0" + (icon.length ? " " + icon : "") : "";

  str = str + "";
  str = str.replace(/\s/g, "");
  str = str.replace(/[^0-9.,-]/g, "");
  str = str.replace(/\./g, ",");
  str = str[0] + str.slice(1).replace(/-/g, "");
  if (str === "undefined") return "";

  const parts = str.split(",");
  let integerPart = parts[0] ? parts[0] : "";
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, "\u00A0");
  const decimalPart = parts.length > 1 ? "," + parts[1] : "";
  if (integerPart === "" && decimalPart) integerPart = "0";
  while (integerPart[0] === "0" && integerPart[1] !== "," && integerPart.length > 1) integerPart = integerPart.slice(1);

  return integerPart + decimalPart + (icon ? "\u00A0" + icon : "");
}

export function formatPhoneNumberWithSpaces(str) {
  if (!str) return "";

  str = str.replace(/\D/g, "");
  str = str.substring(0, 10);
  return str.replace(/(\d{2})(?=\d)/g, "$1 ");
}

export function removeSpaces(str, allowNull = false) {
  if (str === undefined || str === null) return allowNull ? null : "";
  str = str + "";
  return Number(str.replace(/\s/g, "").replace(",", "."));
}

export function getSelectValue(value) {
  return value === "_null" ? null : value;
}

export function removeSpacesFromEvent(e) {
  if (!e.target) return "";
  e.target.value = e.target.value.replace(/\s/g, "").replace(",", ".");
  return e;
}

export function getRandomString(length) {
  let result = "";
  const characters = "abcdefghijklmnopqrstuvwxyz";
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

// return file as { name: String, data: base64 } without compression
export function getImageInfos(event, files) {
  if (!files) files = event.target.files;
  if (!files.length) return { data: null };

  return new Promise((resolve, reject) => {
    const file = files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const lastDotIndex = file.name.lastIndexOf(".");

      resolve({
        name: lastDotIndex !== -1 && lastDotIndex !== 0 ? file.name.substring(0, lastDotIndex) : file.name,
        data: reader.result,
      });
    };

    if (file) {
      reader.readAsDataURL(file);
    } else {
      reject(new Error("No file selected"));
    }
  });
}

// compress image with html2canvas then return as { name: String, data: base64 }
export async function getCompressedImage(file) {
  return new Promise((resolve, reject) => {
    if (file.type === "image/svg+xml") {
      // Lire le fichier SVG en tant que Data URL
      const reader = new FileReader();
      reader.onload = () => {
        resolve({
          name: file.name,
          data: reader.result,
        });
      };
      reader.onerror = error => {
        reject("Erreur lors du chargement de l'image : " + error);
      };
      reader.readAsDataURL(file);
    } else {
      // Gérer les images raster (JPEG, PNG)
      const reader = new FileReader();
      reader.onload = () => {
        const img = new Image();
        img.onload = () => {
          const MAX_WIDTH = 1000;
          const MAX_HEIGHT = 1000;
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }

          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          canvas.width = width;
          canvas.height = height;

          ctx.clearRect(0, 0, width, height);
          ctx.drawImage(img, 0, 0, width, height);

          const dataUrl = canvas.toDataURL("image/png");

          const lastDotIndex = file.name.lastIndexOf(".");

          resolve({
            name: lastDotIndex !== -1 && lastDotIndex !== 0 ? file.name.substring(0, lastDotIndex) : file.name,
            data: dataUrl,
          });
        };

        img.onerror = error => {
          reject("Erreur lors du chargement de l'image : " + error);
        };

        img.src = reader.result;
      };

      reader.onerror = error => {
        reject("Erreur lors de la lecture du fichier : " + error);
      };

      reader.readAsDataURL(file);
    }
  });
}

export function imageToFile(imageUrl, imageName, type = "image/svg+xml", callback) {
  fetch(imageUrl)
    .then(response => {
      if (!response.ok) {
        throw new Error(`Erreur lors du chargement de l'image : ${response.statusText}`);
      }
      return response.blob();
    })
    .then(blob => {
      const file = new File([blob], imageName, { type: blob.type });
      callback(file);
    })
    .catch(error => {
      callback(null, error);
    });
}

export async function checkIfSvgIsValid(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = e => {
      const svgContent = e.target.result;
      const parser = new DOMParser();
      const doc = parser.parseFromString(svgContent, "image/svg+xml");
      const svgElement = doc.querySelector("svg");

      const width = svgElement.getAttribute("width");
      const height = svgElement.getAttribute("height");
      const viewBox = svgElement.getAttribute("viewBox")?.split(" ");

      if (!width && viewBox[2]) svgElement.setAttribute("width", viewBox[2]);
      if (!height && viewBox[3]) svgElement.setAttribute("height", viewBox[3]);

      const serializer = new XMLSerializer();
      const updatedSvgContent = serializer.serializeToString(doc);

      resolve(updatedSvgContent);
    };

    reader.onerror = error => {
      reject("Erreur de lecture du fichier : " + error);
    };

    reader.readAsText(file);
  });
}

export function checkImage(file, maxSize = 10) {
  if (!file) return;

  let fileSize = file.size / 1048576;
  let errorMsg;

  if (
    file.type !== "image/jpeg" &&
    file.type !== "image/png" &&
    file.type !== "image/jpg" &&
    file.type !== "image/svg" &&
    file.type !== "image/svg+xml" &&
    file.type !== "image/webp"
  )
    errorMsg = "Mauvais format d'image";
  else if (fileSize > maxSize) errorMsg = "Image trop lourde (" + fileSize.toFixed(2) + "/" + maxSize + " Mb)";

  return errorMsg;
}

export function isStickyElementPinned(elementRef, topTreshold) {
  if (elementRef.current) {
    const rect = elementRef.current.getBoundingClientRect();
    if (rect.top <= topTreshold) return true;
    else return false;
  }
  return false;
}

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function getPrettyText(text) {
  return text?.replaceAll("&nbsp;", "\u00A0")?.replaceAll("&amp;", "&") ?? "";
}

export function isFormattedDate(string) {
  return string.length === 10 && string.split("/").length === 3;
}

export function isDate(string) {
  return string.length === 10 && string.split("-").length === 3;
}

export function stringToDate(dateString) {
  var parts = dateString.split(/[\s/:]+/);
  return new Date(parts[2], parts[1] - 1, parts[0], parts[3], parts[4], parts[5]);
}

export function getFormattedDate(date) {
  if (!date) return "";
  date = date.split("-");

  return date[2] + "/" + date[1] + "/" + date[0];
}

export function formatDate(date) {
  if (!date) return "";
  date = date.split("/");

  return date[2] + "-" + date[1] + "-" + date[0];
}

export function getDateFromTimestamp(timestamp) {
  const date = new Date(timestamp);

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Les mois commencent à 0
  const year = date.getFullYear();

  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${day}/${month}/${year} ${hours}:${minutes}`;
}

export function getObfuscedEmail(email) {
  if (!email) return "";

  const [username, domain] = email.split("@");
  const obfuscatedUsername = username.substring(0, 3) + "*".repeat(username.length - 3);
  return obfuscatedUsername + "@" + domain;
}

export function dateToString(date) {
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

export function getPasswordErrorMessage(password) {
  if (password === undefined) return;

  const passwordErrorMessage = [
    { content: "Votre mot de passe doit contenir au moins :", visible: true },
    { content: "• 8 caractères", visible: true },
    { content: "• 1 majuscule", visible: true },
    { content: "• 1 minuscule", visible: true },
    { content: "• 1 chiffre", visible: true },
    { content: '• 1 caractère spécial (@, !, ", %)', visible: true },
  ];

  passwordErrorMessage[1].visible = password.length < 8;
  passwordErrorMessage[2].visible = password.toLowerCase() === password || !password.length;
  passwordErrorMessage[3].visible = password.toUpperCase() === password || !password.length;
  passwordErrorMessage[4].visible = !/.*[0-9].*/.test(password);
  passwordErrorMessage[5].visible = !/.*[^\w\s].*/.test(password);

  return (
    <div className='password-tooltip-content'>
      {passwordErrorMessage.map((row, index) => {
        return (
          <Fragment key={index}>
            {index > 0 && <br />}
            <>
              {row.content}
              {!row.visible && index > 0 && <Check color='green' />}{" "}
            </>
          </Fragment>
        );
      })}
    </div>
  );
}

// take an object and return it with all formatted numbers as true numbers
export function formatObjectForPosting(object) {
  const tmp = {};

  for (const [key, value] of Object.entries(object)) {
    if (typeof value === "object" && value !== null) {
      tmp[key] = formatObjectForPosting(value);
    } else {
      tmp[key] = parseFormattedValue(value);
    }
  }

  return tmp;
}

export function getTotalFromValues(values) {
  return formatNumberWithSpaces(
    values.reduce((a, b) => removeSpaces(a, true) + removeSpaces(b, true), 0),
    true,
  );
}

export function normalizeString(str) {
  return (
    (str + "")
      ?.normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toUpperCase() ?? ""
  );
}

export function formatTextWithNbsp(text) {
  return text?.replaceAll(/[\s\u00A0](?=[\?:!])/g, "&nbsp;") ?? "";
}

export function uploadImage(div, token, route, extraData, callback, imageName) {
  try {
    html2canvas(div).then(e => {
      axios
        .post(
          process.env.REACT_APP_API_URL + route,
          {
            image: e.toDataURL("image/jpeg", 0.9),
            image_name: imageName + "_" + getRandomString(10) + ".jpeg",
            ...extraData,
          },
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then(e => {
          callback();
        });
    });
  } catch (e) {}
}

export function formatEuroAmount(amount, isPercentage = false) {
  if (amount === null || amount === undefined) return "-";
  let result = String(amount).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  result += isPercentage ? " %" : " €";

  return result;
}

// takes a string as param and return it formatted for backend (remove spaces between numbers, replace _null by null in selects, format dates)
export function parseFormattedValue(string) {
  if (typeof string === "number") return string;

  if (string === "_null" || !string) return null;

  if (isDate(string)) return getFormattedDate(string);

  const formattedNumberPattern = /^-?\d{1,3}(?:[\s]?\d{3})*(?:[.,]\d+)?$/;

  if (formattedNumberPattern.test(string)) return parseFloat(string.replace(/[\s]/g, "").replace(",", "."));

  return string;
}

// take an object and return it with all values as null
export function resetObjectValues(object) {
  if (!object || typeof object !== "object") {
    return null;
  }

  const tmp = {};

  for (const [key, value] of Object.entries(object)) {
    if (typeof value === "object" && value !== null) tmp[key] = resetObjectValues(value);
    else tmp[key] = null;
  }

  return tmp;
}

export function sanitizeString(str) {
  const accentsMap = {
    à: "a",
    â: "a",
    ä: "a",
    é: "e",
    è: "e",
    ê: "e",
    ë: "e",
    î: "i",
    ï: "i",
    ô: "o",
    ö: "o",
    ù: "u",
    û: "u",
    ü: "u",
    ç: "c",
    ÿ: "y",
    ñ: "n",
    À: "A",
    Â: "A",
    Ä: "A",
    É: "E",
    È: "E",
    Ê: "E",
    Ë: "E",
    Î: "I",
    Ï: "I",
    Ô: "O",
    Ö: "O",
    Ù: "U",
    Û: "U",
    Ü: "U",
    Ç: "C",
    Ÿ: "Y",
    Ñ: "N",
  };

  const sanitizedStr = str
    .split("")
    .map(char => accentsMap[char] || char)
    .join("");

  return sanitizedStr.replace(/[^a-zA-Z0-9]/g, "_");
}

export function createDownloadLink(downloadUrl) {
  if (!downloadUrl || typeof downloadUrl !== "string") return false;

  const link = document.createElement("a");
  link.href = downloadUrl;
  document.body.appendChild(link);

  return link;
}

export function roundUp500(value) {
  return Math.ceil(value / 500) * 500;
}

export function getShortedString(string, maxLength) {
  if (!string) return "";

  if (string.length <= maxLength) return string;
  return string.substring(0, maxLength - 1) + "…";
}

export async function copyToClipboard(text, notificationContent = <></>, createNotification = () => {}) {
  try {
    await navigator.clipboard.writeText(text);
    createNotification(notificationContent);
  } catch (e) {
    createNotification(<>Une erreur est survenue lors de la copie du texte.</>, "var(--red)", "var(--dark-blue)");
  }
}

export function getDuplicateItemName(itemName) {
  const regex = /#\d+$/;

  if (regex.test(itemName)) {
    const match = itemName.match(/\d+$/);

    return itemName.replace(regex, `#${parseInt(match[0]) + 1}`);
  } else {
    return `${itemName} #1`;
  }
}
