import { formatNumberWithSpaces } from "../../../utils/Utils";
import FrameTextPdf from "../frameTextPdf/FrameTextPdf";
import "./EstimationCalcsPdf.css";
import React, { Fragment } from "react";

function EstimationCalcsPdf({ data }) {
  return (
    <FrameTextPdf className='estimation-calcs-container'>
      {data?.map(row => (
        <Fragment key={row.title}>
          <p className='pdf-bold'>
            {row.title}
            {row.sup && <span>({row.sup})</span>}
          </p>
          <p>{row.subtitle}</p>
          <div className='estimation-calcs-row'>
            <div>
              <div className='estimation-calcs-label-low'>Basse</div>
              <div>{formatNumberWithSpaces(Math.max(0, row.low_amount), true, "€")}</div>
            </div>
            <div className='estimation-calcs-divider' />
            <div>
              <div className='estimation-calcs-label-mid'>Moyenne</div>
              <div>{formatNumberWithSpaces(Math.max(0, row.mid_amount), true, "€")}</div>
            </div>
            <div className='estimation-calcs-divider' />
            <div>
              <div className='estimation-calcs-label-high'>Haute</div>
              <div>{formatNumberWithSpaces(Math.max(0, row.high_amount), true, "€")}</div>
            </div>
          </div>
          <p className='estimation-calcs-legend'>{row.mid_amount > 0 && row.proportion}</p>
        </Fragment>
      ))}
    </FrameTextPdf>
  );
}

export default EstimationCalcsPdf;
