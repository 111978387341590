import React, { useContext, useEffect, useRef, useState } from "react";
import Input from "../../molecules/formComponents/input/Input";
import Textarea from "../../molecules/formComponents/textarea/Textarea";
import Select from "../../molecules/formComponents/select/Select";
import FilledButton from "../../molecules/buttons/filledButton/FilledButton";
import { get, useForm } from "react-hook-form";
import "./ArticlesEditorForm.css";
import { useArticlesEditorContext } from "../../../context/ArticlesEditorContext";
import TextEditor from "./TextEditor";
import FileInputPreview, { getFileInputValue } from "../../molecules/formComponents/fileInputPreview/FileInputPreview";
import {
  createArticleApi,
  fetchArticlesTagsApi,
  fetchArticlesLeadMagnetsApi,
  updateArticleApi,
  deleteUnuploadedImagesApi,
} from "../../../api/ArticlesEditorApi";
import AppContext from "../../../context/AppContext";
import {
  defaultValidation,
  max2250Validation,
  max2250RequiredValidation,
  selectValidation,
} from "../../../utils/formValidation/FormValidation";
import { formatTextWithNbsp, getImageInfos, getPrettyText } from "../../../utils/Utils";
import UnfilledButton from "../../molecules/buttons/unfilledButton/UnfilledButton";
import { useAuth } from "../../../context/AuthContext";

function ArticlesEditorForm({ currentArticle, setCurrentArticle, fetchAllArticles, createArticle }) {
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    clearErrors,
    unregister,
    watch,
    formState: { errors },
  } = useForm();
  const inputForm = { register, setValue, unregister, watch };
  const { createNotification, setModalContent, setModalVisible } = useContext(AppContext);
  const [leadMagnetsOptions, setLeadMagnetsOptions] = useState([]);
  const [isFullscreenOn, setIsFullscreenOn] = useState(false);
  const [isArticlePublished, setIsArticlePublished] = useState(false);
  const [headerImg, setHeaderImg] = useState("");
  const [articlesTags, setArticlesTags] = useState([]);
  const [editorValue, setEditorValue] = useState("");
  const { smallCTA, smallCTAPlaceholder, bigCTA, bigCTAPlaceholder } = useArticlesEditorContext();
  const formValues = watch();
  const { canAccess, accesses } = useAuth();

  useEffect(() => {
    fetchAllArticlesTags();
    fetchAllLeadMagnets();
  }, []);

  useEffect(() => {
    if (currentArticle) setupArticle(currentArticle);
  }, [currentArticle, leadMagnetsOptions, articlesTags]);

  async function fetchAllArticlesTags() {
    try {
      setArticlesTags((await fetchArticlesTagsApi()).data);
    } catch (error) {}
  }

  async function fetchAllLeadMagnets() {
    try {
      const res = (await fetchArticlesLeadMagnetsApi()).data;
      setLeadMagnetsOptions(res);
    } catch (error) {
      createNotification(
        "Une erreur est survenue lors de la récupération des outils pratiques",
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  function setupArticle(article) {
    const tagId = articlesTags.find(tag => tag.name === article.tag)?.id;
    const leadMagnetId = leadMagnetsOptions.find(option => option.lead_magnet_file === currentArticle.lead_magnet_file);

    setValue("title", getPrettyText(article.title));
    setValue("description", article.description);
    setValue("meta_title", article.meta_title);
    setValue("meta_description", article.meta_description);
    setValue("url", article.url);
    setValue("header_img_alt", article.header_img_alt);
    setValue("created_at", article.created_at);
    setValue("updated_at", article.updated_at);
    setValue("faq.faq_title", getPrettyText(article?.faq?.faq_title));
    setValue("faq.faq_question_1.question", getPrettyText(article?.faq?.faq_question_1?.question));
    setValue("faq.faq_question_1.answer", article?.faq?.faq_question_1?.answer);
    setValue("faq.faq_question_2.question", getPrettyText(article?.faq?.faq_question_2?.question));
    setValue("faq.faq_question_2.answer", article?.faq?.faq_question_2?.answer);
    setValue("faq.faq_question_3.question", getPrettyText(article?.faq?.faq_question_3?.question));
    setValue("faq.faq_question_3.answer", article?.faq?.faq_question_3?.answer);

    if (leadMagnetId) setValue("lead_magnet", leadMagnetId.id);
    else setValue("lead_magnet", "_null");

    if (tagId) setValue("tag", tagId);
    else setValue("tag", "_null");

    if (article.header_img) {
      setHeaderImg(
        `${process.env.REACT_APP_API_URL}/assets/ConseilsPros/${article.url}/miniature/${article.header_img}`,
      );
      setValue("header_img", true);
    } else setHeaderImg(null);

    setIsArticlePublished(article.is_published);

    clearErrors();
  }

  async function formSubmit(values, force) {
    try {
      if (isArticlePublished && force !== true) {
        setModalContent({
          title: "⚠️ Attention",
          content: (
            <div>
              <p>
                Cet article est déjà publié
                {!areAllFieldsFilled(values) ? " et certains champs du formulaire sont vide" : ""}. Si vous continuez,
                vous allez écraser la version actuelle de l'article.
                <br />
                Voulez-vous continuer ?
              </p>
              <div className='modal-buttons-row'>
                <UnfilledButton onClick={() => setModalVisible(false)}>Annuler</UnfilledButton>
                <FilledButton onClick={() => formSubmit(values, true)}>Continuer</FilledButton>
              </div>
            </div>
          ),
        });
        return setModalVisible(true);
      } else setModalVisible(false);

      values.content = formatArticleForUpload(editorValue);
      values = formatLeadMagnetForUpload(values);
      values.tag = articlesTags.find(tag => tag.id + "" === values.tag + "")?.name;
      values.title = formatTextWithNbsp(values.title);
      values.faq.faq_title = formatTextWithNbsp(values.faq.faq_title);
      values.faq.faq_question_1.question = formatTextWithNbsp(values.faq.faq_question_1.question);
      values.faq.faq_question_2.question = formatTextWithNbsp(values.faq.faq_question_2.question);
      values.faq.faq_question_3.question = formatTextWithNbsp(values.faq.faq_question_3.question);

      if (!areAllFieldsFilled(values) && force !== true) {
        window.alert("Tous les champs n'ont pas été remplis, l'article va donc être enregistré en tant que brouillon.");
        values.is_finished = false;
      } else values.is_finished = true;

      if (values.header_img === true) delete values.header_img;
      else if (!values.header_img) values.header_img = "none";
      else values.header_img = getFileInputValue(values.header_img);

      const errorMsg = checkArticleStructure(values);

      if (errorMsg && values.is_finished) {
        if (window.confirm(errorMsg)) await sendArticle(values);
      } else await sendArticle(values);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de l'envoi de l'article. Veuillez réessayer</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  function formatLeadMagnetForUpload(values) {
    const leadMagnet = leadMagnetsOptions.find(option => option.id == values.lead_magnet);

    values = { ...values, ...leadMagnet };
    delete values.lead_magnet;

    return values;
  }

  //remove all tinymce useless content
  function formatArticleForUpload(articleContent) {
    articleContent = replaceCTAs(articleContent);
    articleContent = removeSecurityFromCTAs(articleContent);
    articleContent = removeUnusedAttributes(articleContent);
    articleContent = removePTagsFromTags(articleContent);
    articleContent = addSlashOnLinks(articleContent);
    return articleContent.replace(/�/g, "");
  }

  function replaceCTAs(articleContent) {
    return articleContent?.replaceAll(bigCTAPlaceholder, bigCTA)?.replaceAll(smallCTAPlaceholder, smallCTA);
  }

  function removeSecurityFromCTAs(articleContent) {
    return articleContent?.replaceAll(' contenteditable="false"', "");
  }

  function removeUnusedAttributes(articleContent) {
    return articleContent
      ?.replaceAll(' dir="ltr"', "")
      .replaceAll(' role="presentation"', "")
      .replaceAll(' style="list-style-type: disc;"', "")
      .replaceAll(' role="presentation"', "")
      .replaceAll(' aria-level="1"', "");
  }

  function removePTagsFromTags(articleContent) {
    if (!articleContent) return articleContent;

    const parser = new DOMParser();
    const articleHtml = parser.parseFromString(articleContent, "text/html");

    const pTags = articleHtml.querySelectorAll("p");
    for (const pTag of pTags) {
      const children = pTag.children;
      if (
        children.length === 1 &&
        (children[0].tagName === "IMG" || children[0].tagName === "BUTTON" || children[0].tagName === "DIV")
      ) {
        pTag.replaceWith(children[0]);
      }
    }

    return articleHtml.body.innerHTML.replaceAll("<p></p>", "");
  }

  function addSlashOnLinks(articleContent) {
    return articleContent?.replaceAll(/href="(?!http|https|\/)([^"]*)"/g, 'href="/$1"');
  }

  function getAllImagesNamesInArticle(articleContent) {
    const result = [];
    const parser = new DOMParser();
    const doc = parser.parseFromString(articleContent, "text/html");
    const images = doc.querySelectorAll("img");

    for (const image of images) {
      if (image.src.includes("ConseilsPros")) result.push({ image_url: image.src.split("/").reverse()[0] });
    }
    return result;
  }

  async function sendArticle(values) {
    try {
      let res;
      if (currentArticle) {
        res = await updateArticleApi(values, currentArticle.id);
      } else {
        res = await createArticleApi(values);
      }

      setCurrentArticle(res.data);

      setHeaderImg(
        `${process.env.REACT_APP_API_URL}/assets/ConseilsPros/${res.data.url}/miniature/${res.data.header_img}`,
      );

      const images = getAllImagesNamesInArticle(res.data.content);
      const relevant_path = "ConseilsPros/" + res.data.url + "/images";

      await deleteUnuploadedImagesApi(relevant_path, images);
      fetchAllArticles();

      createNotification(`${values.is_finished ? "L'article" : "Le brouillon"} a été enregistré avec succès`);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de l'envoi de l'article. Veuillez réessayer</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  function checkArticleStructure(values) {
    if (!values.content) return "Votre article est vide. Voulez-vous l'enregistrer quand même ?";

    const articleContent = values.content;
    const errors = [];
    const nbSmallCTA = [...articleContent?.matchAll('<button class="gold-bordered-btn d-flex m-auto"')]?.length;
    const nbLeadMagnet = [...articleContent?.matchAll(/id="leadMagnet"/g)]?.length;
    const nbBigCTA = [...articleContent?.matchAll(new RegExp('<div class="container mt-5 mb-5">', "g"))]?.length;

    if (!nbLeadMagnet) {
      errors.push("• Pas de lead magnet");
    } else if (nbLeadMagnet !== 1) {
      errors.push("• Plusieurs lead magnets");
    }

    if (!nbBigCTA) {
      errors.push("• Pas de gros CTA");
    } else if (nbBigCTA !== 1) {
      errors.push("• Plusieurs gros CTA");
    }

    if (!nbSmallCTA) {
      errors.push("• Pas de petit CTA");
    } else if (nbSmallCTA !== 2) {
      errors.push(`• Pas le bon nombre de petits CTA (${nbSmallCTA} au lieu de 2)`);
    }

    if (values.title?.length > 70)
      errors.push(`• Le meta title doit contenir moins de 70 caractères (${values.meta_title.length} actuellement)`);

    if (values.meta_title?.length < 50 || values.meta_title?.length > 60)
      errors.push(`• Le meta title doit contenir entre 50 et 60 caractères (${values.meta_title.length} actuellement)`);

    if (values.meta_description?.length < 140 || values.meta_description?.length > 160)
      errors.push(
        `• La meta description doit contenir entre 140 et 160 caractères (${values.meta_title.length} actuellement)`,
      );

    if (!errors.length) return false;

    return `Votre article contient ${
      errors.length > 1 ? "les erreurs suivantes" : "l'erreur suivante"
    } :\n${errors.join("\n")}\nVoulez-vous l'enregistrer quand même ?`;
  }

  function showPreviewModal(articleUrl) {
    setModalContent({
      title: "Prévisualisation de l'article",
      content: (
        <>
          <p>
            La prévisualisation permet de voir le rendu final de votre article.
            <br />
            <br />
            En cliquant sur "Continuer", une nouvelle fenêtre va s'ouvrir dans votre navigateur.
            <br />
            <br />
            Cette nouvelle fenêtre n'évolue pas en temps réel avec vos modifications. Pour pouvoir les voir, pensez à
            "Enregistrer l'article" et à rafraîchir la page de prévisualisation.
          </p>
          <div className='modal-buttons-row mt-md'>
            <UnfilledButton onClick={() => setModalVisible(false)}>Annuler</UnfilledButton>
            <a
              className='button'
              href={`https://estimermoncommerce.fr/conseils-pros/${articleUrl}`}
              target='_blank'
              onClick={() => setModalVisible(false)}>
              <FilledButton>Ouvrir la prévisualisation</FilledButton>
            </a>
          </div>
        </>
      ),
    });
    setModalVisible(true);
  }

  function areAllFieldsFilled(values) {
    return (
      Object.keys(values).every(
        key => key === "updated_at" || (values[key] !== null && values[key] !== "" && values[key] !== "_null"),
      ) &&
      values.faq.faq_title &&
      values.faq.faq_question_1.question &&
      values.faq.faq_question_1.answer &&
      values.faq.faq_question_3.question &&
      values.faq.faq_question_3.answer &&
      values.faq.faq_question_2.question &&
      values.faq.faq_question_2.answer
    );
  }

  return (
    <fieldset className='borderless-fieldset full-page-form w-100 mt-0'>
      {currentArticle.url ? (
        <form onSubmit={handleSubmit(formSubmit)} className='form-container'>
          <Input
            showCharsCount
            name='title'
            label="Titre de l'article h1"
            tip="Titre principal de l'article. Doit contenir 70 caractères maximum (espaces compris)"
            useForm={inputForm}
            error={errors?.title?.message}
            validation={currentArticle?.is_finished ? { defaultValidation } : {}}
          />
          <Input
            disabled
            name='url'
            tip='Au format suivant (sans /) : url-de-l-article'
            label='URL'
            useForm={inputForm}
            error={errors?.url?.message}
          />
          <Input
            showCharsCount
            name='meta_title'
            tip='Doit contenir entre 50 et 60 caractères (espaces compris)'
            label='Balise title (meta title)'
            useForm={inputForm}
            error={errors?.meta_title?.message}
            validation={currentArticle?.is_finished ? { defaultValidation } : {}}
          />
          <Textarea
            validation={currentArticle?.is_finished ? { max2250RequiredValidation } : { max2250Validation }}
            showWordsCount
            showCharsCount
            tip='Doit contenir entre 140 et 160 caractères (espaces compris)'
            name='meta_description'
            label='Meta description'
            useForm={inputForm}
            error={errors?.meta_description?.message}
          />
          <Textarea
            showWordsCount
            showCharsCount
            tip='Même contenu que le meta description'
            validation={currentArticle?.is_finished ? { max2250RequiredValidation } : { max2250Validation }}
            name='description'
            label='Description'
            useForm={inputForm}
            error={errors?.description?.message}
          />
          <Select
            name='tag'
            label='Catégorie'
            useForm={inputForm}
            error={errors?.tag?.message}
            validation={currentArticle?.is_finished ? { selectValidation } : {}}
            tip='Catégorie sur EstimerMonCommerce.fr'>
            {articlesTags.map(tag => (
              <option key={tag.id} value={tag.id}>
                {tag.name}
              </option>
            ))}
          </Select>
          <FileInputPreview
            register={register}
            setValue={setValue}
            name='header_img'
            label="Image d'en-tête"
            previewDefaultValue={headerImg}
          />
          <Input
            showWordsCount
            tip='3 à 5 mots clés séparés par des espaces. Exemple : éditeur articles estimermoncommerce'
            name='header_img_alt'
            label="Alt de l'image d'en-tête"
            useForm={inputForm}
            error={errors?.header_img_alt?.message}
            validation={currentArticle?.is_finished ? { defaultValidation } : {}}
          />
          <Input
            tip='Au format JJ-MM-AAA. Exemple : 13-07-1998'
            name='created_at'
            label='Date de création'
            useForm={inputForm}
            error={errors?.created_at?.message}
            validation={currentArticle?.is_finished ? { defaultValidation } : {}}
          />
          <Input
            tip='Au format JJ-MM-AAA. Exemple : 02-08-2003'
            name='updated_at'
            label='Date de mise à jour'
            useForm={inputForm}
            error={errors?.updated_at?.message}
            validation={{}}
          />
          <Select
            tip="Outil pratique proposé en lead magnet sur l'article"
            useForm={inputForm}
            name='lead_magnet'
            label='Outil pratique relié'
            error={errors?.lead_magnet?.message}
            validation={currentArticle?.is_finished ? { selectValidation } : {}}>
            {leadMagnetsOptions.map(option => (
              <option key={option.id} value={option.id}>
                {option.lead_magnet_file}
              </option>
            ))}
          </Select>
          <TextEditor
            setIsFullscreenOn={setIsFullscreenOn}
            editorValue={editorValue}
            setEditorValue={setEditorValue}
            currentArticle={currentArticle}
          />
          <Input
            name='faq.faq_title'
            label='Titre de la FAQ'
            useForm={inputForm}
            error={errors?.faq?.faq_title?.message}
            validation={currentArticle?.is_finished ? { defaultValidation } : {}}
          />
          <Input
            name='faq.faq_question_1.question'
            label='Question 1'
            useForm={inputForm}
            error={errors?.faq?.faq_question_1?.question?.message}
            validation={currentArticle?.is_finished ? { defaultValidation } : {}}
          />
          <Textarea
            validation={currentArticle?.is_finished ? { max2250RequiredValidation } : { max2250Validation }}
            showWordsCount
            showCharsCount
            name='faq.faq_question_1.answer'
            label='Réponse 1'
            useForm={inputForm}
            error={errors?.faq?.faq_question_1?.answer?.message}
          />
          <Input
            name='faq.faq_question_2.question'
            label='Question 2'
            useForm={inputForm}
            error={errors?.faq?.faq_question_2?.question?.message}
            validation={currentArticle?.is_finished ? { defaultValidation } : {}}
          />
          <Textarea
            validation={currentArticle?.is_finished ? { max2250RequiredValidation } : { max2250Validation }}
            showWordsCount
            showCharsCount
            name='faq.faq_question_2.answer'
            label='Réponse 2'
            useForm={inputForm}
            error={errors?.faq?.faq_question_2?.answer?.message}
          />
          <Input
            name='faq.faq_question_3.question'
            label='Question 3'
            useForm={inputForm}
            error={errors?.faq?.faq_question_3?.question?.message}
            validation={currentArticle?.is_finished ? { defaultValidation } : {}}
          />
          <Textarea
            validation={currentArticle?.is_finished ? { max2250RequiredValidation } : { max2250Validation }}
            showWordsCount
            showCharsCount
            name='faq.faq_question_3.answer'
            label='Réponse 3'
            useForm={inputForm}
            error={errors?.faq?.faq_question_3?.answer?.message}
          />
          {canAccess("article_update", accesses) && (
            <div className={`articles-editor-buttons-row ${isFullscreenOn ? "fullscreen" : ""}`}>
              <FilledButton padding='10px 25px' onClick={() => showPreviewModal(currentArticle?.url)}>
                Prévisualiser l'article
              </FilledButton>
              <FilledButton padding='10px 25px' type='submit'>
                {areAllFieldsFilled(formValues) || isArticlePublished
                  ? "Enregistrer l'article"
                  : "Enregistrer le brouillon"}
              </FilledButton>
            </div>
          )}
        </form>
      ) : (
        <div className='mid-page-button centered my-auto'>
          <FilledButton padding='10px 25px' onClick={createArticle}>
            Ajouter un article
          </FilledButton>
        </div>
      )}
    </fieldset>
  );
}

export default ArticlesEditorForm;
