import React, { useContext, useState, useEffect, useRef, Fragment } from "react";
import "./FolderDeliverables.css";
import FilledButton from "../../../../../../../components/molecules/buttons/filledButton/FilledButton";
import Add from "../../../../../../../components/atoms/icons/general/add/Add";
import { useWindowSize } from "@uidotdev/usehooks";
import AppContext from "../../../../../../../context/AppContext";
import CreateDeliverableModal from "../../../../../../../components/forms/modals/account/createDeliverableModal/CreateDeliverableModal";
import {
  deleteAllCreditSimulatorsFromDeliverableApi,
  deleteAllEstimationsFromDeliverableApi,
  deleteAllFinancingPlansFromDeliverableApi,
  deleteCreditSimulatorFromDeliverableApi,
  deleteDeliverableApi,
  deleteEstimationFromDeliverableApi,
  deleteFinancingPlanFromDeliverableApi,
  fetchDeliverablesByFolderIdApi,
} from "../../../../../../../api/FolderApi";
import {
  downloadCreditSimulatorPdfApi,
  downloadEstimationPdfApi,
  downloadFinancingPlanPdfApi,
  downloadFolderApi,
  downloadFolderDeliverableApi,
} from "../../../../../../../api/DownloadApi";
import Card from "../../../../../../../components/molecules/cards/card/Card";
import Hr from "../../../../../../../components/molecules/hr/Hr";
import Bracket from "../../../../../../../components/atoms/icons/general/bracket/Bracket";
import Pen from "../../../../../../../components/atoms/icons/account/pen/Pen";
import Trash from "../../../../../../../components/atoms/icons/general/trash/Trash";
import Deliverable from "../../../../../../../components/atoms/icons/folder/deliverable/Deliverable";
import UnfilledButton from "../../../../../../../components/molecules/buttons/unfilledButton/UnfilledButton";
import AddEstimationToDeliverableModal from "../../../../../../../components/forms/modals/account/addEstimationToDeliverableModal/AddEstimationToDeliverableModal";
import UpdateDeliverableModal from "../../../../../../../components/forms/modals/account/updateDeliverableModal/UpdateDeliverableModal";
import AddFinancingPlanToDeliverableModal from "../../../../../../../components/forms/modals/account/addFinancingPlanToDeliverableModal/AddFinancingPlanToDeliverableModal";
import DuplicateDeliverableModal from "../../../../../../../components/forms/modals/account/duplicateDeliverableModal/DuplicateDeliverableModal";
import AddCreditSimulatorToDeliverableModal from "../../../../../../../components/forms/modals/account/addCreditSimulatorToDeliverableModal/AddCreditSimulatorToDeliverableModal";
import { Link } from "react-router-dom";
import Download from "../../../../../../../components/atoms/icons/general/download/Download";
import Duplicate from "../../../../../../../components/atoms/icons/account/duplicate/Duplicate";
import Eye from "../../../../../../../components/atoms/icons/general/eye/Eye";
import HiddenEye from "../../../../../../../components/atoms/icons/general/hiddenEye/HiddenEye";
import { copyToClipboard, createDownloadLink } from "../../../../../../../utils/Utils";
import {
  EstimationsTabDropdownMenu,
  EstimationsTabFilters,
  EstimationTabRowItem,
} from "../../../accountsToolsTab/EstimationsTabComponents";
import {
  FinancingPlansTabDropdownMenu,
  FinancingPlansTabFilters,
  FinancingPlanTabRowItem,
} from "../../../accountsToolsTab/FinancingPlansTabComponents";
import {
  CreditSimulatorsTabDropdownMenu,
  CreditSimulatorsTabFilters,
  CreditSimulatorTabRowItem,
} from "../../../accountsToolsTab/CreditSimulatorsTabComponents";
import DuplicateEstimationModal from "../../../../../../../components/forms/modals/account/duplicateEstimationModal/DuplicateEstimationModal";
import DuplicateFinancingPlanModal from "../../../../../../../components/forms/modals/account/duplicateFinancingPlanModal/DuplicateFinancingPlanModal";
import DuplicateCreditSimulatorModal from "../../../../../../../components/forms/modals/account/duplicateCreditSimulatorModal/DuplicateCreditSimulator";

function FolderDeliverables({ folder, triggerFolderUpdate }) {
  const {
    setModalContent,
    setModalVisible,
    createNotification,
    isDownloadNotificationLoading,
    setIsDownloadNotificationLoading,
  } = useContext(AppContext);
  const { width } = useWindowSize();
  const [deliverables, setDeliverables] = useState([]);
  const [openedItemDropdown, setOpenedItemDropdown] = useState(false);
  const [openedDeliverable, setOpenedDeliverable] = useState();
  const [openedDeliverableDropdown, setOpenedDeliverableDropdown] = useState(-1);
  const estimationsContainerRef = useRef(null);
  const estimationsDropdownMenuRef = useRef(null);
  const financingPlansContainerRef = useRef(null);
  const financingPlansDropdownMenuRef = useRef(null);
  const creditSimulationsContainerRef = useRef(null);
  const creditSimulationsDropdownMenuRef = useRef(null);

  const types = ["estimation", "financing_plan", "credit_simulator"];

  const itemsComponents = {
    estimation: {
      filters: EstimationsTabFilters,
      rowItem: EstimationTabRowItem,
      dropdownMenu: EstimationsTabDropdownMenu,
      dropdownMenuRef: estimationsDropdownMenuRef,
      containerRef: estimationsContainerRef,
    },
    financing_plan: {
      filters: FinancingPlansTabFilters,
      rowItem: FinancingPlanTabRowItem,
      dropdownMenu: FinancingPlansTabDropdownMenu,
      dropdownMenuRef: financingPlansDropdownMenuRef,
      containerRef: financingPlansContainerRef,
    },
    credit_simulator: {
      filters: CreditSimulatorsTabFilters,
      rowItem: CreditSimulatorTabRowItem,
      dropdownMenu: CreditSimulatorsTabDropdownMenu,
      dropdownMenuRef: creditSimulationsDropdownMenuRef,
      containerRef: creditSimulationsContainerRef,
    },
  };

  useEffect(() => {
    if (folder) fetchAlllDeliverables();
  }, [folder]);

  useEffect(() => {
    setOpenedItemDropdown(false);
  }, [openedDeliverable]);

  useEffect(() => {
    "openedItemDropdown", openedItemDropdown;
  }, [openedItemDropdown]);

  useEffect(() => {
    document.addEventListener("click", handleCloseDropdownMenu);

    return () => {
      document.removeEventListener("click", handleCloseDropdownMenu);
    };
  }, [
    estimationsDropdownMenuRef.current,
    financingPlansDropdownMenuRef.current,
    creditSimulationsDropdownMenuRef.current,
  ]);

  // Deliverables

  async function fetchAlllDeliverables() {
    try {
      const res = (await fetchDeliverablesByFolderIdApi(folder.data.folder_id)).data;

      const extractedLivrables = res.map(item => ({
        id: item.deliverable.data.deliverable_id,
        name: item.deliverable.data.deliverable_name,
        estimations: [
          ...item.deliverable.estimations.fonds_de_commerce,
          ...item.deliverable.estimations.titres_de_societe,
          ...item.deliverable.estimations.murs_commerciaux,
        ],
        financing_plans: [
          ...item.deliverable.financing_plans.financing_plan_fdc,
          ...item.deliverable.financing_plans.financing_plan_murs,
        ],
        credit_simulators: item.deliverable.credit_simulators.map(simulator => simulator.credit_simulator),
      }));

      extractedLivrables.forEach(
        deliverable => (deliverable.financing_plans = deliverable.financing_plans.map(plan => plan.financing_plan)),
      );

      setDeliverables(extractedLivrables || []);
    } catch (error) {
      setDeliverables([]);
      createNotification(
        <>Une erreur est survenue lors de la récupération de vos livrables. Veuillez réessayer</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  function openCreateDeliverableModal() {
    setModalContent({
      title: "Créer un livrable",
      content: (
        <CreateDeliverableModal
          folder={folder}
          folderId={folder.data.folder_id}
          onLivrableCreated={() => triggerDeliverableUpdate()}
        />
      ),
      className: "big-modal",
    });
    setModalVisible(true);
  }

  async function handleDeleteDeliverable(deliverableId, e) {
    e?.stopPropagation();

    setModalContent({
      title: "⚠️ Attention",
      content: (
        <>
          <p>
            Êtes-vous sûr de vouloir supprimer ce livrable ?<br />
            Cette action est irréversible.
          </p>
          <div className='modal-buttons-row'>
            <UnfilledButton padding='10px 25px' onClick={() => setModalVisible(false)}>
              Annuler
            </UnfilledButton>
            <FilledButton
              padding='10px 25px'
              onClick={() => {
                deleteDeliverable(deliverableId);
              }}>
              Retirer
            </FilledButton>
          </div>
        </>
      ),
    });
    setModalVisible(true);
  }

  async function deleteDeliverable(deliverableId) {
    try {
      await deleteDeliverableApi(deliverableId);

      triggerDeliverableUpdate();

      createNotification(<>Le livrable a été supprimé avec succès</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la suppression du livrable</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    } finally {
      setModalVisible(false);
    }
  }

  function handleDuplicateDeliverable(deliverable, e) {
    e?.stopPropagation();

    setModalContent({
      title: "Dupliquer le livrable",
      content: (
        <DuplicateDeliverableModal
          deliverableId={deliverable.id}
          currentName={deliverable.name}
          onDuplicateSuccess={() => triggerDeliverableUpdate()}
        />
      ),
    });
    setModalVisible(true);
  }

  function handleModifyDeliverable(deliverable, e) {
    e?.stopPropagation();

    setModalContent({
      title: "Modifier le livrable",
      content: <UpdateDeliverableModal deliverable={deliverable} onUpdateSuccess={() => triggerDeliverableUpdate()} />,
    });
    setModalVisible(true);
  }

  function triggerDeliverableUpdate() {
    fetchAlllDeliverables();
    triggerFolderUpdate();
  }

  async function downloadFolder() {
    try {
      setIsDownloadNotificationLoading(true);

      const res = await downloadFolderApi(folder.data.folder_id);

      const downloadUrl = res.data;

      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", "");
      document.body.appendChild(link);
      link.click();

      createNotification(<>Le dossier a été téléchargé avec succès</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la génération de votre dossier. Veuillez réessayer</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    } finally {
      setIsDownloadNotificationLoading(false);
    }
  }

  async function downloadDeliverable(deliverableId, e) {
    e?.stopPropagation();

    try {
      setIsDownloadNotificationLoading(true);

      const res = await downloadFolderDeliverableApi(deliverableId);

      const downloadUrl = res.data;

      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", "");
      document.body.appendChild(link);
      link.click();

      createNotification(<>Le livrable a été téléchargé avec succès</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la génération de votre livrable. Veuillez réessayer</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    } finally {
      setIsDownloadNotificationLoading(false);
    }
  }

  function toggleOpenedDeliverableDropdown(index, event) {
    event.stopPropagation();
    setOpenedDeliverableDropdown(prev => (prev === index ? -1 : index));
  }

  // Items management

  async function deleteSingleItem(type, deliverableId, itemId) {
    try {
      switch (type) {
        case "estimation":
          await deleteEstimationFromDeliverableApi(deliverableId, itemId);
          break;
        case "financing_plan":
          await deleteFinancingPlanFromDeliverableApi(deliverableId, itemId);
          break;
        case "credit_simulator":
          await deleteCreditSimulatorFromDeliverableApi(deliverableId, itemId);
          break;
      }

      triggerDeliverableUpdate();

      createNotification(<>La ressource a été supprimée du livrable avec succès</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la suppression de la ressource du livrable. Veuillez réesssayer.</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  async function deleteAllItemsFromDeliverable(type, deliverableId) {
    try {
      switch (type) {
        case "estimation":
          await deleteAllEstimationsFromDeliverableApi(deliverableId);
          break;
        case "financing_plan":
          await deleteAllFinancingPlansFromDeliverableApi(deliverableId);
          break;
        case "credit_simulator":
          await deleteAllCreditSimulatorsFromDeliverableApi(deliverableId);
          break;
      }

      triggerDeliverableUpdate();

      createNotification(<>Les ressources ont été supprimées du livrable avec succès</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la suppression des ressources du livrable.</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  async function downloadItemPdf(itemId, type) {
    try {
      type;

      switch (type) {
        case "estimation":
          setIsDownloadNotificationLoading(true);
          const resEstimation = await downloadEstimationPdfApi(itemId);

          const linkEstimation = createDownloadLink(resEstimation.data);
          if (!linkEstimation) throw new Error("");

          linkEstimation.click();
          linkEstimation.remove();

          createNotification(<>Votre estimation a été téléchargée avec succès</>);
          break;
        case "financing_plan":
          setIsDownloadNotificationLoading(true);
          const resFinancingplan = await downloadFinancingPlanPdfApi(itemId);

          const linkFinancingplan = createDownloadLink(resFinancingplan.data);
          if (!linkFinancingplan) throw new Error("");

          linkFinancingplan.click();
          linkFinancingplan.remove();

          createNotification(<>Votre plan de financement a été téléchargé avec succès</>);
          break;
        case "credit_simulator":
          setIsDownloadNotificationLoading(true);
          const resCreditSimulation = await downloadCreditSimulatorPdfApi(itemId);

          const linkCreditSimulation = createDownloadLink(resCreditSimulation.data);
          if (!linkCreditSimulation) throw new Error("");

          linkCreditSimulation.click();
          linkCreditSimulation.remove();

          createNotification(<>Votre simulation de prêt a été téléchargée avec succès</>);
          break;
      }
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors du téléchargement de votre {getItemsCategoryTitle(type)}. Veuillez réessayer</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    } finally {
      setIsDownloadNotificationLoading(false);
    }
  }

  function showDuplicateItemModal(type, itemId, itemName) {
    switch (type) {
      case "estimation":
        setModalContent({
          title: "Dupliquer l'estimation",
          content: (
            <DuplicateEstimationModal
              defaultValue={getDuplicateItemName(getEstimationName(itemId))}
              fetchEstimationsFunction={() => {}}
              estimationId={itemId}
            />
          ),
        });
        break;
      case "financing_plan":
        setModalContent({
          title: "Dupliquer le plan de financement",
          content: (
            <DuplicateFinancingPlanModal
              defaultValue={getDuplicateItemName(itemName)}
              fetchFinancingPlansFunction={() => {}}
              financingPlanId={itemId}
            />
          ),
        });
        break;
      case "credit_simulator":
        setModalContent({
          title: "Dupliquer la simulation de prêt",
          content: (
            <DuplicateCreditSimulatorModal
              defaultValue={getDuplicateItemName(itemName)}
              fetchCreditSimulatorsFunction={() => {}}
              creditSimulatorId={itemId}
            />
          ),
        });
    }
    setModalVisible(true);
  }

  function addItemToDeliverable(type, deliverableId) {
    const livrable = deliverables.find(livrable => livrable.id === deliverableId);

    switch (type) {
      case "estimation":
        setModalContent({
          title: "Ajouter une/des estimation(s) au livrable : " + livrable.name,
          content: (
            <AddEstimationToDeliverableModal
              folder={folder}
              deliverableId={deliverableId}
              onEstimationsAdded={() => triggerDeliverableUpdate()}
            />
          ),
          className: "big-modal",
        });
        break;
      case "financing_plan":
        setModalContent({
          title: "Ajouter un/des plan(s) de financement au livrable : " + livrable.name,
          content: (
            <AddFinancingPlanToDeliverableModal
              folder={folder}
              deliverableId={deliverableId}
              onFinancingPlansAdded={() => triggerDeliverableUpdate()}
            />
          ),
          className: "big-modal",
        });
        break;
      case "credit_simulator":
        setModalContent({
          title: "Ajouter un/des simulation(s) de prêt au livrable : " + livrable.name,
          content: (
            <AddCreditSimulatorToDeliverableModal
              folder={folder}
              deliverableId={deliverableId}
              onCreditSimulatorsAdded={() => triggerDeliverableUpdate()}
            />
          ),
          className: "big-modal",
        });

        break;
    }
    setModalVisible(true);
  }

  // Items name getters

  function getDeletionWording(type, isPlural) {
    let string = "Supprimer ";
    string += isPlural ? "tou" : "l";

    switch (type) {
      case "estimation":
        string += isPlural ? "tes les estimations" : "'estimation";
        break;
      case "financing_plan":
        string += isPlural ? "s les plans de financement" : "e plan de financement";
        break;
      case "credit_simulator":
        string += isPlural ? "tes les simulations de prêt" : "a simulation de prêt";
    }

    string += " du livrable";

    return string;
  }

  function getItemsCategoryTitle(type) {
    switch (type) {
      case "estimation":
        return "Estimation(s) du livrable :";
      case "financing_plan":
        return "Plans(s) de financement du livrable :";
      case "credit_simulator":
        return "Simulation(s) de prêt du livrable :";
    }
  }

  function getItemClassName(type) {
    return type.replace("_", "-") + "s";
  }

  function getItemsCategoryName(type) {
    switch (type) {
      case "estimation":
        return "e estimation";
      case "financing_plan":
        return " plan de financement";
      case "credit_simulator":
        return "e simulation de prêt";
    }
  }

  function getItemDate(item, type) {
    switch (type) {
      case "estimation":
        return item?.date_creation_estimation?.substring(0, 10);
      case "financing_plan":
      case "credit_simulator":
        return item?.date_creation?.split(" ")[0];
    }
  }

  function getEstimationName(itemId) {
    const activeDeliverable = deliverables.find(livrable => livrable.id === openedDeliverable);
    const estimation = activeDeliverable.estimations.find(estimation => estimation.id === itemId);
    return estimation.infos.enseigne ?? estimation.infos.nom;
  }

  function getDuplicateItemName(itemName) {
    const regex = /#\d+$/;

    if (regex.test(itemName)) {
      const match = itemName.match(/\d+$/);

      return itemName.replace(regex, `#${parseInt(match[0]) + 1}`);
    } else {
      return `${itemName} #1`;
    }
  }

  // Utils

  function toggleOpenedLivrable(id, onlyTrue, e) {
    e?.stopPropagation();

    if (onlyTrue) return setOpenedDeliverable(id);
    setOpenedDeliverable(prev => (prev === id ? -1 : id));
  }

  function copyIdToClipboard(e, id) {
    e.preventDefault();
    e.stopPropagation();

    copyToClipboard(id, <>La référence a été copiée dans votre presse-papier avec succès.</>, createNotification);
  }

  function handleCloseDropdownMenu() {
    if (
      !estimationsDropdownMenuRef.current ||
      !financingPlansDropdownMenuRef.current ||
      !creditSimulationsDropdownMenuRef.current
    )
      return;

    setOpenedItemDropdown(false);
  }

  function toggleOpenedItemDropdown(e, key, type) {
    e.preventDefault();
    if (
      !estimationsDropdownMenuRef.current ||
      !financingPlansDropdownMenuRef.current ||
      !creditSimulationsDropdownMenuRef.current
    )
      return;

    const tmp = openedItemDropdown;
    let top = 0;
    const activeDeliverable = deliverables.find(livrable => livrable.id === openedDeliverable);

    switch (type) {
      case "estimation":
        top = estimationsContainerRef.current.children[key].offsetTop + 60;
        break;
      case "financing_plan":
        top = financingPlansContainerRef.current.children[key].offsetTop + 60;
        break;
      case "credit_simulator":
        top = creditSimulationsContainerRef.current.children[key].offsetTop + 60;
        break;
    }
    "key", key;

    if (openedItemDropdown) setOpenedItemDropdown(false);
    setTimeout(() => {
      if (tmp.key !== key) {
        ("show");
        setOpenedItemDropdown({
          item: activeDeliverable[type + "s"][key],
          key,
          type: type,
          top,
        });
      }
    }, 10);
  }

  return (
    <div>
      <div className='tab-content-header'>
        <h2>
          <Deliverable />
          Livrables
        </h2>
        <p>
          Créez votre / vos livrable(s) pour vos vendeurs, acquéreurs ou clients, à partir des éléments de ce dossier.
          <br />
          Vous pouvez créer autant de livrables que vous le souhaitez.
        </p>
      </div>
      <div className='folder-deliverable-buttons'>
        <FilledButton onClick={openCreateDeliverableModal}>
          <Add width={width > 576 ? "30px" : "20px"} /> Créer un livrable
        </FilledButton>
        <FilledButton
          bgColor='#B8C7FF'
          color='var(--dark-blue)'
          onClick={downloadFolder}
          isLoading={isDownloadNotificationLoading}>
          <Download color='var(--dark-blue)' width={width > 576 ? "30px" : "20px"} /> Télécharger tous les livrables
        </FilledButton>
      </div>
      <div className='deliverables-grid mt-md'>
        {deliverables?.length > 0 ? (
          deliverables.map((deliverable, index) => (
            <Card
              key={deliverable?.id}
              className='deliverable-item'
              onClick={() => toggleOpenedLivrable(deliverable?.id, true)}>
              <div className='deliverable-item-head' onClick={e => toggleOpenedLivrable(deliverable?.id, false, e)}>
                <div className='deliverable-name'>
                  <h3>
                    <Deliverable />
                    {deliverable.name}
                  </h3>
                </div>
                <div
                  className='deliverable-actions-buttons'
                  onClick={event => toggleOpenedDeliverableDropdown(index, event)}>
                  <Bracket
                    rotation={openedDeliverableDropdown === index ? "0" : "180deg"}
                    width='20px'
                    color='var(--dark-blue)'
                    className='dropdown-icon-deliverables'
                  />
                  <ul className={`dropdown-menu-deliverables ${openedDeliverableDropdown === index ? "show" : ""}`}>
                    {openedDeliverable !== deliverable?.id ? (
                      <li>
                        <button onClick={() => toggleOpenedLivrable(deliverable?.id)}>
                          <Eye color='var(--dark-blue)' width='22px' /> Accéder
                        </button>
                      </li>
                    ) : (
                      <li>
                        <button onClick={() => toggleOpenedLivrable(deliverable?.id)}>
                          <HiddenEye color='var(--dark-blue)' width='22px' /> Fermer
                        </button>
                      </li>
                    )}
                    <li>
                      <button onClick={() => handleModifyDeliverable(deliverable)}>
                        <Pen width='22px' /> Renommer
                      </button>
                    </li>
                    <li>
                      <button onClick={() => downloadDeliverable(deliverable?.id)}>
                        <Download color='var(--dark-blue)' width='22px' /> Télécharger
                      </button>
                    </li>
                    <li>
                      <button onClick={() => handleDuplicateDeliverable(deliverable)}>
                        <Duplicate width='22px' /> Dupliquer
                      </button>
                    </li>
                    <li>
                      <button onClick={() => handleDeleteDeliverable(deliverable?.id)}>
                        <Trash width='22px' /> Supprimer
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              {openedDeliverable === deliverable?.id && (
                <>
                  <div className='deliverable-content-container'>
                    <div className='w-100'>
                      <Hr margin='20px 0' />
                    </div>
                    {types.map((type, index) => (
                      <Fragment key={type}>
                        <div className='deliverable-estimations-container'>
                          <h4 className='deliverable-content-header'>{getItemsCategoryTitle(type)}</h4>
                          <div className={`folder-table-estimations items-table ${getItemClassName(type)}-table`}>
                            {deliverable[type + "s"].length > 0 && (
                              <div className='items-filters'>{itemsComponents[type].filters()}</div>
                            )}
                            <ul
                              ref={itemsComponents[type].dropdownMenuRef}
                              className={`dropdown-menu-item ${openedItemDropdown?.type === type ? "show" : ""}`}
                              style={{ "--top": openedItemDropdown?.top + "px" }}>
                              {itemsComponents[type].dropdownMenu({
                                openedItemDropdown: openedItemDropdown.type === type ? openedItemDropdown : false,
                                downloadItemPdf: itemId => downloadItemPdf(itemId, type),
                                showDuplicateItemModal: (...params) => showDuplicateItemModal(type, ...params),
                                removeFromDeliverable: itemId => deleteSingleItem(type, deliverable.id, itemId),
                              })}
                            </ul>
                            <div className='items-table-body' ref={itemsComponents[type].containerRef}>
                              {Array.isArray(deliverable?.[type + "s"]) && deliverable[type + "s"].length > 0 ? (
                                deliverable[type + "s"].map((item, index) => (
                                  <Fragment key={index}>
                                    {itemsComponents[type].rowItem({
                                      index: index,
                                      copyIdToClipboard: copyIdToClipboard,
                                      item: item,
                                      date: getItemDate(item, type),
                                      toggleOpenedItemDropdown: (...params) =>
                                        toggleOpenedItemDropdown(...params, type),
                                      openedItemDropdown: openedItemDropdown.type === type ? openedItemDropdown : false,
                                    })}
                                  </Fragment>
                                ))
                              ) : (
                                <div>Aucun{getItemsCategoryName(type)} associé</div>
                              )}
                            </div>
                            <div className='deliverable-estimations-actions-button'>
                              <UnfilledButton
                                className='add-estimation-button'
                                onClick={() => addItemToDeliverable(type, deliverable.id)}>
                                Ajouter un{getItemsCategoryName(type)}
                              </UnfilledButton>
                              {deliverable[type + "s"].length > 0 && (
                                <UnfilledButton
                                  className='add-estimation-button'
                                  bgColor='var(--dark-red)'
                                  onClick={() => deleteAllItemsFromDeliverable(type, deliverable.id)}>
                                  {getDeletionWording(type, deliverable[type + "s"].length > 1)}
                                </UnfilledButton>
                              )}
                            </div>
                          </div>
                        </div>
                        {index < types.length - 1 && (
                          <div className='w-100'>
                            <Hr margin='20px 0' />
                          </div>
                        )}
                      </Fragment>
                    ))}
                  </div>
                </>
              )}
            </Card>
          ))
        ) : (
          <div className='info-block-deliverable'>
            <p>Aucun livrable dans ce dossier</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default FolderDeliverables;
