import { createClientNoteApi, deleteClientNoteApi, updateClientNoteApi } from "../../../../api/CrmClientsApi";
import { stringToDate } from "../../../../utils/Utils";
import Add from "../../../atoms/icons/general/add/Add";
import Trash from "../../../atoms/icons/general/trash/Trash";
import FilledButton from "../../../molecules/buttons/filledButton/FilledButton";
import Textarea from "../../../molecules/formComponents/textarea/Textarea";
import "./CrmNotesContainer.css";
import React, { useEffect, useState } from "react";

function CrmNotesContainer({ notes, updateNotesTrigger, clientUuid }) {
  const [visibleConfirmation, setVisibleConfirmation] = useState(null);

  useEffect(() => {
    document.addEventListener("click", handleCloseConfirmation);

    return () => {
      document.removeEventListener("click", handleCloseConfirmation);
    };
  }, []);

  function handleCloseConfirmation(e) {
    setVisibleConfirmation(null);
  }

  async function deleteNote(noteUuid) {
    try {
      await deleteClientNoteApi(noteUuid);
      updateNotesTrigger(clientUuid);
    } catch (error) {}
  }

  async function updateNote(noteUuid, e) {
    try {
      await updateClientNoteApi(noteUuid, e.target.value);
      updateNotesTrigger(clientUuid);
    } catch (error) {}
  }

  async function createNote() {
    try {
      await createClientNoteApi(clientUuid);
      updateNotesTrigger(clientUuid);
    } catch (error) {}
  }

  function showConfirmation(e, noteUuid) {
    e.stopPropagation();
    setVisibleConfirmation(value => (value === noteUuid ? null : noteUuid));
  }

  function getSortedNotes(notes) {
    return notes.sort((a, b) => {
      return stringToDate(b.date_creation) - stringToDate(a.date_creation);
    });
  }

  return (
    <div className='crm-notes-container'>
      {notes?.length ? (
        getSortedNotes(notes).map(note => (
          <div key={note.id} className='crm-note'>
            <p className='crm-note-date'>
              {note.date_creation.substr(0, 16)}
              {note.date_last_update && <span>dernière mise à jour : {note.date_last_update.substr(0, 16)}</span>}
            </p>
            <div className='crm-note-author'>Auteur&nbsp;:&nbsp;{note.author}</div>
            <div className={`crm-note-delete-confirm ${visibleConfirmation === note.id ? "active" : ""}`}>
              <FilledButton
                bgColor='var(--red)'
                hoverColor='var(--red)'
                padding='2px 5px'
                fontSize='0.8rem'
                onClick={() => deleteNote(note.id)}
                className='crm-note-delete-button crm-button'>
                Supprimer la note
              </FilledButton>
            </div>
            <Trash onClick={e => showConfirmation(e, note.id)} />
            <Textarea defaultValue={note.note} onBlur={e => updateNote(note.id, e)} />
          </div>
        ))
      ) : (
        <p className='m-lg'>Aucune note pour ce client</p>
      )}
      <FilledButton className='crm-note-client-button crm-button' onClick={createNote}>
        <Add width='20px' />
        Ajouter une note
      </FilledButton>
    </div>
  );
}

export default CrmNotesContainer;
