export const headerSEOValues = {
  "/": {
    title: "Evaluer en ligne une affaire en quelques clics",
    description:
      "Fonds de commerce, titres de société, murs : évaluez vous-même en ligne une affaire (analyse professionnelle globale et confidentielle)",
    author: "EstimerMonCommerce.fr",
    follow: "follow",
    robots: "index, follow",
    image: "https://images.pickapic.live/get/7fe873b7-3377-42ae-b76a-4b40d123950e-1638786656.jpeg",
    url: "/",
    breadcrumb: [],
  },
  "/tarifs": {
    title: "Nos tarifs. Découvrez l'offre qui vous convient",
    description:
      "Vous êtes prêts à acheter ou à vendre un commerce ou une entreprise ? Choisissez votre formule et préparez votre projet avec nos estimations en ligne",
    author: "EstimerMonCommerce.fr",
    follow: "follow",
    robots: "noindex, nofollow",
    image: "https://images.pickapic.live/get/7fe873b7-3377-42ae-b76a-4b40d123950e-1638786656.jpeg",
    url: "/offres",
    breadcrumb: [{ name: "Nos tarifs. Découvrez l'offre qui vous convient", item: "/offres" }],
  },
  // Outils
  "/philosophie": {
    title: "Notre philosophie, qui sommes-nous ?",
    description:
      "Notre solution est le fruit d’une rencontre entre un spécialiste de la cession de commerce et entreprise et un spécialiste du digital",
    author: "EstimerMonCommerce.fr",
    follow: "follow",
    robots: "index, follow",
    image: "https://images.pickapic.live/get/7fe873b7-3377-42ae-b76a-4b40d123950e-1638786656.jpeg",
    url: "/philosophie",
    breadcrumb: [{ name: "Notre philosophie, qui sommes-nous ?", item: "/philosophie" }],
  },
  "/faq": {
    title: "Foire aux questions fréquentes",
    description:
      "Vous souhaitez en savoir plus ? Voici nos réponses aux questions fréquentes sur le domaine de la cession, transmission... de commerce et d'entreprise",
    author: "EstimerMonCommerce.fr",
    follow: "follow",
    robots: "index, follow",
    image: "https://images.pickapic.live/get/7fe873b7-3377-42ae-b76a-4b40d123950e-1638786656.jpeg",
    url: "/faq",
    breadcrumb: [{ name: "Foire aux questions fréquentes", item: "/faq" }],
  },
  "/lexique": {
    title: "Lexique commercial à l'usage des (non)initiés",
    description:
      "Notre petit lexique vous propose de découvrir/re-découvrir le champs lexical du commerce. Pratique pour mieux comprendre nos conseils et tutos",
    author: "EstimerMonCommerce.fr",
    follow: "follow",
    robots: "index, follow",
    image: "https://images.pickapic.live/get/7fe873b7-3377-42ae-b76a-4b40d123950e-1638786656.jpeg",
    url: "/lexique",
    breadcrumb: [{ name: "", item: "/lexique" }],
  },
  "/calculateur-cotisations": {
    title: "Calculateur de cotisations en ligne",
    description:
      "Déterminez le salaire brut à partir du net, mensuel ou annuel, et estimez le montant des cotisations patronales",
    author: "EstimerMonCommerce.fr",
    follow: "follow",
    robots: "index, follow",
    image: "https://images.pickapic.live/get/7fe873b7-3377-42ae-b76a-4b40d123950e-1638786656.jpeg",
    url: "/calculateur-cotisations",
    breadcrumb: [{ name: "Calculateur de cotisations en ligne", item: "/calculateur-cotisations" }],
  },
  "/simulateur-credit": {
    title: "Simulateur de crédit en ligne",
    description: "Editez et téléchargez un tableau d'amortissement personnalisé pour votre plan de financement",
    author: "EstimerMonCommerce.fr",
    follow: "follow",
    robots: "index, follow",
    image: "https://images.pickapic.live/get/7fe873b7-3377-42ae-b76a-4b40d123950e-1638786656.jpeg",
    url: "/simulateur-credit",
    breadcrumb: [{ name: "Simulateur de crédit en ligne", item: "/simulateur-credit" }],
  },
  "/plan-financement": {
    title: "Plan de financement en ligne",
    description: "TODO",
    author: "EstimerMonCommerce.fr",
    follow: "follow",
    robots: "index, follow",
    image: "https://images.pickapic.live/get/7fe873b7-3377-42ae-b76a-4b40d123950e-1638786656.jpeg",
    url: "/plan-financement",
    breadcrumb: [{ name: "Plan de financement en ligne", item: "/plan-financement" }],
  },
  "/outils-pratiques": {
    title: "Outils pratiques. Cession, acquisition, optimisation d'affaire",
    description: "Téléchargez nos outils exclusifs pour vous aider à optimiser, céder ou acquérir une affaire",
    author: "EstimerMonCommerce.fr",
    follow: "follow",
    robots: "index, follow",
    image: "https://images.pickapic.live/get/7fe873b7-3377-42ae-b76a-4b40d123950e-1638786656.jpeg",
    url: "/outils-pratiques",
    breadcrumb: [{ name: "Outils pratiques. Cession, acquisition, optimisation d'affaire", item: "/outils-pratiques" }],
  },
  // Articles
  "/conseils-pros": {
    title: "Conseils de pros sur la cession, acquisition et optimisation",
    description:
      "Cession, acquisition, optimisation d'affaire : astuces, pièges à éviter, points essentiels, parcours et étapes à anticiper",
    author: "EstimerMonCommerce.fr",
    follow: "follow",
    robots: "index, follow",
    image: "https://images.pickapic.live/get/7fe873b7-3377-42ae-b76a-4b40d123950e-1638786656.jpeg",
    url: "/conseils-pros",
    breadcrumb: [{ name: "Conseils de pros sur la cession, acquisition et optimisation", item: "/conseils-pros" }],
  },
  "/conseils-pros/:article": {
    useSpecialValue: true,
    title: "",
    description: "",
    author: "EstimerMonCommerce.fr",
    follow: "follow",
    robots: "",
    image: "https://images.pickapic.live/get/7fe873b7-3377-42ae-b76a-4b40d123950e-1638786656.jpeg",
    url: "",
    breadcrumb: [{ name: "", item: "" }],
  },
  "/guides-agents-immos": {
    title: "Nos guides pour agents immos",
    description: "Chaque mois, des méthodes & infos pratiques de terrain",
    author: "EstimerMonCommerce.fr",
    follow: "follow",
    robots: "index, follow",
    image: "https://images.pickapic.live/get/7fe873b7-3377-42ae-b76a-4b40d123950e-1638786656.jpeg",
    url: "/guides-agents-immos",
    breadcrumb: [{ name: "Nos guides pour agents immos", item: "/guides-agents-immos" }],
  },
  "/guides-agents-immos/:article": {
    useSpecialValue: true,
    title: "Nos guides pour agents immos",
    description: "Chaque mois, des méthodes & infos pratiques de terrain",
    author: "EstimerMonCommerce.fr",
    follow: "follow",
    robots: "index, follow",
    image: "https://images.pickapic.live/get/7fe873b7-3377-42ae-b76a-4b40d123950e-1638786656.jpeg",
    url: "/guides-agents-immos",
    breadcrumb: [{ name: "Nos guides pour agents immos", item: "/guides-agents-immos" }],
  },
  // Legal
  "/cgv": {
    title: "Conditions générales de ventes",
    description:
      "Découvrez les conditions générales de ventes d'EstimerMonCommerce.fr avant d'utiliser notre parcours d'estimation",
    author: "EstimerMonCommerce.fr",
    follow: "nofollow",
    robots: "noindex, nofollow",
    image: "https://images.pickapic.live/get/7fe873b7-3377-42ae-b76a-4b40d123950e-1638786656.jpeg",
    url: "/cgv",
    breadcrumb: [{ name: "Conditions générales de ventes", item: "/cgv" }],
  },
  "/cgu": {
    title: "Conditions générales d'utilisation",
    description: "Découvrez les conditions générales d'utilisation d'EstimerMonCommerce.fr",
    author: "EstimerMonCommerce.fr",
    follow: "nofollow",
    robots: "noindex, nofollow",
    image: "https://images.pickapic.live/get/7fe873b7-3377-42ae-b76a-4b40d123950e-1638786656.jpeg",
    url: "/cgu",
    breadcrumb: [{ name: "Conditions générales d'utilisation", item: "/cgu" }],
  },
  "/parametrage-cookies": {
    title: "Nos cookies pour une utilisation optimale",
    description:
      "Ce site utilise des cookies pour vous offrir une navigation de qualité et améliorer votre expérience de navigation sur le site",
    author: "EstimerMonCommerce.fr",
    follow: "nofollow",
    robots: "noindex, nofollow",
    image: "https://images.pickapic.live/get/7fe873b7-3377-42ae-b76a-4b40d123950e-1638786656.jpeg",
    url: "/parametrage-cookies",
    breadcrumb: [{ name: "Nos cookies pour une utilisation optimale", item: "/parametrage-cookies" }],
  },
  "/mentions-legales": {
    title: "Mentions légales applicables au 15 octobre 2021",
    description:
      "Découvrez les mentions legales d'EstimerMonCommerce.fr pour découvrir l'éditeur, le concepteur et l'hébergeur du site",
    author: "EstimerMonCommerce.fr",
    follow: "nofollow",
    robots: "noindex, nofollow",
    image: "https://images.pickapic.live/get/7fe873b7-3377-42ae-b76a-4b40d123950e-1638786656.jpeg",
    url: "/mentions-legales",
    breadcrumb: [{ name: "Mentions légales applicables au 15 octobre 2021", item: "/mentions-legales" }],
  },
  "/politique-confidentialite": {
    title: "Politique de confidentialité de vos données",
    description:
      "Découvrez la politique de confidentialité d'EstimerMonCommerce.fr pour en savoir plus sur l'utilisation du site",
    author: "EstimerMonCommerce.fr",
    follow: "nofollow",
    robots: "noindex, nofollow",
    image: "https://images.pickapic.live/get/7fe873b7-3377-42ae-b76a-4b40d123950e-1638786656.jpeg",
    url: "/politique-confidentialite",
    breadcrumb: [{ name: "Politique de confidentialité de vos données", item: "/politique-confidentialite" }],
  },
  // Parcours
  "/debut-parcours": {
    title: "Commencer mon parcours d'estimation en ligne",
    description:
      "Fonds de commerce, titres de société, murs commerciaux. Estimez votre affaire 100% en ligne, avec EstimerMonCommerce.fr",
    author: "EstimerMonCommerce.fr",
    follow: "follow",
    robots: "index, follow",
    image: "https://images.pickapic.live/get/7fe873b7-3377-42ae-b76a-4b40d123950e-1638786656.jpeg",
    url: "/debut-parcours",
    breadcrumb: [{ name: "Commencer mon parcours d'estimation en ligne", item: "/debut-parcours" }],
  },
  "/detail-parcours/murs-commerciaux": {
    title: "Parcours d'estimation en ligne, murs commerciaux",
    description: "Réalisez votre parcours d'estimation en ligne de vos murs commerciaux afin d'estimer leur valeur",
    author: "EstimerMonCommerce.fr",
    follow: "follow",
    robots: "noindex, nofollow",
    image: "https://images.pickapic.live/get/7fe873b7-3377-42ae-b76a-4b40d123950e-1638786656.jpeg",
    url: "/detail-parcours/murs-commerciaux",
    breadcrumb: [{ name: "", item: "/detail-parcours/murs-commerciaux" }],
  },
  "/detail-parcours/titres-de-societe": {
    title: "Parcours d'estimation en ligne",
    description:
      "Réalisez votre parcours d'estimation en ligne de fonds de commerce et de titres de société afin d'estimer la valeur de votre affaire",
    author: "EstimerMonCommerce.fr",
    follow: "follow",
    robots: "noindex, nofollow",
    image: "https://images.pickapic.live/get/7fe873b7-3377-42ae-b76a-4b40d123950e-1638786656.jpeg",
    url: "/detail-parcours/titres-de-societe",
    breadcrumb: [{ name: "Parcours d'estimation en ligne", item: "/detail-parcours/titres-de-societe" }],
  },
  "/detail-parcours/fonds-de-commerce": {
    title: "Parcours d'estimation en ligne",
    description:
      "Réalisez votre parcours d'estimation en ligne de fonds de commerce et de titres de société afin d'estimer la valeur de votre affaire",
    author: "EstimerMonCommerce.fr",
    follow: "follow",
    robots: "noindex, nofollow",
    image: "https://images.pickapic.live/get/7fe873b7-3377-42ae-b76a-4b40d123950e-1638786656.jpeg",
    url: "/detail-parcours/fonds-de-commerce",
    breadcrumb: [{ name: "", item: "/detail-parcours/fonds-de-commerce" }],
  },
  "/synthese": {
    title: "Rapport synthétique de l'estimation de votre affaire",
    description:
      "Découvrez une première estimation de la valeur de votre affaire. Prévisualisez l'état général de celle-ci avant d'obtenir votre rapport complet",
    author: "EstimerMonCommerce.fr",
    follow: "follow",
    robots: "noindex, nofollow",
    image: "https://images.pickapic.live/get/7fe873b7-3377-42ae-b76a-4b40d123950e-1638786656.jpeg",
    url: "/synthese",
    breadcrumb: [{ name: "Rapport synthétique de l'estimation de votre affaire", item: "/synthese" }],
  },
  // Compte
  "/mon-compte": {
    title: "Mon tableau de bord",
    description:
      "Accédez à vos rapports d’estimation, téléchargez vos factures, actualisez vos informations personnelles et suivez l’offre à laquelle vous avez souscrite",
    author: "EstimerMonCommerce.fr",
    follow: "follow",
    robots: "noindex, nofollow",
    image: "https://images.pickapic.live/get/7fe873b7-3377-42ae-b76a-4b40d123950e-1638786656.jpeg",
    url: "/mon-compte",
    breadcrumb: [{ name: "Mon tableau de bord", item: "/mon-compte" }],
  },
  "/connexion": {
    title: "Connexion à votre compte personnel",
    description:
      "Bienvenue sur EstimerMonCommerce.fr, connectez-vous à votre compte depuis cette page afin d'estimer en ligne la valeur de votre affaire",
    author: "EstimerMonCommerce.fr",
    follow: "nofollow",
    robots: "noindex, nofollow",
    image: "https://images.pickapic.live/get/7fe873b7-3377-42ae-b76a-4b40d123950e-1638786656.jpeg",
    url: "/connexion",
    breadcrumb: [{ name: "Connexion à votre compte personnel", item: "/connexion" }],
  },
  "/inscription": {
    title: "Inscrivez-vous pour profiter de toutes nos offres",
    description:
      "Inscrivez-vous depuis cette page pour profiter de tous nos conseils et outils, de nos rapports complets et bien plus encore",
    author: "EstimerMonCommerce.fr",
    follow: "nofollow",
    robots: "noindex, nofollow",
    image: "https://images.pickapic.live/get/7fe873b7-3377-42ae-b76a-4b40d123950e-1638786656.jpeg",
    url: "/inscription",
    breadcrumb: [{ name: "Inscrivez-vous pour profiter de toutes nos offres", item: "/inscription" }],
  },
};
