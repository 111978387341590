import axiosInstance from "./AxiosConfig";

export const fetchAllClientsApi = async params =>
  axiosInstance.get(`/crm/users/all`, {
    params: params,
  });

export const fetchClientByUuidApi = async uuid => axiosInstance.get(`/crm/users/${uuid}`);

export const fetchClientSubscriptionApi = async uuid => axiosInstance.get(`/crm/users/subscriptions/${uuid}`);

export const createClientAccountApi = async values => axiosInstance.post("/crm/users/create", values);

export const updateClientAccountApi = async (values, clientUuid) =>
  axiosInstance.put(`/crm/users/update/${clientUuid}`, values);

// Notes

export const createClientNoteApi = async (uuid, value = "Nouvelle note") =>
  axiosInstance.post(`/crm/users/note/create`, { user_id: uuid, note: value });

export const updateClientNoteApi = async (noteUuid, note) =>
  axiosInstance.put(`/crm/users/note/edit/${noteUuid}`, { note });

export const deleteClientNoteApi = async noteUuid => axiosInstance.delete(`/crm/users/note/delete/${noteUuid}`);

export const fetchClientNotesApi = async uuid =>
  axiosInstance.get(`/crm/users/note`, {
    params: {
      method: "user",
      id: uuid,
    },
  });

// Bloquage clients

export const blockClientApi = async (clientUuid, params) => axiosInstance.put(`/user/block/${clientUuid}`, params);

export const unblockClientApi = async clientUuid => axiosInstance.put(`/user/unblock/${clientUuid}`);
