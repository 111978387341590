import { normalizeString } from "../../../../../utils/Utils";

export const VisitingCardTabColumns = [
  {
    id: 1,
    label: "UUID",
    key: "uuid",
    default: 0,
  },
  {
    id: 2,
    label: "Intitulé",
    key: "name",
    default: 0,
    showBracket: true,
  },
  {
    id: 3,
    label: "Défaut",
    key: "is_default",
    default: 768,
  },
  {
    id: 4,
    label: "Type",
    key: "card_type_id",
    default: 992,
  },
  {
    id: 5,
    label: "Création",
    key: "date_creation",
    default: 992,
    showBracket: true,
  },
  {
    id: 10,
    default: 992,
    label: "Mise à jour",
    key: "date_last_update",
    showBracket: true,
  },
];

export function getVisitingCardColumnContent(key, item) {
  switch (key) {
    case "date_last_update":
    case "date_creation":
      return item[key] || "-";
    case "card_type_id":
      return item.card_type_id === 1 ? "Créée" : "Importée";
    default:
      return item[key];
  }
}

export function filterVisitingCards(items, searchValue) {
  let normalizedSearchValue = normalizeString(searchValue);

  return items?.filter(item => {
    if (item.uuid == searchValue || normalizeString(item.name).includes(normalizedSearchValue)) return true;

    return false;
  });
}
