import "./ReportExamples.css";
import React, { useContext, useEffect } from "react";
import RapportsRow from "../../../components/organisms/rapportsRow/RapportsRow";
import AppContext from "../../../context/AppContext";

function ReportExamples() {
  const { setBannerConfig } = useContext(AppContext);

  useEffect(() => {
    setBannerConfig({
      title: <>Exemples de rapports</>,
      subtitle: <>Téléchargez nos exemples de rapports pour consulter leur contenu détaillé</>,
    });
  }, []);

  return (
    <div className='page-container'>
      <div>
        <RapportsRow />
      </div>
    </div>
  );
}

export default ReportExamples;
