import { useParams } from "react-router-dom";
import Add from "../../../../components/atoms/icons/general/add/Add";
import Bracket from "../../../../components/atoms/icons/general/bracket/Bracket";
import Download from "../../../../components/atoms/icons/general/download/Download";
import Banner from "../../../../components/molecules/banner/Banner";
import Breadcrumbs from "../../../../components/molecules/breadcrumbs/Breadcrumbs";
import FilledButton from "../../../../components/molecules/buttons/filledButton/FilledButton";
import Searchbar from "../../../../components/molecules/searchbar/Searchbar";
import "./CrmViewer.css";
import React, { useContext, useEffect, useState } from "react";
import {
  getClientTabColumnClassName,
  ClientTabSearchPlaceholder,
  ClientTabColumns,
  getClientSortingFilters,
} from "./subTabs/CrmClientTabComponents";
import Checkbox from "../../../../components/molecules/formComponents/checkbox/Checkbox";
import { Link } from "react-router-dom";
import { copyToClipboard, formatNumberWithSpaces } from "../../../../utils/Utils";
import AppContext from "../../../../context/AppContext";
import Copy from "../../../../components/atoms/icons/account/copy/Copy";
import ArrowSm from "../../../../components/atoms/icons/general/arrowSm/ArrowSm";
import CrmNotesContainer from "../../../../components/crmComponents/client/crmNotesContainer/CrmNotesContainer";
import { useWindowSize } from "@uidotdev/usehooks";
import { useCrm } from "../../../../context/CrmContext";
import { createClientNoteApi, fetchAllClientsApi, fetchClientNotesApi } from "../../../../api/CrmClientsApi";
import Loader from "../../../../components/organisms/loader/Loader";
import CrmSortingTab from "../../../../components/crmComponents/crmSortingTab/CrmSortingTab";
import CreateUserModal from "../../../../components/forms/modals/crm/createUserModal/CreateUserModal";
import { useForm } from "react-hook-form";

function CrmViewer() {
  const { register, unregister, setValue, getValues } = useForm();
  const inputForm = { register, unregister, setValue };

  const { createNotification, setModalVisible, setModalContent } = useContext(AppContext);
  const { formatTabColumns, copyIdToClipboard, getClientPaymentStatus, partnersList, subscriptionsList, rolesList } =
    useCrm();

  const [pageTitle, setPageTitle] = useState("");
  const [isLoaderVisible, setIsLoaderVisible] = useState(true);

  const [searchValue, setSearchValue] = useState("");
  const [searchPlaceholder, setSearchPlaceholder] = useState("");
  const [resetSerchValueTrigger, setResetSerchValueTrigger] = useState(false);
  const [queryParams, setQueryParams] = useState({
    limit: 50,
    hits: 0,
    matchs: 0,
    totalHits: 0,
  });

  const [items, setItems] = useState([]);
  const [tabColumns, setTabColumns] = useState([]);
  const [sortingFilters, setSortingFilters] = useState([]);

  const { tabParam } = useParams();
  const { width } = useWindowSize();

  const tabComponents = {
    searchPlaceholder: { clients: ClientTabSearchPlaceholder },
    columns: { clients: ClientTabColumns },
    getColumnClassName: { clients: getClientTabColumnClassName },
  };

  useEffect(() => {
    setSearchPlaceholder(tabComponents.searchPlaceholder[tabParam]);
    setResetSerchValueTrigger(resetSerchValueTrigger => !resetSerchValueTrigger);
    setTabColumns(formatTabColumns(tabComponents.columns[tabParam], width));

    if (tabParam === "faq") setPageTitle("F.A.Q.");
    else setPageTitle(tabParam.replaceAll("-", " ").replace(/^\w/, c => c.toUpperCase()));
  }, [tabParam, width]);

  useEffect(() => {
    fillSortingFilters(tabParam, subscriptionsList, rolesList);
  }, [tabParam, partnersList, subscriptionsList, rolesList]);

  useEffect(() => {
    const timeout = setTimeout(
      () => fetchItems(tabParam, { limit: queryParams.limit, search: searchValue, ...queryParams.appliedFilters }),
      500,
    );

    return () => clearTimeout(timeout);
  }, [searchValue]);

  // Clients functions

  async function fetchClients(params) {
    let result = [];

    try {
      const res = (await fetchAllClientsApi(params)).data;
      result = res.users.map(client => ({
        ...client,
        name: client.last_name + " " + client.first_name,
        address: client.street + " " + client.city,
        partner_name: client.partner_name || "-",
        subscription_name: client.subscriptions?.current?.plan.toUpperCase() || "-",
        date_start_subscription: client.subscriptions?.current.date_start_sub?.slice(0, 16) || "-",
        date_end_subscription: client.subscriptions?.current.date_end_sub?.slice(0, 16) || "-",
        date_last_connexion: client.date_last_connexion?.slice(0, 16) || "-",
        date_creation: client.date_creation?.slice(0, 16) || "-",
        payment_status: getClientPaymentStatus(client.subscriptions),
        status: client.is_active ? "Actif" : "Non confirmé",
      }));

      setQueryParams({
        limit: res.limit,
        hits: res.hits,
        matchs: res.matchs,
        totalHits: res.total_hits,
        appliedFilters: res.applied_filters,
        orderBy: res.order_by,
        orderDir: res.order_dir,
      });
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la récupération des utilisateurs. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    } finally {
      return result;
    }
  }

  async function showNotesModal(e, notes, clientUuid, createNote) {
    e.stopPropagation();
    e.preventDefault();

    if (createNote) notes = [(await createClientNoteApi(clientUuid)).data];

    setModalContent({
      title: "Notes",
      content: (
        <>
          <CrmNotesContainer notes={notes} clientUuid={clientUuid} updateNotesTrigger={updateClientNotes} />
          <div className='modal-buttons-row'>
            <FilledButton onClick={() => setModalVisible()}>OK</FilledButton>
          </div>
        </>
      ),
      className: "big-modal",
    });

    setModalVisible(true);
  }

  async function updateClientNotes(clientUuid) {
    try {
      const res = (await fetchClientNotesApi(clientUuid)).data;
      setItems(items => [...items.map(item => (item.uuid === clientUuid ? { ...item, notes: [...res] } : item))]);

      setModalContent({
        title: "Notes",
        content: (
          <>
            <CrmNotesContainer notes={res} clientUuid={clientUuid} updateNotesTrigger={updateClientNotes} />
            <div className='modal-buttons-row'>
              <FilledButton onClick={() => setModalVisible()}>OK</FilledButton>
            </div>
          </>
        ),
        className: "big-modal",
      });
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la mise à jour des notes. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  function showCreateClientModal() {
    setModalContent({
      title: "Créer un utilisateur",
      content: (
        <CreateUserModal
          rolesList={rolesList}
          partnersList={partnersList}
          triggerUpdate={() =>
            fetchItems(tabParam, { limit: queryParams.limit, search: searchValue, ...queryParams.appliedFilters })
          }
        />
      ),
      className: "big-modal",
    });

    setModalVisible(true);
  }

  // Fetching functions

  async function fetchItems(tabParam, params) {
    switch (tabParam) {
      case "clients":
        setItems(await fetchClients(params));
        break;
    }

    setTimeout(() => {
      setIsLoaderVisible(false);
    }, 200);
  }

  // Common functions

  async function fillSortingFilters(tabParam) {
    let result;
    switch (tabParam) {
      case "clients":
        result = await getClientSortingFilters(partnersList, subscriptionsList, rolesList);
        break;
    }

    setSortingFilters(result);
  }

  function displayMoreItems(newLimit) {
    fetchItems(tabParam, { limit: newLimit, search: searchValue, ...queryParams.appliedFilters });
  }

  function resetFilters() {
    setTabColumns(formatTabColumns(tabComponents.columns[tabParam], width));
    setResetSerchValueTrigger(resetSerchValueTrigger => !resetSerchValueTrigger);

    fetchItems(tabParam, { limit: 50 });
  }

  function toggleAllCheckboxes() {
    const shouldCheck = !getValues("checkbox-all");

    Object.keys(getValues("checkbox-column")).forEach(key => setValue("checkbox-column." + key, shouldCheck));
  }

  function toggleColumnBracket(columnKey) {
    let result;
    if (queryParams.orderBy === columnKey)
      switch (queryParams.orderDir) {
        case "asc":
          result = { key: columnKey, order: "desc" };
          break;
        case "desc":
          result = null;
          break;
      }
    else result = { key: columnKey, order: "asc" };

    fetchItems(tabParam, {
      limit: queryParams.limit,
      search: searchValue,
      ...queryParams.appliedFilters,
      ...(result ? { order_by: columnKey, order_dir: result.order } : {}),
    });
  }

  function applySortingFilters(values) {
    setTabColumns(values.tabColumns);

    let filters = {};
    values.filters.forEach(filter => {
      if (filter.options.length) filters[filter.key] = filter.options.map(option => option.id).join(",");
    });

    fetchItems(tabParam, { limit: values.resultsLimit, search: searchValue, ...filters });
  }

  return (
    <>
      <section className='page-container container'>
        <Breadcrumbs
          routes={[
            { to: "/", name: "Accueil" },
            { to: "/admin/crm", name: "CRM" },
            { to: "/admin/crm/" + tabParam, name: pageTitle },
          ]}
        />
        <Banner
          className='crm-banner'
          title={"CRM - " + pageTitle}
          subtitle={
            <Link to='/admin/crm' className='link-with-arrow'>
              <ArrowSm />
              Retour au menu
            </Link>
          }
        />
      </section>
      <CrmSortingTab
        filters={sortingFilters}
        applySortingFilters={applySortingFilters}
        tabColumns={tabColumns}
        resultsLimit={queryParams.limit}
        triggerFiltersReset={resetFilters}
      />
      <div className='container'>
        {tabParam === "clients" ? (
          <div className='crm-items-container'>
            <Loader visible={isLoaderVisible} className='crm-loader' preventScrolling={false} />
            <div className='crm-action-row'>
              <Searchbar
                className='crm-searchbar'
                placeholder={searchPlaceholder}
                onChange={value => setSearchValue(value)}
                resetValueTrigger={resetSerchValueTrigger}
              />
              <FilledButton disabled>
                <Download width='20px' />
                Exporter la sélection
              </FilledButton>
              <FilledButton onClick={showCreateClientModal}>
                <Add width='20px' /> Créer un utilisateur
              </FilledButton>
            </div>
            <div className='crm-table-container'>
              <div className='crm-thead'>
                <Checkbox
                  useForm={inputForm}
                  name='checkbox-all'
                  onChange={toggleAllCheckboxes}
                  className='crm-checkbox'
                />
                {tabColumns?.map(
                  column =>
                    column.visible && (
                      <div
                        className={`crm-column-${column.key} ${column.showBracket ? "cursor-pointer" : ""}`}
                        {...(column.showBracket && { onClick: () => toggleColumnBracket(column.key) })}
                        key={column.id}>
                        {column.label}
                        {column.showBracket && (
                          <Bracket
                            rotation={
                              queryParams?.orderBy == column.key
                                ? queryParams?.orderDir === "asc"
                                  ? "180deg"
                                  : "0deg"
                                : "90deg"
                            }
                            width='12px'
                            className='ml-xs'
                            color='var(--blue)'
                          />
                        )}
                      </div>
                    ),
                )}
                {tabParam !== "clients" && <div className='crm-column-actions'>Actions</div>}
              </div>
              <div className='crm-tbody'>
                {items.map(item => (
                  <Link className='crm-table-row' key={item.uuid} to={`/admin/crm/${tabParam}/${item.uuid}`}>
                    <Checkbox
                      useForm={inputForm}
                      className='crm-checkbox crm-clickable-column'
                      name={"checkbox-column." + item.uuid}
                      onClick={e => e.stopPropagation()}
                    />
                    {tabColumns?.map(column => {
                      if (!column.visible) return null;
                      switch (column.key) {
                        case "uuid":
                          return (
                            <div
                              key={column.id}
                              className='crm-row-column crm-column-uuid crm-clickable-column'
                              onClick={e => copyIdToClipboard(e, item.uuid)}>
                              <Copy width='18px' color='var(--white)' onClick={e => copyIdToClipboard(e, item.uuid)} />
                            </div>
                          );
                        case "notes":
                          return (
                            <div className='crm-row-column crm-column-notes' key={column.id}>
                              {item?.notes?.length ? (
                                <FilledButton
                                  color='var(--dark-blue)'
                                  bgColor='var(--sky-blue)'
                                  padding='1px 5px'
                                  fontSize='0.8rem'
                                  borderRadius='4px'
                                  onClick={e => showNotesModal(e, item.notes, item.uuid)}
                                  className='crm-row-button'>
                                  Afficher
                                </FilledButton>
                              ) : (
                                <FilledButton
                                  color='var(--dark-blue)'
                                  bgColor='var(--light-blue)'
                                  padding='1px 5px'
                                  fontSize='0.8rem'
                                  borderRadius='4px'
                                  onClick={e => showNotesModal(e, item.notes, item.uuid, true)}
                                  className='crm-row-button'>
                                  Ajouter
                                </FilledButton>
                              )}
                            </div>
                          );
                        default:
                          return (
                            <div
                              title={item[column.key]}
                              className={`crm-row-column crm-column-${column.key} crm-${tabComponents.getColumnClassName[tabParam](column.key, item[column.key])}`}
                              key={column.id}>
                              {item[column.key]}
                            </div>
                          );
                      }
                    })}
                    {tabParam !== "clients" && (
                      <div className='crm-column-actions crm-clickable-column'>
                        <Bracket width='14px' rotation='180deg' />
                      </div>
                    )}
                  </Link>
                ))}
              </div>
              <div className='crm-paginator'>
                {queryParams.hits} résultats affichés sur {formatNumberWithSpaces(queryParams.totalHits, 1)}.{" "}
                {queryParams.hits < queryParams.totalHits && (
                  <span onClick={() => displayMoreItems(Number(queryParams.limit) + 50)}>Afficher plus</span>
                )}
              </div>
            </div>
          </div>
        ) : (
          <h2 className='centered text-xl'>Pas encore développé</h2>
        )}
      </div>
    </>
  );
}

export default CrmViewer;
