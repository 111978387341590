import { Link } from "react-router-dom";
import { normalizeString } from "../../../../../utils/Utils";
import Archive from "../../../../../components/atoms/icons/account/archive/Archive";
import Unarchive from "../../../../../components/atoms/icons/account/unarchive/Unarchive";
import Trash from "../../../../../components/atoms/icons/general/trash/Trash";
import Duplicate from "../../../../../components/atoms/icons/account/duplicate/Duplicate";
import Download from "../../../../../components/atoms/icons/general/download/Download";
import Pen from "../../../../../components/atoms/icons/account/pen/Pen";

export const FinancingPlanTabColumns = [
  {
    id: 1,
    label: "UUID",
    key: "uuid",
    default: 0,
  },
  {
    id: 2,
    label: "Intitulé",
    key: "name",
    default: 0,
    showBracket: true,
  },
  {
    id: 3,
    label: "Type",
    key: "type",
    default: 576,
  },
  {
    id: 9,
    label: "Estimation liée",
    key: "estimation_name",
    default: 1200,
    showBracket: true,
  },
  {
    id: 4,
    label: "Création",
    key: "date_creation",
    showBracket: true,
    default: 992,
  },
  {
    id: 5,
    label: "Mise à jour",
    key: "date_last_update",
    showBracket: true,
  },
  {
    id: 7,
    label: "",
    key: "archived",
    default: 576,
  },
];

export function getFinancingPlanColumnContent(key, item) {
  switch (key) {
    case "date_last_update":
    case "date_creation":
      return item[key] || "-";
    default:
      return item[key];
  }
}

export function filterFinancingPlans(items, searchValue) {
  let normalizedSearchValue = normalizeString(searchValue);

  return items?.filter(item => {
    if (
      item.uuid === searchValue ||
      normalizeString(item.name).includes(normalizedSearchValue) ||
      normalizeString(item.estimation_name).includes(normalizedSearchValue) ||
      normalizeString(item.type?.description).includes(normalizedSearchValue)
    )
      return true;

    return false;
  });
}

export const FinancingPlanDropdownMenu = ({
  activeItem,
  downloadItemPdf,
  showDuplicateItemModal,
  archiveItem,
  removeFromFolder,
  removeFromDeliverable,
}) => (
  <>
    <li>
      <Link
        to={`/plan-financement/${activeItem?.type?.id === 1 ? "fdc" : "murs"}/${activeItem?.uuid}`}
        onClick={e => {
          e.preventDefault();
          window.open(`/plan-financement/${activeItem?.type?.id === 1 ? "fdc" : "murs"}/${activeItem?.uuid}`);
        }}>
        <Pen width='18px' />
        Modifier
      </Link>
    </li>
    {downloadItemPdf && (
      <li>
        <button onClick={() => downloadItemPdf(activeItem?.uuid)}>
          <Download color='var(--dark-blue)' width='18px' />
          Télécharger
        </button>
      </li>
    )}
    {showDuplicateItemModal && (
      <li>
        <button onClick={() => showDuplicateItemModal(activeItem?.uuid, activeItem?.name)}>
          <Duplicate width='18px' />
          Dupliquer
        </button>
      </li>
    )}
    {removeFromFolder && (
      <li>
        <button onClick={() => removeFromFolder(activeItem?.uuid)}>
          <Trash width='18px' />
          Retirer du dossier
        </button>
      </li>
    )}
    {removeFromDeliverable && (
      <li>
        <button onClick={() => removeFromDeliverable(activeItem?.uuid)}>
          <Trash width='18px' />
          Retirer du livrable
        </button>
      </li>
    )}
    {archiveItem &&
      (activeItem?.archived ? (
        <li>
          <button onClick={() => archiveItem(activeItem?.uuid, false)}>
            <Unarchive width='18px' />
            Désarchiver
          </button>
        </li>
      ) : (
        <li>
          <button onClick={() => archiveItem(activeItem?.uuid, true)}>
            <Archive width='18px' />
            Archiver
          </button>
        </li>
      ))}
  </>
);
