import "./OfferSubscriptionModal.css";
import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import AppContext from "../../../../../context/AppContext";
import NumberInput from "../../../../molecules/formComponents/numberInput/NumberInput";
import Checkbox from "../../../../molecules/formComponents/checkbox/Checkbox";
import FilledButton from "../../../../molecules/buttons/filledButton/FilledButton";
import Input from "../../../../molecules/formComponents/input/Input";
import UnfilledButton from "../../../../molecules/buttons/unfilledButton/UnfilledButton";
import { offerSubscriptionApi, updateFreeSubscriptionApi } from "../../../../../api/CrmSubscriptionApi";

function OfferSubscriptionModal({ clientUuid, triggerUpdate, subscription }) {
  const {
    register,
    unregister,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const inputForm = { register, unregister, watch, setValue };

  const { setModalVisible, createNotification } = useContext(AppContext);

  useEffect(() => {
    const now = new Date();

    if (subscription) {
      setValue("date_start_free_sub", changeDateFormat(subscription.date_start_sub));
      setValue("date_end_free_sub", changeDateFormat(subscription.date_end_sub));
      setValue("tools_access", subscription.tools_access);
      if (subscription.tools_access) {
        setValue("date_start_tools_access", changeDateFormat(subscription.date_start_tools_access));
        setValue("date_end_tools_access", changeDateFormat(subscription.date_end_tools_access));
      } else {
        setValue("date_start_tools_access", now.toISOString().split(":").slice(0, 2).join(":"));
        setValue("date_end_tools_access", now.toISOString().split(":").slice(0, 2).join(":"));
      }

      if (subscription.nb_estimations === -1) setValue("unlimited_estimations", true);
      else setValue("nb_estimations", subscription.nb_estimations);
    } else {
      setValue("date_start_free_sub", now.toISOString().split(":").slice(0, 2).join(":"));
      setValue("date_end_free_sub", now.toISOString().split(":").slice(0, 2).join(":"));

      setValue("date_start_tools_access", now.toISOString().split(":").slice(0, 2).join(":"));
      setValue("date_end_tools_access", now.toISOString().split(":").slice(0, 2).join(":"));
    }
  }, []);

  async function formSubmit(values) {
    setModalVisible(false);

    values.date_start_free_sub = formatDate(values.date_start_free_sub);
    values.date_end_free_sub = formatDate(values.date_end_free_sub);

    values.date_start_tools_access = formatDate(values.date_start_tools_access);
    values.date_end_tools_access = formatDate(values.date_end_tools_access);

    values.tools_access = values.tools_access ? 1 : 0;

    if (subscription) await updateFreeSubscriptionApi(subscription.sub_id, { ...values, user_id: clientUuid });
    else await offerSubscriptionApi({ ...values, user_id: clientUuid });
    triggerUpdate();

    createNotification(<>L'abonnement gratuit a été {subscription ? "modifié" : "créé"} avec succès.</>);
    try {
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la {subscription ? "modification" : "création"} de l'abonnement gratuit</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  function changeDateFormat(date) {
    return date.split(" ")[0].split("/").reverse().join("-") + "T" + date.split(" ")[1];
  }

  function formatDate(date) {
    date = new Date(date);

    const pad = num => num.toString().padStart(2, "0");

    return `${pad(date.getDate())}/${pad(date.getMonth() + 1)}/${date.getFullYear()} ${pad(date.getHours())}:${pad(date.getMinutes())}:00`;
  }

  function increaseEndDate(type, inputName) {
    const endDate = new Date(watch(inputName));
    switch (type) {
      case "day":
        endDate.setDate(endDate.getDate() + 1);
        break;
      case "week":
        endDate.setDate(endDate.getDate() + 7);
        break;
      case "month":
        endDate.setMonth(endDate.getMonth() + 1);
        break;
      case "year":
        endDate.setFullYear(endDate.getFullYear() + 1);
        break;
    }
    endDate.setHours(endDate.getHours() + 1);

    setValue(inputName, endDate.toISOString().split(".")[0]);
  }

  return (
    <form onSubmit={handleSubmit(formSubmit)}>
      <div className='offer-subscription-row-container'>
        <div>
          <Checkbox name='unlimited_estimations' label='Estimations illimitées' useForm={inputForm} />
          <NumberInput
            visible={!watch("unlimited_estimations")}
            error={errors?.nb_estimations?.message}
            name='nb_estimations'
            label="Nombre d'estimations"
            useForm={inputForm}
          />
          <Input
            error={errors?.date_start_free_sub?.message}
            type='datetime-local'
            name='date_start_free_sub'
            label='Date de début (estimations)'
            useForm={inputForm}
          />
          <Input
            error={errors?.date_end_free_sub?.message}
            type='datetime-local'
            name='date_end_free_sub'
            label='Date de fin (estimations)'
            useForm={inputForm}
          />
          <div className='offer-subscription-modal-buttons'>
            <FilledButton onClick={() => increaseEndDate("day", "date_end_free_sub")}>+1 jour</FilledButton>
            <FilledButton onClick={() => increaseEndDate("week", "date_end_free_sub")}>+1 semaine</FilledButton>
            <FilledButton onClick={() => increaseEndDate("month", "date_end_free_sub")}>+1 mois</FilledButton>
            <FilledButton onClick={() => increaseEndDate("year", "date_end_free_sub")}>+1 an</FilledButton>
          </div>
        </div>
        <div>
          {!watch("unlimited_estimations") && <div className='placeholder' />}
          <Checkbox name='tools_access' label='Accès aux outils pratiques' useForm={inputForm} />
          <Input
            visible={watch("tools_access")}
            error={errors?.date_start_free_sub?.message}
            type='datetime-local'
            name='date_start_tools_access'
            label='Date de début (outils pratiques)'
            useForm={inputForm}
          />
          <Input
            visible={watch("tools_access")}
            error={errors?.date_end_free_sub?.message}
            type='datetime-local'
            name='date_end_tools_access'
            label='Date de fin (outils pratiques)'
            useForm={inputForm}
          />
          {watch("tools_access") && (
            <div className='offer-subscription-modal-buttons'>
              <FilledButton onClick={() => increaseEndDate("day", "date_end_tools_access")}>+1 jour</FilledButton>
              <FilledButton onClick={() => increaseEndDate("week", "date_end_tools_access")}>+1 semaine</FilledButton>
              <FilledButton onClick={() => increaseEndDate("month", "date_end_tools_access")}>+1 mois</FilledButton>
              <FilledButton onClick={() => increaseEndDate("year", "date_end_tools_access")}>+1 an</FilledButton>
            </div>
          )}
        </div>
      </div>
      <div className='modal-buttons-row'>
        <UnfilledButton padding='10px 25px' onClick={() => setModalVisible(false)}>
          Annuler
        </UnfilledButton>
        <FilledButton padding='10px 25px' type='submit'>
          Valider
        </FilledButton>
      </div>
    </form>
  );
}

export default OfferSubscriptionModal;
