import { useCrm } from "../../../../context/CrmContext";
import Add from "../../../atoms/icons/general/add/Add";
import Download from "../../../atoms/icons/general/download/Download";
import FilledButton from "../../../molecules/buttons/filledButton/FilledButton";
import Checkbox from "../../../molecules/formComponents/checkbox/Checkbox";
import Searchbar from "../../../molecules/searchbar/Searchbar";
import "./CrmClientItems.css";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useWindowSize } from "@uidotdev/usehooks";
import {
  EstimationsDropdownMenu,
  EstimationTabColumns,
  filterEstimations,
  getColumnContent,
  getEstimationColumnContent,
  orderEstimationsBy,
} from "../../../../pages/admin/crm/crmViewer/subTabs/CrmEstimationTabComponents";
import Bracket from "../../../atoms/icons/general/bracket/Bracket";
import Copy from "../../../atoms/icons/account/copy/Copy";
import { Link, useSearchParams } from "react-router-dom";
import Shop from "../../../atoms/icons/header/shop/Shop";
import Factory from "../../../atoms/icons/header/factory/Factory";
import Building from "../../../atoms/icons/header/building/Building";
import Unarchive from "../../../atoms/icons/account/unarchive/Unarchive";
import Archive from "../../../atoms/icons/account/archive/Archive";
import Check from "../../../atoms/icons/general/check/Check";
import ProgressCircle from "../../../atoms/progressCircle/ProgressCircle";
import {
  filterFolders,
  folderDropdownMenu,
  FolderTabColumns,
  getFolderColumnContent,
} from "../../../../pages/admin/crm/crmViewer/subTabs/CrmFolderTabComponents";
import {
  filterFinancingPlans,
  FinancingPlanDropdownMenu,
  FinancingPlanTabColumns,
  getFinancingPlanColumnContent,
} from "../../../../pages/admin/crm/crmViewer/subTabs/CrmFinancingPlanTabComponents";
import {
  CreditSimulationDropdownMenu,
  CreditSimulationTabColumns,
  filterCreditSimulations,
  getCreditSimulationColumnContent,
} from "../../../../pages/admin/crm/crmViewer/subTabs/CrmCreditSimulationTabComponents";
import {
  filterVisitingCards,
  getVisitingCardColumnContent,
  VisitingCardTabColumns,
} from "../../../../pages/admin/crm/crmViewer/subTabs/CrmVisitingCardTabComponents";
import Toggle from "../../../molecules/formComponents/toggle/Toggle";
import { createDownloadLink, getDuplicateItemName, stringToDate } from "../../../../utils/Utils";
import AppContext from "../../../../context/AppContext";
import {
  downloadCreditSimulatorPdfApi,
  downloadEstimationPdfApi,
  downloadFinancingPlanPdfApi,
  downloadFolderApi,
} from "../../../../api/DownloadApi";
import DuplicateEstimationModal from "../../../forms/modals/account/duplicateEstimationModal/DuplicateEstimationModal";
import DuplicateFinancingPlanModal from "../../../forms/modals/account/duplicateFinancingPlanModal/DuplicateFinancingPlanModal";
import DuplicateCreditSimulatorModal from "../../../forms/modals/account/duplicateCreditSimulatorModal/DuplicateCreditSimulator";
import DuplicateFolderModal from "../../../forms/modals/account/duplicateFolderModal/DuplicateFolderModal";
import DropdownMenu from "../../../molecules/dropdownMenu/DropdownMenu";
import { archiveEstimationApi } from "../../../../api/EstimationApi";
import { archiveFinancingPlanApi } from "../../../../api/FinancingPlanApi";
import { archiveCreditSimulatorApi } from "../../../../api/CreditSimulatorApi";
import { archiveFolderApi } from "../../../../api/FolderApi";

function CrmClientItems({ type, items, updateClientItemsTrigger }) {
  const [typeInfos, setTypeInfos] = useState({
    pageTitle: "",
    searchPlaceholder: "",
    createButtonLabel: "",
  });
  const [searchValue, setSearchValue] = useState("");
  const [resetSerchValueTrigger, setResetSerchValueTrigger] = useState(false);
  const [tabColumns, setTabColumns] = useState([]);
  const [orderBy, setOrderBy] = useState({
    key: null,
    order: null,
  });
  const [dropdownItem, setDropdownItem] = useState(null);

  const tabComponents = {
    tabColumns: {
      estimations: EstimationTabColumns,
      folders: FolderTabColumns,
      financingPlans: FinancingPlanTabColumns,
      creditSimulations: CreditSimulationTabColumns,
      visitingCards: VisitingCardTabColumns,
    },
    getColumnContent: {
      estimations: getEstimationColumnContent,
      folders: getFolderColumnContent,
      financingPlans: getFinancingPlanColumnContent,
      creditSimulations: getCreditSimulationColumnContent,
      visitingCards: getVisitingCardColumnContent,
    },
    filter: {
      estimations: filterEstimations,
      financingPlans: filterFinancingPlans,
      folders: filterFolders,
      creditSimulations: filterCreditSimulations,
      visitingCards: filterVisitingCards,
    },
    dropdownMenu: {
      estimations: EstimationsDropdownMenu,
      financingPlans: FinancingPlanDropdownMenu,
      creditSimulations: CreditSimulationDropdownMenu,
      folders: folderDropdownMenu,
      visitingCards: () => <></>,
    },
  };

  const itemsContainerRef = useRef(null);

  const { formatTabColumns, copyIdToClipboard } = useCrm();
  const { setIsDownloadNotificationLoading, createNotification, setModalVisible, setModalContent } =
    useContext(AppContext);
  const { width } = useWindowSize();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!searchParams.get("visiting_card_id")) return;
    // TODO show visiting card edit modal
    searchParams.delete("state");
    setSearchParams(searchParams);
  }, [searchParams]);

  useEffect(() => {
    switch (type) {
      case "estimations":
        setTypeInfos({
          pageTitle: "Estimations",
          searchPlaceholder: "Enseigne, UUID, type, adresse...",
          createButtonLabel: "Créer une estimation",
        });
        break;
      case "folders":
        setTypeInfos({
          pageTitle: "Dossiers",
          searchPlaceholder: "Nom, UUID...",
          createButtonLabel: "Créer un dossier",
        });
        break;
      case "financingPlans":
        setTypeInfos({
          pageTitle: "Plans de financement",
          searchPlaceholder: "Nom, UUID, type, estimation liée...",
          createButtonLabel: "Créer un plan de financement",
        });
        break;
      case "creditSimulations":
        setTypeInfos({
          pageTitle: "Simulations de prêt",
          searchPlaceholder: "Nom, UUID...",
          createButtonLabel: "Créer une simulation de prêt",
        });
        break;
      case "visitingCards":
        setTypeInfos({
          pageTitle: "Cartes de visite",
          searchPlaceholder: "Intitulé, UUID...",
          createButtonLabel: "Créer une carte de visite",
        });
        break;
    }

    setResetSerchValueTrigger(!resetSerchValueTrigger);
    setOrderBy({ key: null, order: null });
  }, [type]);

  useEffect(() => {
    setTabColumns(formatTabColumns(tabComponents.tabColumns[type], width));
  }, [type, width]);

  //Quick actions

  function getItemLink(type, item) {
    switch (type) {
      case "estimations":
        return `/synthese/${item.id}`;
      case "financingPlans":
        return `/plan-financement/${item.type.id === 1 ? "fdc" : "murs"}/${item.uuid}`;
      case "creditSimulations":
        return `/simulateur-credit/${item.uuid}`;
      case "folders":
        return `/mon-compte/mes-dossiers/${item.uuid}`;
      case "visitingCards":
        return `?visiting_card_id=${item.uuid}`;
    }
  }

  async function downloadItemPdf(itemId) {
    try {
      setIsDownloadNotificationLoading(true);
      let res;

      switch (type) {
        case "estimations":
          res = await downloadEstimationPdfApi(itemId);
          break;
        case "folders":
          res = await downloadFolderApi(itemId);
          break;
        case "financingPlans":
          res = await downloadFinancingPlanPdfApi(itemId);
          break;
        case "creditSimulations":
          res = await downloadCreditSimulatorPdfApi(itemId);
          break;
      }

      const link = createDownloadLink(res.data);
      if (!link) throw new Error("");

      link.click();
      link.remove();
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors du téléchargement de votre ressource. Veuillez réessayer</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    } finally {
      setIsDownloadNotificationLoading(false);
    }
  }

  function showDuplicateItemModal(itemId, itemName) {
    console.log(itemName, getDuplicateItemName(itemName));

    switch (type) {
      case "estimations":
        setModalContent({
          title: "Dupliquer l'estimation",
          content: (
            <DuplicateEstimationModal
              defaultValue={getDuplicateItemName(getEstimationName(itemId))}
              fetchEstimationsFunction={() => updateClientItemsTrigger(4)}
              estimationId={itemId}
            />
          ),
        });
        break;
      case "folders":
        setModalContent({
          title: "Dupliquer le dossier",
          content: (
            <DuplicateFolderModal
              defaultValue={getDuplicateItemName(itemName)}
              fetchFoldersFunction={() => updateClientItemsTrigger(3)}
              folderId={itemId}
            />
          ),
        });
        break;
      case "financingPlans":
        setModalContent({
          title: "Dupliquer le plan de financement",
          content: (
            <DuplicateFinancingPlanModal
              defaultValue={getDuplicateItemName(itemName)}
              fetchFinancingPlansFunction={() => updateClientItemsTrigger(5)}
              financingPlanId={itemId}
            />
          ),
        });
        break;
      case "creditSimulations":
        setModalContent({
          title: "Dupliquer la simulation de prêt",
          content: (
            <DuplicateCreditSimulatorModal
              defaultValue={getDuplicateItemName(itemName)}
              fetchCreditSimulatorsFunction={() => updateClientItemsTrigger(6)}
              creditSimulatorId={itemId}
            />
          ),
        });
    }
    setModalVisible(true);
  }

  async function archiveItem(itemId, shouldArchive) {
    try {
      switch (type) {
        case "estimations":
          await archiveEstimationApi(itemId, shouldArchive);
          updateClientItemsTrigger(4);
          break;
        case "folders":
          await archiveFolderApi(itemId, shouldArchive);
          updateClientItemsTrigger(3);
          break;
        case "financingPlans":
          await archiveFinancingPlanApi(itemId, shouldArchive);
          updateClientItemsTrigger(5);
          break;
        case "creditSimulations":
          await archiveCreditSimulatorApi(itemId, shouldArchive);
          updateClientItemsTrigger(6);
          break;
      }

      createNotification(<>La ressource a été {shouldArchive ? "archivée" : "désarchivée"} avec succès.</>);
    } catch (error) {
      createNotification(
        <>
          Une erreur est survenue lors {shouldArchive ? "de l'archivage" : "du désarchivage"} de la ressource. Veuillez
          réessayer.
        </>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  // General

  function toggleRowCheck(e, uuid) {
    e.preventDefault();

    // TODO
  }

  function toggleColumnBracket(key) {
    if (orderBy.key === key) {
      if (orderBy.order === "asc") return setOrderBy({ key, order: "desc" });

      return setOrderBy({ key: null, order: null });
    }

    setOrderBy({ key, order: "asc" });
  }

  function getOrderedItems(items, orderBy, searchValue) {
    let result = tabComponents.filter[type](items, searchValue);

    if (!orderBy.key) return result;

    result.sort((a, b) => {
      let valA = tabComponents.getColumnContent[type](orderBy.key, a);
      let valB = tabComponents.getColumnContent[type](orderBy.key, b);

      if (orderBy.key.includes("date_")) {
        valA = valA === "-" ? new Date() : stringToDate(valA + ":00");
        valB = valB === "-" ? new Date() : stringToDate(valB + ":00");
        return valA - valB;
      }

      if (typeof valA === "number" && typeof valB === "number") {
        return valA - valB;
      }

      return String(valA).localeCompare(String(valB), "fr", { numeric: true });
    });

    if (orderBy.order === "desc") result.reverse();
    return result;
  }

  async function toggleVisitingCardDefault(e, visitingCard) {
    e.preventDefault();

    // TODO
  }

  function getItemTypeClassName(type) {
    switch (type?.id) {
      case 1:
        return "blue-text";
      case 2:
        return "grey-text";
      case 3:
        return "gold-text";
    }
  }

  function setActiveItem(e, item, index) {
    e.stopPropagation();
    e.preventDefault();

    if (item === dropdownItem?.item) return setDropdownItem(false);

    setDropdownItem({
      item: item,
      itemRef: itemsContainerRef.current.children[index],
      top: itemsContainerRef.current.children[index].offsetTop + 150,
    });
  }

  // Estimations

  function getEstimationName(itemId) {
    const estimation = items.find(estimation => estimation.id === itemId);
    return estimation.infos.enseigne ?? estimation.infos.nom;
  }

  return (
    <>
      <h2 className='crm-h2'>{typeInfos.title}</h2>
      <div className='crm-action-row'>
        <Searchbar
          className='crm-searchbar'
          value
          placeholder={typeInfos.searchPlaceholder}
          onChange={value => setSearchValue(value)}
          resetValueTrigger={resetSerchValueTrigger}
        />
        <FilledButton disabled>
          <Download width='20px' />
          Exporter la sélection
        </FilledButton>
        <FilledButton disabled>
          <Add width='20px' /> {typeInfos.createButtonLabel}
        </FilledButton>
      </div>
      <div className='crm-table-container'>
        <div className='crm-thead'>
          <Checkbox name='checkbox-all' className='crm-checkbox' />
          {tabColumns?.map(
            column =>
              column.visible && (
                <div
                  className={`crm-column-${column.key} ${column.showBracket ? "cursor-pointer" : ""}`}
                  key={column.id}
                  {...(column.showBracket && { onClick: () => toggleColumnBracket(column.key) })}>
                  {column.label}
                  {column.showBracket && (
                    <Bracket
                      rotation={orderBy.key == column.key ? (orderBy.order === "asc" ? "180deg" : "0deg") : "90deg"}
                      width='12px'
                      className='ml-xs'
                      color='var(--blue)'
                    />
                  )}
                </div>
              ),
          )}
          <div className='crm-column-actions'>Actions</div>
        </div>
        <DropdownMenu
          itemsContainerRef={itemsContainerRef}
          dropdownItem={dropdownItem}
          closeDropdown={() => setDropdownItem(null)}>
          {tabComponents.dropdownMenu[type]({
            activeItem: dropdownItem?.item,
            downloadItemPdf,
            showDuplicateItemModal,
            archiveItem,
          })}
        </DropdownMenu>
        <div className='crm-tbody' ref={itemsContainerRef}>
          {!items?.length && <p className='centered text-xl mt-lg'>Aucun résultat</p>}
          {getOrderedItems(items, orderBy, searchValue)?.map((item, index) => (
            <Link
              {...(type !== "visitingCards" && { target: "_blank" })}
              className='crm-table-row'
              key={item.id ?? item.uuid}
              to={getItemLink(type, item)}>
              <Checkbox className='crm-checkbox crm-clickable-column' onChange={e => toggleRowCheck(e, item.uuid)} />
              {tabColumns?.map(column => {
                if (!column.visible) return null;
                switch (column.key) {
                  case "uuid":
                    return (
                      <div
                        key={column.id}
                        className='crm-row-column crm-column-uuid crm-clickable-column'
                        onClick={e => copyIdToClipboard(e, item.uuid)}>
                        <Copy width='18px' color='var(--white)' onClick={e => copyIdToClipboard(e, item.uuid)} />
                      </div>
                    );
                  case "type":
                    return (
                      <div
                        className={`crm-row-column crm-tag crm-column-${column.key} crm-${getItemTypeClassName(item.type)}`}
                        key={column.id}>
                        {item.type?.id === 1 ? (
                          <Shop width='20px' color='#b9bafd' className='mr-sm' />
                        ) : item.type?.id === 2 ? (
                          <Factory width='20px' color='#cac8c8' className='mr-sm' />
                        ) : (
                          <Building width='20px' color='var(--light-gold)' className='mr-sm' />
                        )}
                        {item.type?.description.split(" ")[0]}
                      </div>
                    );
                  case "archived":
                    return (
                      <div className={`crm-row-column crm-tag crm-column-archive`} key={column.id}>
                        {item.archived ? (
                          <Unarchive width='20px' color='var(--red)' />
                        ) : (
                          <Archive width='20px' color='var(--green)' />
                        )}
                      </div>
                    );
                  case "validated":
                    return item.steps_status?.completed_steps === item.steps_status?.total_steps ? (
                      <div className='crm-row-column crm-column-archive crm-estimation-validated' key={column.id}>
                        <Check width='11px' color='var(--white)' />
                      </div>
                    ) : (
                      <ProgressCircle
                        key={column.id}
                        className='crm-row-column crm-tag crm-clickable-columncrm-column-archive'
                        totalSteps={item.steps_status?.total_steps}
                        currentStep={item.steps_status?.completed_steps}
                      />
                    );
                  case "is_default":
                    return (
                      <div
                        className='crm-row-column crm-column-is_default'
                        onClick={e => toggleVisitingCardDefault(e, item)}
                        key={column.id}>
                        <Toggle checked={item.is_default} />
                      </div>
                    );
                  default:
                    return (
                      <div className={`crm-row-column crm-column-${column.key}`} key={column.id}>
                        {tabComponents.getColumnContent[type](column.key, item)}
                      </div>
                    );
                }
              })}
              <div className='crm-column-actions crm-clickable-column' onClick={e => setActiveItem(e, item, index)}>
                <Bracket width='14px' rotation={dropdownItem?.item?.uuid === item.uuid ? "0deg" : "180deg"} />
              </div>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
}

export default CrmClientItems;
