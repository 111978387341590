import Bracket from "../../atoms/icons/general/bracket/Bracket";
import FilledButton from "../../molecules/buttons/filledButton/FilledButton";
import UnfilledButton from "../../molecules/buttons/unfilledButton/UnfilledButton";
import Checkbox from "../../molecules/formComponents/checkbox/Checkbox";
import MultiSelect from "../../molecules/formComponents/multiSelect/MultiSelect";
import NumberInput from "../../molecules/formComponents/numberInput/NumberInput";
import "./CrmSortingTab.css";
import React, { useEffect, useState } from "react";

function CrmSortingTab({
  tabColumns,
  triggerFiltersReset = () => {},
  resultsLimit,
  setResultLimit = () => {},
  filters,
  applySortingFilters = () => {},
}) {
  const [isSortingTabOpen, setIsSortingTabOpen] = useState(false);
  const [tmpFilters, setTmpFilters] = useState(filters);
  const [sortedColumns, setSortedColumns] = useState([]);
  const [tmpResultsLimit, setTmpResultsLimit] = useState(resultsLimit);

  useEffect(() => {
    formatSortedColumns();
  }, [tabColumns]);

  useEffect(() => {
    setTmpResultsLimit(resultsLimit);
  }, [resultsLimit]);

  useEffect(() => {
    setTmpFilters(filters);
  }, [filters]);

  function resetValues() {
    triggerFiltersReset();

    setTmpFilters(filters =>
      filters.map(filter => ({
        ...filter,
        options: filter.options.map(option => ({ ...option, selected: false })),
      })),
    );
    setResultLimit(50);

    setIsSortingTabOpen(false);
  }

  function applyValues() {
    setIsSortingTabOpen(false);

    applySortingFilters({
      resultsLimit: tmpResultsLimit,
      tabColumns: tabColumns.map(tabColumn => {
        const column = sortedColumns
          .find(group => group.name === tabColumn.group)
          .columns.find(column => column.id === tabColumn.id);
        tabColumn.visible = column.visible;

        return tabColumn;
      }),
      filters: tmpFilters.map(filter => ({
        id: filter.id,
        key: filter.key,
        options: filter.options.filter(option => option.selected),
      })),
    });
  }

  // Sorting filters

  function updateFilter(id, value) {
    let result = { ...tmpFilters };

    const updatedFilter = tmpFilters.find(filter => filter.id === id);

    updatedFilter.options = value;
    result = tmpFilters.map(filter => (filter.id === id ? updatedFilter : filter));

    setTmpFilters(result);
  }

  // Columns check

  function formatSortedColumns() {
    const result = tabColumns.reduce((acc, column) => {
      const index = acc.findIndex(element => element.name === column.group);

      if (index === -1) acc.push({ name: column.group, columns: [column], id: acc.length });
      else acc[index].columns.push(column);

      return acc;
    }, []);

    setSortedColumns(result);
  }

  function toggleColumnCheck(id, groupName) {
    const result = sortedColumns.map(group => {
      if (group.name !== groupName) return group;

      group.columns = group.columns.map(column => {
        if (column.id === id) column.visible = !column.visible;
        return column;
      });

      return group;
    });

    setSortedColumns(result);
  }

  return (
    <div className={`sorting-tab-background ${isSortingTabOpen ? "opened" : ""}`}>
      <div className='sorting-tab-toggle' onClick={() => setIsSortingTabOpen(!isSortingTabOpen)}>
        <Bracket width='20px' rotation={isSortingTabOpen ? "90deg" : "270deg"} />
        Trier
      </div>
      <div className='sorting-tab-container'>
        <h3 className='crm-sorting-tab-title'>Nombre de résultats affichés</h3>
        <NumberInput value={tmpResultsLimit} onChange={e => setTmpResultsLimit(e.target.value)} />
        <h3 className='crm-sorting-tab-title'>Filtres</h3>
        {tmpFilters?.map(filter => (
          <MultiSelect
            key={filter.id}
            label={filter.name}
            options={filter.options}
            setOptions={value => updateFilter(filter.id, value)}
          />
        ))}
        <h3 className='crm-sorting-tab-title'>Colonnes affichées</h3>
        <div className='sorting-tab-columns-container'>
          {sortedColumns.map(group => (
            <div className='sorting-tab-columns-group' key={group.id}>
              <h3>{group.name}</h3>
              {group.columns.map(column => (
                <Checkbox
                  key={column.id}
                  label={column.label}
                  checked={column.visible}
                  name={column.id}
                  onChange={() => toggleColumnCheck(column.id, group.name)}
                />
              ))}
            </div>
          ))}
        </div>
        <div className='modal-buttons-row'>
          <UnfilledButton padding='10px 25px' onClick={() => resetValues()}>
            Réinitialiser
          </UnfilledButton>
          <FilledButton padding='10px 25px' onClick={() => applyValues()}>
            Appliquer
          </FilledButton>
        </div>
      </div>
    </div>
  );
}

export default CrmSortingTab;
