import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../../../context/AppContext";
import { useForm } from "react-hook-form";
import Input from "../../../../molecules/formComponents/input/Input";
import NumberInput from "../../../../molecules/formComponents/numberInput/NumberInput";
import { emailValidation, phoneValidation } from "../../../../../utils/formValidation/FormValidation";
import { fetchUserDropdownApi, updateUserDataApi } from "../../../../../api/AccountApi";
import Select from "../../../../molecules/formComponents/select/Select";
import UnfilledButton from "../../../../molecules/buttons/unfilledButton/UnfilledButton";
import FilledButton from "../../../../molecules/buttons/filledButton/FilledButton";
import { createClientAccountApi, updateClientAccountApi } from "../../../../../api/CrmClientsApi";
import Checkbox from "../../../../molecules/formComponents/checkbox/Checkbox";

function CreateUserModal({ clientInfos, triggerUpdate = () => {}, partnersList, rolesList }) {
  const {
    register,
    unregister,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const inputForm = { register, unregister, watch, setValue };

  const { setModalVisible, createNotification } = useContext(AppContext);
  const [userTypes, setUserTypes] = useState([]);

  useEffect(() => {
    fetchUserTypes();
  }, []);

  async function fetchUserTypes() {
    try {
      const res = await fetchUserDropdownApi("type");

      if (clientInfos) fillClientInfos(res.data);
      else fillDefaultValues();

      setUserTypes(res.data);
    } catch (error) {
      createNotification(
        "Une erreur est survenue lors de la récupération de données. Veuillez réessayer plus tard.",
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  async function formSubmit(values) {
    setModalVisible(false);
    try {
      values.partner.use_partner_discount = values.partner.use_partner_discount ? 1 : 0;

      if (clientInfos) await updateClientAccountApi(values, clientInfos.uuid);
      else await createClientAccountApi(values);

      triggerUpdate();

      createNotification(<>L'utilisateur a été {clientInfos ? "mis à jour" : "créé"} avec succès.</>);
    } catch (error) {
      createNotification(
        <>
          Une erreur est survenue lors de la {clientInfos ? "mise à jour" : "création"} de l'utilisateur. Veuillez
          réessayer
        </>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  function fillClientInfos(userTypes) {
    for (const [key, value] of Object.entries(clientInfos)) key !== "use_partner_discount" && setValue(key, value);
    const userTypeId = userTypes.find(userType => userType.description === clientInfos.type)?.id + "";
    if (clientInfos.use_partner_discount !== null)
      setValue("partner.use_partner_discount", clientInfos.use_partner_discount);

    setTimeout(() => {
      setValue("user_type_id", userTypeId ? userTypeId : "_null");
      setValue("role_id", rolesList.find(role => role.description === clientInfos.role)?.id);
    }, 100);
  }

  function fillDefaultValues() {
    setValue("partner.use_partner_discount", true);
    setValue("account_status_id", "1");
    setValue("role_id", rolesList.find(role => role.description === "user")?.id);
  }

  function fillTestValues() {
    // fill test values in all fields
    setValue("email", "zebi@gmail.com");
    setValue("first_name", "Zebi");
    setValue("last_name", "Zebi");
    setValue("company_name", "Zebi");
    setValue("user_type_id", "1");
    setValue("street", "Zebi");
    setValue("zip_code", "696961");
    setValue("city", "Zebi");
    setValue("phone", "06 06 06 06 06");
  }

  return (
    <form onSubmit={handleSubmit(formSubmit)}>
      <Input
        type='email'
        name='email'
        label='Email'
        useForm={inputForm}
        validation={emailValidation}
        error={errors?.email?.message}
        visible={!clientInfos}
      />
      <div className='row-500' onClick={fillTestValues}>
        <Input name='first_name' label='Prénom' useForm={inputForm} error={errors?.first_name?.message} />
        <Input name='last_name' label='Nom' useForm={inputForm} error={errors?.first_name?.message} />
      </div>
      <Input
        validation={{}}
        useForm={inputForm}
        error={errors?.company_name?.message}
        name='company_name'
        label='Raison sociale'
      />
      <Select
        useForm={inputForm}
        error={errors?.user_type_id?.message}
        name='user_type_id'
        label='Profession'
        children={userTypes.map(type => (
          <option key={type.id} value={type.id}>
            {type.description}
          </option>
        ))}
      />
      <Input useForm={inputForm} error={errors?.street?.message} name='street' label='Adresse' />
      <div className='row-500'>
        <Input useForm={inputForm} error={errors?.zip_code?.message} name='zip_code' label='Code postal' />
        <Input useForm={inputForm} error={errors?.city?.message} name='city' label='Ville' />
      </div>
      <NumberInput
        type='phone'
        useForm={inputForm}
        error={errors?.phone?.message}
        name='phone'
        validation={phoneValidation}
        label='Téléphone'
      />
      <div className='row-500'>
        {partnersList && (
          <Select useForm={inputForm} validation={{}} label='Réseau' name='partner.partner_id'>
            {partnersList.map(partner => (
              <option key={partner.partner_id} value={partner.partner_id}>
                {partner.partner_name}
              </option>
            ))}
          </Select>
        )}
        {clientInfos?.use_partner_discount !== null && (
          <div className='w-100 my-auto'>
            <Checkbox
              useForm={inputForm}
              name='partner.use_partner_discount'
              label='Profite des réductions du réseau'
              visible={watch("partner.partner_id") !== "_null"}
            />
          </div>
        )}
      </div>
      <div className='row-500'>
        <Select
          useForm={inputForm}
          error={errors?.account_status_id?.message}
          label='Statut de compte'
          name='account_status_id'>
          <option value='0'>Non confirmé</option>
          <option value='1'>Actif</option>
          <option value='2'>Désactivé</option>
          <option value='3'>TODO</option>
        </Select>
        <Select useForm={inputForm} error={errors?.role_id?.message} label='Rôle' name='role_id'>
          {rolesList.map(role => (
            <option key={role.id} value={role.id}>
              {role.description}
            </option>
          ))}
        </Select>
      </div>
      <div className='modal-buttons-row'>
        <UnfilledButton onClick={() => setModalVisible(false)} padding='10px 20px'>
          Annuler
        </UnfilledButton>
        <FilledButton padding='10px 20px' type='submit'>
          {clientInfos ? "Modifier l'utilisateur" : "Créer l'utilisateur"}
        </FilledButton>
      </div>
    </form>
  );
}

export default CreateUserModal;
