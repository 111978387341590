import { useParams } from "react-router-dom";
import "./CrmDetail.css";
import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../../components/molecules/breadcrumbs/Breadcrumbs";
import Banner from "../../../../components/molecules/banner/Banner";
import CrmClientView from "../crmClientView/CrmClientView";
import ArrowSm from "../../../../components/atoms/icons/general/arrowSm/ArrowSm";
import { Link } from "react-router-dom";

function CrmDetail() {
  const [pageTitle, setPageTitle] = useState("");

  const { tabParam, uuid } = useParams();

  useEffect(() => {
    switch (tabParam) {
      case "clients":
        setPageTitle("Détail client");
        break;
    }
  }, [tabParam]);
  return (
    <>
      <section className='page-container container'>
        <Breadcrumbs
          routes={[
            { to: "/", name: "Accueil" },
            { to: "/admin/crm", name: "CRM" },
            { to: "/admin/crm/" + tabParam, name: tabParam.replaceAll("-", " ").replace(/^\w/, c => c.toUpperCase()) },
            { to: `/admin/crm/${tabParam}/${uuid}`, name: pageTitle },
          ]}
        />
        <Banner
          title={pageTitle}
          subtitle={
            <Link className='link-with-arrow' to={`/admin/crm/${tabParam}`}>
              <ArrowSm rotation='0deg' /> Retour aux {pageTitle.split(" ")[1]}s
            </Link>
          }
        />
        {tabParam === "clients" && <CrmClientView />}
      </section>
    </>
  );
}

export default CrmDetail;
