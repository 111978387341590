import React, { useContext, useEffect } from "react";
import Breadcrumbs from "../../../components/molecules/breadcrumbs/Breadcrumbs";
import Banner from "../../../components/molecules/banner/Banner";
import ObtainFinancingForm from "../../../components/forms/obtainFinancingForm/ObtainFinancingForm";
import AppContext from "../../../context/AppContext";

function ObtainFinancing() {
  const { setBannerConfig } = useContext(AppContext);

  useEffect(() => {
    setBannerConfig({
      title: <>Obtenir un financement</>,
      subtitle: (
        <>
          Vous recherchez un financement professionnel ?<br /> Complétez le formulaire ci-dessous pour être contacté par
          notre partenaire, qui vous indiquera la finançabilité du projet.
        </>
      ),
    });
  }, []);

  return (
    <>
      <section className='container page-container'>
        <ObtainFinancingForm />
      </section>
    </>
  );
}

export default ObtainFinancing;
