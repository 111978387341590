export const ClientTabSearchPlaceholder = "UUID, email, réseau...";

export const ClientTabColumns = [
  {
    id: 1,
    type: "uuid",
    label: "UUID",
    key: "uuid",
    default: 0,
    group: "Utilisateur",
  },
  {
    id: 2,
    label: "Email",
    key: "email",
    default: 992,
    showBracket: true,
    group: "Utilisateur",
  },
  {
    id: 3,
    label: "Nom",
    key: "name",
    default: 0,
    group: "Utilisateur",
  },
  {
    id: 4,
    label: "Statut",
    key: "status",
    default: 10000,
    group: "Utilisateur",
  },
  {
    id: 18,
    label: "Rôle",
    key: "role",
    default: 10000,
    group: "Divers",
  },
  {
    id: 5,
    label: "Réseau",
    key: "partner_name",
    default: 1200,
    group: "Utilisateur",
  },
  {
    id: 6,
    label: "Adresse",
    key: "address",
    default: 100000,
    group: "Utilisateur",
  },
  {
    id: 7,
    label: "Code postal",
    key: "zip_code",
    default: 100000,
    group: "Utilisateur",
  },
  {
    id: 8,
    label: "Raison sociale",
    key: "company_name",
    default: 100000,
    group: "Utilisateur",
  },
  {
    id: 9,
    label: "Téléphone",
    key: "phone",
    default: 768,
    group: "Utilisateur",
  },
  {
    id: 10,
    label: "Date d'inscription",
    key: "date_creation",
    showBracket: true,
    default: 10000,
    group: "Divers",
  },
  {
    id: 11,
    label: "Début abo",
    key: "date_start_subscription",
    default: 10000,
    group: "Divers",
  },
  {
    id: 12,
    label: "Fin abo",
    key: "date_end_subscription",
    default: 10000,
    group: "Divers",
  },
  {
    id: 13,
    label: "NB estimations",
    key: "todo",
    default: 10000,
    group: "Divers",
  },
  {
    id: 14,
    label: "Dernière connexion",
    key: "date_last_connexion",
    default: 10000,
    showBracket: true,
    group: "Divers",
  },
  {
    id: 19,
    label: "Dernière update",
    key: "date_last_update",
    showBracket: true,
    default: 10000,
    group: "Divers",
  },
  {
    id: 15,
    label: "Notes",
    key: "notes",
    visible: true,
    type: "notes",
    default: 1400,
    group: "Divers",
  },
  {
    id: 16,
    label: "Abonnement",
    key: "subscription_name",
    default: 992,
    group: "Divers",
  },
  {
    id: 17,
    label: "Statut de paiement",
    key: "payment_status",
    default: 576,
    group: "Divers",
  },
];

export function getClientTabColumnClassName(key, value) {
  switch (key) {
    case "subscription_name":
      switch (value) {
        case "MAESTRO":
        case "MAES":
          return "blue-text";
        case "LIBERTY":
        case "LIB":
          return "grey-text";
        case "OPTIMUM":
        case "OPTIM":
          return "gold-text";
        case "FREE":
          return "green-text";
        default:
      }
    case "payment_status":
      switch (value) {
        case "Echec paiement":
          return "red-background crm-tag";
        case "Résiliation en cours":
          return "orange-background crm-tag";
        case "Résilié":
          return "yellow-background crm-tag";
        case "Aucun abonnement":
          return "grey-background crm-tag";
        case "Actif":
          return "green-background crm-tag";
      }
  }
  return "";
}

export async function getClientSortingFilters(partners, subscriptions, roles) {
  const result = [
    {
      id: 1,
      name: "Réseau",
      key: "partner",
      options: [
        { id: "0", description: "Aucun réseau", selected: false },
        ...partners.map(partner => ({
          id: partner.partner_id,
          description: partner.partner_name,
          selected: false,
        })),
      ],
    },
    {
      id: 2,
      name: "Statut d'abonnement",
      key: "subscription_status",
      options: [
        { id: "110", description: "Actif", selected: false },
        { id: "001", description: "Résilié", selected: false },
        { id: "101", description: "Résiliation en cours", selected: false },
        { id: "100", description: "Echec paiement", selected: false },
      ],
    },
    {
      id: 3,
      name: "Type d'abonnement",
      key: "subscription_type",
      options: [
        { id: "0", description: "Aucun abonnement", selected: false },
        ...subscriptions.map(subscription => ({
          id: subscription.price_id,
          description: subscription.price_name,
          selected: false,
        })),
      ],
    },
    {
      id: 4,
      name: "Rôle",
      key: "role",
      options: roles.map(role => ({ id: role.id, description: role.description, selected: false })),
    },
    {
      id: 5,
      key: "estimation_type",
      name: "Type d'estimations réalisées",
      options: [
        { id: 1, description: "Fonds de commerce", selected: false },
        { id: 2, description: "Titres de société", selected: false },
        { id: 3, description: "Murs commerciaux", selected: false },
      ],
    },
  ];

  return result;
}
