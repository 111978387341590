import axiosInstance from "./AxiosConfig";

//Newsletter

export const newsletterSubscriptionApi = async email => {
  return axiosInstance.post("/newsletter/user/create", {
    email: email,
  });
};

// Demande de démo

export const demoRequestApi = async (firstName, lastName, email, phone, content) => {
  return axiosInstance.post("/contact/demo", {
    first_name: firstName,
    last_name: lastName,
    email: email,
    phone: phone,
    content: content,
  });
};

//Contact

export const contactRequestApi = async (firstName, lastName, email, phone, subject, content) => {
  return axiosInstance.post("/contact/form", {
    first_name: firstName,
    last_name: lastName,
    email: email,
    phone: phone,
    subject: subject,
    content: content,
  });
};

// Réseaux

export const fetchAllPartnersApi = async () => axiosInstance.get("/partners/all");

// Rôles

export const fetchAllRolesApi = async () => axiosInstance.get("/roles/all");

export const fetchRoleByIdApi = async roleId => axiosInstance.get("/roles/" + roleId);
