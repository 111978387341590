import "./ContributionCalculator.css";
import React, { useContext, useEffect } from "react";
import BreadCrumbs from "../../../components/molecules/breadcrumbs/Breadcrumbs";
import Banner from "../../../components/molecules/banner/Banner";
import ContributionCalculatorForm from "../../../components/forms/contributionCalculatorForm/ContributionCalculatorForm";
import { useAuth } from "../../../context/AuthContext";
import { Link } from "react-router-dom";
import ArrowSm from "../../../components/atoms/icons/general/arrowSm/ArrowSm";
import AppContext from "../../../context/AppContext";

function ContributionCalculator() {
  const { setBannerConfig } = useContext(AppContext);

  useEffect(() => {
    setBannerConfig({
      title: <>Calculateur de cotisations</>,
      subtitle: <>Estimez le montant des cotisations patronales</>,
    });
  }, []);

  return (
    <>
      <section className='container page-container'>
        <ContributionCalculatorForm />
      </section>
    </>
  );
}

export default ContributionCalculator;
