import "./MultiSelect.css";
import React, { useRef } from "react";
import Cross from "../../../atoms/icons/general/cross/Cross.js";

// options = [
//   {
//     id: 1,
//     description: "",
//     selected: false,
//   }
// ]
function MultiSelect({
  label,
  name,
  options,
  setOptions = () => {},
  onKeyDown = () => {},
  onFocus = () => {},
  className = "",
}) {
  const selectRef = useRef(null);

  function handleChange(e) {
    const result = options.map(value => (value.id + "" === e.target.value ? { ...value, selected: true } : value));

    setOptions(result);

    selectRef.current && (selectRef.current.value = "_null");
  }

  function unselectOption(option) {
    const result = options.map(value => (value.id === option.id ? { ...value, selected: false } : value));

    setOptions(result);

    selectRef.current && (selectRef.current.value = "_null");
  }

  return (
    <div className={`w-100 d-flex ${className}`}>
      <div className='w-100'>
        <div className='field-container'>
          <label className='form-label'>{label}</label>
          <select onFocus={onFocus} onKeyDown={onKeyDown} id={name} ref={selectRef} onChange={handleChange}>
            <option value='_null'>...</option>
            {options.map(option => (
              <option key={option.id} value={option.id}>
                {option.description}
              </option>
            ))}
          </select>
          <div className='multi-select-values-container'>
            {options.map(
              option =>
                option.selected && (
                  <div key={option.id} onClick={() => unselectOption(option)}>
                    {option.description}
                    <Cross width='8px' onClick={() => unselectOption(option)} />
                  </div>
                ),
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MultiSelect;
