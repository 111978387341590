import CardWithHeaders from "../../../components/molecules/cards/cardWithHeaders/CardWithHeaders";
import StickySearchbar from "../../../components/molecules/stickySearchbar/StickySearchbar";
import "./ActivityList.css";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useWindowScroll } from "@uidotdev/usehooks";
import { fetchAllActivitiesApi } from "../../../api/ToolsApi";
import { useNavigate } from "react-router-dom";
import AppContext from "../../../context/AppContext";
import { normalizeString } from "../../../utils/Utils";

function ActivityList() {
  const [activities, setActivities] = useState([]);
  const [displayedActivities, setDisplayedActivities] = useState([]);

  const containerRef = useRef();
  const [windowScroll] = useWindowScroll();
  const { setAppLoaderVisible, setBannerConfig } = useContext(AppContext);
  const navigate = useNavigate();

  const columns = [
    [
      "Hôtellerie Restauration (CHR)",
      "Bar Tabac Presse Loto PMU",
      "Métiers de bouche",
      "Auto - Moto - Cycles",
      "Equipement de la Personne",
    ],
    ["Services à la Personne", "Soins à la Personne", "Activités de loisirs", "Equipement de la Maison"],
    ["TPE - PME - Artisanat", "Services aux Entreprises"],
  ];

  useEffect(() => {
    setBannerConfig({
      title: <>Liste des activités estimables</>,
      subtitle: <>Consultez la toalité des activités que vous pouvez estimer sur notre site</>,
    });
    setAppLoaderVisible(true);
    fetchActivities();
  }, []);

  async function fetchActivities() {
    try {
      const activities = (await fetchAllActivitiesApi()).data;

      sortActivities(activities);
      setAppLoaderVisible(false);
    } catch (error) {
      navigate("/404");
    }
  }

  function sortActivities(activities) {
    const sortedActivities = [];

    for (let i = 0; i < columns.length; i++) {
      sortedActivities.push([]);

      for (const category of columns[i]) {
        const index = activities.findIndex(activity => {
          return activity.category === category;
        });

        if (index !== -1) {
          sortedActivities[i].push(activities[index]);
          activities.splice(index, 1);
        }
      }
    }
    setActivities(sortedActivities);
    setDisplayedActivities(sortedActivities);
  }

  function handleSearchbarChange(value) {
    if (value.length < 1) return setDisplayedActivities(activities);

    const normalizedValue = normalizeString(value).toLowerCase();
    const flattenActivities = [];
    let filteredActivities = [];
    const result = [];
    activities.forEach(column => flattenActivities.push(...column));

    // get all matching activities
    for (const category of flattenActivities) {
      if (category.activities) {
        const tmpActivities = category.activities.filter(activity =>
          normalizeString(activity).toLowerCase().includes(normalizedValue),
        );

        if (tmpActivities.length) {
          filteredActivities.push({ category: category.category, activities: tmpActivities });
        }
      }
    }
    filteredActivities = filteredActivities.sort((a, b) => b.activities.length - a.activities.length);

    // sorting categories into 3 columns
    for (let i = 0; i < filteredActivities.length; i++) {
      if (i < 3) result.push([]);

      result[i % 3].push(filteredActivities[i]);
    }

    setDisplayedActivities(result);

    if (windowScroll.y > 500) window.scrollTo(0, 0);
  }

  return (
    <>
      <section className='container page-container'>
        <StickySearchbar onChange={handleSearchbarChange} />
        <div className='relative'>
          {!displayedActivities.length && (
            <p className='text-xl text-center outfit-semibold'>Aucun résultat ne correspond à votre recherche</p>
          )}
          <div ref={containerRef} className='liste-activites-container'>
            {Array.isArray(displayedActivities) &&
              displayedActivities.map((column, key) => (
                <div key={key}>
                  {column?.map((category, key) => (
                    <CardWithHeaders
                      borderColor='var(--dark-blue)'
                      key={key}
                      paddingFooter='0'
                      header={
                        <div className='flex'>
                          <div className='activity-category'>{category.category}</div>
                        </div>
                      }>
                      <ul>
                        {category.activities.map((activity, key) => (
                          <li key={key}>{activity}</li>
                        ))}
                      </ul>
                    </CardWithHeaders>
                  ))}
                </div>
              ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default ActivityList;
