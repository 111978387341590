import React, { createContext, useContext, useEffect, useState } from "react";
import AppContext from "./AppContext";
import { copyToClipboard } from "../utils/Utils";
import { fetchAllPartnersApi, fetchAllRolesApi } from "../api/UtilsApi";
import { fetchPricesApi } from "../api/TarifsApi";

const CrmContext = createContext();

export function useCrm() {
  return useContext(CrmContext);
}

export function CrmProvider({ children }) {
  const { createNotification } = useContext(AppContext);
  const [subscriptionsList, setSubscriptionsList] = useState([]);
  const [partnersList, setPartnersList] = useState([]);
  const [rolesList, setRolesList] = useState([]);

  useEffect(() => {
    fillLists();
  }, []);

  async function fillLists() {
    setRolesList((await fetchAllRolesApi()).data);
    setPartnersList((await fetchAllPartnersApi()).data);
    setSubscriptionsList((await fetchPricesApi()).data);
  }

  function formatTabColumns(columns, width) {
    if (!columns || !width) return;

    return columns.map(columns => ({ ...columns, visible: columns.default <= width }));
  }

  function getClientPaymentStatus(clientSubscription) {
    if (!clientSubscription) return "Aucun abonnement";

    clientSubscription = clientSubscription.current;

    if (!clientSubscription) return "-";
    if (clientSubscription.is_active && clientSubscription.is_auto_renewed && !clientSubscription.is_cancelled)
      return "Actif";
    if (clientSubscription.is_active && !clientSubscription.is_auto_renewed && clientSubscription.is_cancelled)
      return "Résiliation en cours";
    if (!clientSubscription.is_active && !clientSubscription.is_auto_renewed && clientSubscription.is_cancelled)
      return "Résilié";
    if (!clientSubscription.is_active && !clientSubscription.is_auto_renewed && !clientSubscription.is_cancelled)
      return "Echec paiement";
    if (clientSubscription.is_active && clientSubscription.is_free_trial) return "Actif";
  }

  function copyIdToClipboard(e, id) {
    e.preventDefault();
    e.stopPropagation();

    copyToClipboard(id, <>La référence a été copiée dans votre presse-papier avec succès.</>, createNotification);
  }

  return (
    <CrmContext.Provider
      value={{
        formatTabColumns,
        copyIdToClipboard,
        getClientPaymentStatus,
        partnersList,
        subscriptionsList,
        rolesList,
      }}>
      {children}
    </CrmContext.Provider>
  );
}
