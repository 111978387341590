import "./DropdownMenu.css";
import React, { useEffect, useRef, useState } from "react";

function DropdownMenu({ children, itemsContainerRef, dropdownItem, closeDropdown = () => {} }) {
  const dropdownMenuRef = useRef(null);
  const [itemsContainerScroll, setItemsContainerScroll] = useState(0);

  useEffect(() => {
    document.addEventListener("click", handleCloseDropdownMenu);

    return () => {
      document.removeEventListener("click", handleCloseDropdownMenu);
    };
  }, [dropdownMenuRef.current]);

  useEffect(() => {
    if (!itemsContainerRef?.current) return;

    onItemsContainerScroll();

    itemsContainerRef.current?.addEventListener("scroll", onItemsContainerScroll);

    return () => {
      itemsContainerRef.current?.removeEventListener("scroll", onItemsContainerScroll);
    };
  }, [dropdownItem]);

  function onItemsContainerScroll() {
    if (!dropdownItem) return setItemsContainerScroll(itemsContainerRef.current.scrollTop);

    const elementOffsetBottom = dropdownItem?.itemRef?.offsetTop ?? 0 + dropdownItem?.itemRef?.offsetHeight ?? 0;

    const containerOffsetBottom = itemsContainerRef.current.scrollTop + itemsContainerRef.current.offsetHeight;
    let dropdownMenuHeight = 20;
    for (const child of dropdownMenuRef.current.children) dropdownMenuHeight += child.offsetHeight + 10;

    if (elementOffsetBottom + dropdownMenuHeight + 25 > containerOffsetBottom)
      setItemsContainerScroll(itemsContainerRef.current.scrollTop + dropdownMenuHeight + 15);
    else setItemsContainerScroll(itemsContainerRef.current.scrollTop);

    if (
      (dropdownItem && elementOffsetBottom < itemsContainerRef.current.scrollTop) ||
      elementOffsetBottom > containerOffsetBottom
    )
      closeDropdown();
  }

  function handleCloseDropdownMenu() {
    if (!dropdownMenuRef.current) return;

    closeDropdown();
  }

  return (
    <ul
      ref={dropdownMenuRef}
      className={`dropdown-menu-item ${dropdownItem ? "show" : ""}`}
      style={{ "--top": dropdownItem?.top - itemsContainerScroll + "px" }}>
      {children}
    </ul>
  );
}

export default DropdownMenu;
