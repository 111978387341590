import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import NumberInput from "../../../../molecules/formComponents/numberInput/NumberInput";
import {
  loyerValidation,
  max2250Validation,
  numberValidation,
} from "../../../../../utils/formValidation/FormValidation.js";
import Info from "../../../../atoms/icons/general/info/Info";
import { ParcoursContext } from "../../../../../context/ParcoursContext";
import Textarea from "../../../../molecules/formComponents/textarea/Textarea.js";
import { formatObjectForPosting, removeSpaces } from "../../../../../utils/Utils.js";
import Tuto from "../../../../atoms/icons/general/tuto/Tuto.js";
import AppContext from "../../../../../context/AppContext.js";

function LoyerCharges({ index }) {
  const {
    register,
    reset,
    unregister,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm();

  const inputForm = { register, setValue, watch, unregister };

  const {
    handleTabs,
    step,
    moveToNextStep,
    resetFormsRef,
    setStep,
    submitFormsMc,
    postValues,
    setGoToStep,
    getStepToGo,
    estimationValues,
    fillInputsFromEstimationValues,
  } = useContext(ParcoursContext);
  const { showTooltip } = useContext(AppContext);

  useEffect(() => {
    submitFormsMc.current[index] = handleSubmit(formSubmit);
    resetFormsRef.current[index] = () => reset();
  }, [step, estimationValues]);

  useEffect(() => {
    fillInputsFromEstimationValues(["loyer_charges"], setValue, estimationValues);
    setValue("notes.note_step_6", estimationValues?.notes?.note_step_6);
  }, [estimationValues]);

  async function formSubmit(values) {
    await postValues(formatObjectForPosting(values));

    if (getStepToGo() != 0) {
      setStep(getStepToGo());
      setGoToStep(0);
    } else moveToNextStep(index);
  }
  return (
    <>
      <form onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? "auto" : "0" }}>
        <fieldset className='borderless-fieldset'>
          <h2>loyer & charges</h2>
          <div className='row-1000 mb-sm'>
            <NumberInput
              useForm={inputForm}
              icon='euro'
              validation={loyerValidation}
              label={
                <>
                  <div>
                    montant du loyer&nbsp; <span className='bold-label'>annuel</span> &nbsp;(hors charges, hors taxe
                    foncière)
                  </div>
                  <Tuto onClick={() => showTooltip("step_6_murs_montant_loyer_charges_annuel.webp")} />
                </>
              }
              name='loyer_charges.loyer_annuel'
              error={errors?.loyer_charges?.loyer_annuel?.message}
            />
            <NumberInput
              useForm={inputForm}
              icon='euro'
              label={
                <>
                  <div>
                    montant des dernières charges locatives&nbsp; <span className='bold-label'>annuelles</span>
                  </div>
                  <Tuto onClick={() => showTooltip("step_10_montant_charges_locatives_annuelles.webp")} />
                </>
              }
              name='loyer_charges.charges_locatives_annuelles'
              error={errors?.loyer_charges?.charges_locatives_annuelles?.message}
            />
          </div>
          <NumberInput
            useForm={inputForm}
            icon='euro'
            label={
              <>
                montant de la dernière taxe foncière
                <Tuto onClick={() => showTooltip("step_10_montant_taxe_fonciere.webp")} />
              </>
            }
            name='loyer_charges.taxe_fonciere'
            error={errors?.loyer_charges?.taxe_fonciere?.message}
          />
          <Textarea
            useForm={inputForm}
            name='notes.note_step_6'
            validation={max2250Validation}
            error={errors?.notes?.note_step_6?.message}
            className='mt-md'
            label='notes et remarques'
            {...handleTabs(step, index, () => handleSubmit(formSubmit)())}
          />
        </fieldset>
      </form>
    </>
  );
}

export default LoyerCharges;
