import React, { useContext } from "react";
import Input from "../../../../molecules/formComponents/input/Input";
import { passwordValidation } from "../../../../../utils/formValidation/FormValidation";
import FilledButton from "../../../../molecules/buttons/filledButton/FilledButton";
import { createPasswordApi } from "../../../../../api/AccountApi";
import { useForm } from "react-hook-form";
import AppContext from "../../../../../context/AppContext";
import PasswordInput from "../../../../molecules/formComponents/passwordInput/PasswordInput";

function CreatePasswordModal({ userUuid, navigate = () => {}, setUuid = () => {} }) {
  const {
    register,
    unregister,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const inputForm = { register, unregister, watch, setValue };

  const { setModalVisible, createNotification } = useContext(AppContext);

  async function formSubmit(values) {
    setModalVisible(false);

    try {
      const res = (await createPasswordApi(userUuid, values.password)).data;

      createNotification(<>Votre mot de passe a été enregistré avec succès.</>);
      setUuid(res.uuid);
      navigate("/mon-compte");
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de l'enregistrement de votre mot de passe. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  return (
    <form onSubmit={handleSubmit(formSubmit)}>
      <p className='my-lg'>
        Votre compte a été créé par un administrateur.
        <br /> Pour l'utiliser, veuillez vous créer un mot de passe personnel.
      </p>
      <PasswordInput
        useForm={inputForm}
        showTooltip
        error={errors?.password?.message}
        name='password'
        label='Mot de passe*'
        watch={watch}
      />
      <PasswordInput
        useForm={inputForm}
        isPasswordConfirm
        error={errors?.password_confirm?.message}
        name='password_confirm'
        label='Confirmez votre mot de passe'
        watch={watch}
      />
      <div className='modal-buttons-row'>
        <FilledButton type='submit'>Créer mon mot de passe</FilledButton>
      </div>
    </form>
  );
}

export default CreatePasswordModal;
