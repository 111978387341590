import { useParams } from "react-router-dom";
import "./CrmClientView.css";
import React, { useContext, useEffect, useState } from "react";
import User from "../../../../components/atoms/icons/general/user/User";
import Subscription from "../../../../components/atoms/icons/crm/subscription/Subscription";
import Folder from "../../../../components/atoms/icons/folder/folder/Folder";
import Emc from "../../../../components/atoms/icons/header/emc/Emc";
import Rapport from "../../../../components/atoms/icons/header/rapport/Rapport";
import Chart from "../../../../components/atoms/icons/header/chart/Chart";
import VisitingCard from "../../../../components/atoms/icons/crm/visitingCard/VisitingCard";
import ActivityLog from "../../../../components/atoms/icons/crm/activityLog/ActivityLog";
import Dashboard from "../../../../components/atoms/icons/folder/dashboard/Dashboard";
import CrmClientSubscription from "../../../../components/crmComponents/client/crmClientSubscription/CrmClientSubscription";
import CrmClientInfos from "../../../../components/crmComponents/client/crmClientInfos/CrmClientInfos";
import CrmClientItems from "../../../../components/crmComponents/client/crmClientItems/CrmClientItems";
import AppContext from "../../../../context/AppContext";
import { fetchUserEstimationsApi } from "../../../../api/EstimationApi";
import { stringToDate } from "../../../../utils/Utils";
import { fetchUserFoldersApi } from "../../../../api/FolderApi";
import Loader from "../../../../components/organisms/loader/Loader";
import { fetchAllFinancingPlanApi } from "../../../../api/FinancingPlanApi";
import { fetchAllCreditSimulatorsApi } from "../../../../api/CreditSimulatorApi";
import { fetchClientByUuidApi, fetchClientNotesApi, fetchClientSubscriptionApi } from "../../../../api/CrmClientsApi";
import { fetchUserDataApi } from "../../../../api/AccountApi";
import { use } from "react";
import { fetchVisitingCardsByUuidApi } from "../../../../api/VisitingCardApi";
import { fetchClientBillingLinkApi } from "../../../../api/CrmSubscriptionApi";
import { useCrm } from "../../../../context/CrmContext";
import { fetchPricesWithUuidApi } from "../../../../api/TarifsApi";

function CrmClientView() {
  const { createNotification } = useContext(AppContext);
  const { getClientPaymentStatus } = useCrm();

  const [activeTabIndex, setActiveTabIndex] = useState(1);
  const [tabs, setTabs] = useState([
    {
      id: 1,
      label: "Infos générales",
      icon: User,
    },
    {
      id: 2,
      label: "Abonnement",
      icon: Subscription,
    },
    {
      id: 4,
      label: "Estimations",
      icon: Emc,
    },
    {
      id: 5,
      label: "Plans de financement",
      icon: Rapport,
    },
    {
      id: 6,
      label: "Simulations de prêt",
      icon: Chart,
    },
    {
      id: 3,
      label: "Dossiers",
      icon: Folder,
    },
    {
      id: 7,
      label: "Cartes de visite",
      icon: VisitingCard,
    },
    {
      id: 8,
      label: "Journal d'activité",
      icon: ActivityLog,
    },
  ]);
  const [activeClient, setActiveClient] = useState({});
  const [isLoaderVisible, setIsLoaderVisible] = useState(true);
  const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false);

  const [typesTable, setTypesTable] = useState([
    { index: 1, description: "infos", action: fetchClientInfos },
    { index: 2, description: "subscription", action: fetchClientSubscription },
    { index: 3, description: "folders", action: fetchClientFolders },
    { index: 4, description: "estimations", action: fetchClientEstimations },
    { index: 5, description: "financingPlans", action: fetchClientFinancingPlans },
    { index: 6, description: "creditSimulations", action: fetchClientCreditSimulations },
    { index: 7, description: "visitingCards", action: fetchClientVisitingCards },
    { index: 8, description: "activityLog", action: fetchClientActivityLog },
    { index: 9, description: "tarifs", action: fetchClientTarifs },
  ]);

  const { uuid } = useParams();

  useEffect(() => {
    document.addEventListener("click", handleCloseMobileMenu);

    return () => {
      document.removeEventListener("click", handleCloseMobileMenu);
    };
  }, []);

  useEffect(() => {
    fetchClientData(uuid);
  }, [uuid]);

  useEffect(() => {
    setTabs([
      {
        id: 1,
        label: "Infos générales",
        icon: User,
      },
      {
        id: 2,
        label: `Abonnement (${activeClient.subscription?.current?.plan ?? "Aucun abonnement"})`,
        icon: Subscription,
      },
      {
        id: 4,
        label: `Estimations (${activeClient.estimations?.length ?? 0})`,
        icon: Emc,
      },
      {
        id: 5,
        label: `Plans de financement (${activeClient.financingPlans?.length ?? 0})`,
        icon: Rapport,
      },
      {
        id: 6,
        label: `Simulations de prêt (${activeClient.creditSimulations?.length ?? 0})`,
        icon: Chart,
      },
      {
        id: 3,
        label: `Dossiers (${activeClient.folders?.length ?? 0})`,
        icon: Dashboard,
      },
      {
        id: 7,
        label: `Cartes de visite (${activeClient.visitingCards?.length ?? 0})`,
        icon: VisitingCard,
      },
      {
        id: 8,
        label: "Journal d'activité",
        icon: ActivityLog,
      },
    ]);
  }, [activeClient]);

  async function fetchClientData(uuid) {
    const promises = typesTable.map(async type => {
      const result = await type.action(uuid);
      return { [type.description]: result };
    });

    const resultsArray = await Promise.all(promises);
    const results = resultsArray.reduce((acc, curr) => ({ ...acc, ...curr }), {});

    console.log("user data", results);

    setActiveClient(results);

    setTimeout(() => {
      setIsLoaderVisible(false);
    }, 200);
  }

  async function fetchClientInfos(uuid) {
    let result = {};

    try {
      const notes = (await fetchClientNotesApi(uuid)).data;
      const clientData = (await fetchClientByUuidApi(uuid)).data;
      result = {
        ...clientData,
        notes: notes,
        date_creation: clientData.date_creation.slice(0, 16),
        date_last_connexion: clientData.date_last_connexion?.slice(0, 16),
        date_last_update: clientData.date_last_update?.slice(0, 16),
      };
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la récupération des infos client. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue",
      );
    } finally {
      return result;
    }
  }

  async function fetchClientTarifs(uuid) {
    let result = {};

    try {
      result = (await fetchPricesWithUuidApi(uuid)).data;
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la récupération des tarifs du client. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue",
      );
    } finally {
      return result;
    }
  }

  async function fetchClientSubscription(uuid) {
    let result = [];

    try {
      const res = (await fetchClientSubscriptionApi(uuid)).data;
      const billingLink = (await fetchClientBillingLinkApi(uuid, window.location.href)).data;

      if (res.subscriptions)
        res.subscriptions.history = res.subscriptions.history
          .sort((a, b) => stringToDate(a.date_start_sub) - stringToDate(b.date_start_sub))
          .map(subscription => ({
            ...subscription,
            date_start_sub: subscription.date_start_sub.slice(0, 16),
            date_end_sub: subscription.date_end_sub.slice(0, 16),
            date_update_sub: subscription.date_update_sub?.slice(0, 16),
          }));

      if (res.subscriptions?.current)
        res.subscriptions.current = {
          ...res.subscriptions.current,
          date_start_sub: res.subscriptions.current.date_start_sub?.slice(0, 16),
          date_end_sub: res.subscriptions.current.date_end_sub?.slice(0, 16),
          date_update_sub: res.subscriptions.current.date_update_sub?.slice(0, 16),
        };

      result = { ...res.subscriptions, customer_id: res.customer_id, billingLink: billingLink };
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la récupération de l'abonnement du client. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue",
      );
    } finally {
      return result;
    }
  }

  async function fetchClientFolders(uuid) {
    let result = [];
    try {
      let res = (await fetchUserFoldersApi(uuid)).data;

      res = res.sort((a, b) => stringToDate(b.folder.data.date_creation) - stringToDate(a.folder.data.date_creation));

      result = res.map(folder => ({
        ...folder.folder.data,
        uuid: folder.folder.data.folder_id,
        date_creation: folder.folder.data.date_creation.slice(0, 16),
        date_last_update: folder.folder.data.date_last_update?.slice(0, 16),
        credit_simulators_count: folder.folder.credit_simulators.length,
        estimations_count:
          folder.folder.estimations.fonds_de_commerce.length +
          folder.folder.estimations.murs_commerciaux.length +
          folder.folder.estimations.titres_de_societe.length,
        financing_plans_count:
          folder.folder.financing_plans.financing_plan_fdc.length +
          folder.folder.financing_plans.financing_plan_murs.length,
      }));
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la récupération des dossiers du client. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue",
      );
    } finally {
      return result;
    }
  }

  async function fetchClientEstimations(uuid) {
    let result = [];
    try {
      let res = (await fetchUserEstimationsApi(uuid)).data;

      res = [...res["Fonds de commerce"], ...res["Murs commerciaux"], ...res["Titres de société"]];
      res.sort((a, b) => stringToDate(b.date_creation_estimation) - stringToDate(a.date_creation_estimation));

      result = res.map(estimation => ({
        ...estimation,
        uuid: estimation.id,
        date_creation_estimation: estimation.date_creation_estimation.slice(0, 16),
        date_last_update_estimation: estimation.date_last_update_estimation?.slice(0, 16),
      }));
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la récupération des estimations. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue",
      );
    } finally {
      return result;
    }
  }

  async function fetchClientFinancingPlans(uuid) {
    let result = [];
    try {
      const res = (await fetchAllFinancingPlanApi(uuid)).data;

      result = [...res.financing_plan_fdc, ...res.financing_plan_murs];

      result.sort((a, b) => stringToDate(b.date_creation) - stringToDate(a.date_creation));

      result = result.map(plan => ({
        date_creation: plan.date_creation.slice(0, 16),
        date_last_update: plan.date_last_update?.slice(0, 16),
        estimation_name: plan.estimation_data?.estimation_name || "-",
        uuid: plan.financing_plan_id,
        name: plan.financing_plan_name,
        type:
          plan.financing_plan_type === "fdc"
            ? {
                id: 1,
                description: "Fonds de commerce",
              }
            : {
                id: 3,
                description: "Murs commerciaux",
              },
        archived: plan.archived,
      }));
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la récupération des plans de financement. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue",
      );
    } finally {
      return result;
    }
  }

  async function fetchClientCreditSimulations(uuid) {
    let result = [];
    try {
      result = (await fetchAllCreditSimulatorsApi(uuid)).data;
      result.sort((a, b) => stringToDate(b.date_creation) - stringToDate(a.date_creation));
      result = result.map(simulation => ({
        ...simulation,
        uuid: simulation.credit_id,
        name: simulation.credit_name,
        date_creation: simulation.date_creation.slice(0, 16),
      }));
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la récupération des simulations de prêt. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue",
      );
    } finally {
      return result;
    }
  }

  async function fetchClientVisitingCards(uuid) {
    let result = [];

    try {
      const res = (await fetchVisitingCardsByUuidApi(uuid)).data;

      result = res.map(card => ({
        ...card,
        uuid: card.id,
        name: card.card_name,
        card_type_id: card.card_type === "created_card" ? 1 : 0,
        date_creation: card.date_creation_visiting_card?.slice(0, 16),
        date_last_update: card.date_last_update_visiting_card?.slice(0, 16),
      }));
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la récupération des cartes de visite du client. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue",
      );
    } finally {
      return result;
    }
  }

  async function fetchClientActivityLog(uuid) {
    try {
      // TODO
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la récupération du journal d'activité du client. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue",
      );
    }
  }

  async function updateClientData(typeIndex) {
    const type = getItemType(typeIndex);
    const result = await type.action(uuid);

    setActiveClient(value => ({ ...value, [type.description]: result }));
  }

  function getItemType(typeIndex) {
    return typesTable.find(item => item.index === typeIndex);
  }

  function handleCloseMobileMenu() {
    setIsMobileMenuOpened(false);
  }

  function showMobileMenu(e) {
    e.stopPropagation();

    setIsMobileMenuOpened(value => !value);
  }

  function getSubscriptionTagClassName() {
    switch (getClientPaymentStatus(activeClient.subscription)) {
      case "Echec paiement":
        return "red-circle crm-circle";
      case "Résiliation en cours":
        return "orange-circle crm-circle";
      case "Résilié":
        return "yellow-circle crm-circle";
      case "-":
        return "grey-circle crm-circle";
      case "Actif":
        return "green-circle crm-circle";
    }
  }

  return (
    <div className='container page-container'>
      <div className='crm-client-view-container'>
        <div className={`crm-client-view-menu ${isMobileMenuOpened ? "active" : ""}`}>
          <div className={`crm-client-view-mobile-menu ${isMobileMenuOpened ? "active" : ""}`} onClick={showMobileMenu}>
            <div />
            <div />
            <div />
          </div>
          <div className='crm-client-view-menu-header'>
            <h2 className='crm-h2'>
              {activeClient.infos?.last_name} {activeClient.infos?.first_name || "Utilisateur"}
            </h2>
          </div>
          {tabs.map(tab => (
            <div
              key={tab.id}
              className={`crm-client-view-menu-item ${activeTabIndex === tab.id ? "active" : ""} ${tab.id === 2 ? "crm-" + getSubscriptionTagClassName() : ""}`}
              onClick={() => setActiveTabIndex(tab.id)}>
              <tab.icon width='30px' color='var(--dark-blue)' />
              {tab.label}
            </div>
          ))}
        </div>
        <div className='crm-items-container'>
          <Loader visible={isLoaderVisible} className='crm-loader' preventScrolling={false} />
          {activeTabIndex === 1 && (
            <CrmClientInfos
              clientTarifs={activeClient.tarifs}
              updateActiveClientTrigger={() => updateClientData(activeTabIndex)}
              clientInfos={activeClient.infos}
            />
          )}
          {activeTabIndex === 2 && (
            <CrmClientSubscription
              isClientBlocked={activeClient?.infos?.is_blocked?.status}
              updateActiveClientTrigger={() => updateClientData(activeTabIndex)}
              updateClientInfosTrigger={() => updateClientData(1)}
              clientSubscription={activeClient.subscription}
            />
          )}
          {activeTabIndex > 2 && activeTabIndex < 8 && (
            <CrmClientItems
              updateActiveClientTrigger={() => updateClientData(activeTabIndex)}
              type={getItemType(activeTabIndex).description}
              items={activeClient[getItemType(activeTabIndex).description]}
              updateClientItemsTrigger={index => updateClientData(index)}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default CrmClientView;
