import "./Tarifs.css";
import React, { useContext } from "react";
import Breadcrumbs from "../../../components/molecules/breadcrumbs/Breadcrumbs";
import Banner from "../../../components/molecules/banner/Banner";
import Crown from "../../../components/atoms/icons/tarifs/crown/Crown";
import { useState } from "react";
import { useEffect } from "react";
import FilledButton from "../../../components/molecules/buttons/filledButton/FilledButton";
import Check from "../../../components/atoms/icons/general/check/Check";
import { useWindowSize } from "@uidotdev/usehooks";
import RapportsRow from "../../../components/organisms/rapportsRow/RapportsRow";
import { useCart } from "../../../context/CartContext";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { fetchPricesApi, fetchPricesWithUuidApi } from "../../../api/TarifsApi";
import { useAuth } from "../../../context/AuthContext";
import AppContext from "../../../context/AppContext";

function Tarifs() {
  const [discount, setDiscount] = useState({});
  const [normalPrices, setNormalPrices] = useState({});
  const [prices, setPrices] = useState([]);

  const { width } = useWindowSize();

  const { getUuid } = useAuth();
  const { addToCart } = useCart();
  const { setBannerConfig } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    fetchPrices();
    fetchDiscounts();

    setBannerConfig({
      title: "Nos offres",
    });
  }, []);

  async function fetchPrices() {
    try {
      const userUuid = getUuid();
      let res;

      if (userUuid) res = (await fetchPricesWithUuidApi(userUuid)).data;
      else res = (await fetchPricesApi()).data;

      setPrices(res);
      // setNormalPrices({
      //   liberty: res[0],
      //   maestro: res[1],
      //   optimum: res[2],
      // });
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la récupération des tarifs. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  async function fetchDiscounts() {
    //  setDiscount({
    //    liberty: {
    //       discountRate: '-40%',
    //       finalPrice: '59€',
    //    },
    //    maestro: {
    //       discountRate: '-35%',
    //       finalPrice: '52€',
    //    },
    //    optimum: {
    //       discountRate: '-35%',
    //       finalPrice: '52€',
    //    },
    // });
  }

  function pickTarif(price) {
    addToCart({
      name: price.price_name,
      price: price.base_price_by_month + "€",
      discount: price.discount?.discounted_price_by_month ? price.discount.discounted_price_by_month + "€" : null,
      offerUuid: price.price_id,
    });
    navigate("/panier");
  }

  const Tarif = ({ price, engagment, color }) => {
    return (
      <div className={price.price_name.toLowerCase() + "-tarif tarif-container"} style={{ "--color": color }}>
        {name === "maestro" && <Crown className='maestro-crown' />}
        <div className='tarif-title'>
          {price.price_name}
          {price.discount && <div className='tarif-discount'>{price.discount.discount_value}</div>}
        </div>
        <div className='tarif-content'>
          <div className='price-row'>
            {price.discount ? (
              <>
                <p className='tarif-price tarif-with-discount'>
                  {price.price_name === "maestro" ? (
                    <>
                      <span className='maestro-price'>{price.discount.discounted_price_by_month}</span>
                      <span className='normal-price-crossed'>{price.price_name}</span>
                    </>
                  ) : (
                    <>
                      <span className='normal-price-crossed'>{price.price_name}</span>
                      {price.discount.discounted_price_by_month}
                    </>
                  )}
                </p>
                <p className='tarif-duration with-promo'>ht/mois</p>
              </>
            ) : (
              <>
                <p className='tarif-price'>
                  {price.base_price_by_month}€{price.price_name === "maestro" ? <br /> : <>&nbsp;</>}
                  <span className='tarif-duration'>ht/mois</span>
                </p>
              </>
            )}
          </div>
          <p className='tarif-engagment'>
            {engagment[0]}
            <br />
            <span>{engagment[1]}</span>
            <br />
            {engagment[2]}
          </p>
          <div className='centered tarif-activate-button'>
            <FilledButton
              hoverColor='var(--dark-blue)'
              fontSize={width > 1200 ? "1.3rem" : "1.3rem"}
              color={name === "maestro" ? "var(--white)" : "var(--dark-blue)"}
              padding='5px 15px'
              bgColor={color}
              onClick={() => pickTarif(price)}>
              Activer
            </FilledButton>
          </div>
          <div className='centered'>
            <p className='beta-tarif'>
              <Link target='_blank' to='https://estimermoncommerce.fr/offres' className='tarif-v1'>
                Pour activer cette offre
                <br />
                accédez à la version classique du site
              </Link>
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <section className='container page-container'>
        <div className='tarifs-page-container'>
          {prices.length && (
            <div className='relative'>
              <div className='tarifs-row'>
                <Tarif price={prices[0]} engagment={["Engagement", "12 MOIS", "Paiement mensuel"]} color='#180047' />
              </div>
              <div className='tarifs-row'>
                <Tarif price={prices[1]} engagment={["", "MENSUEL", "Sans engagement"]} color='#D9E1FF' />
                {width > 992 && <div className='empty-tarif-container' />}
                <Tarif
                  price={prices[2]}
                  engagment={["Engagement", "12 MOIS", "Paiement annuel en 1 fois"]}
                  color='var(--gold)'
                />
              </div>
            </div>
          )}
          <div className='tarifs-detail'>
            <p className='tarif-detail-title'>Toutes nos offres incluent :</p>
            <div className='row-1000'>
              <div className='row-500'>
                <div className='centered'>
                  <Check width='25px' />
                  <p>Nombre illimité d'estimations</p>
                </div>
                <div className='centered'>
                  <Check width='25px' />
                  <p>Estimations modifiables à volonté</p>
                </div>
              </div>
              <div className='row-500'>
                <div className='centered'>
                  <Check width='25px' />
                  <p>Personnalisation de vos rapports</p>
                </div>
                <div className='centered'>
                  <Check width='25px' />
                  <p>Accès illimité à tous nos outils</p>
                </div>
              </div>
            </div>
          </div>
          <p className='mt-lg text-center'>
            Votre offre se renouvelle automatiquement à sa date d'échéance (mois ou année), sauf si vous choisissez de
            la résilier avant.
          </p>
          <p className='text-center my-xl outfit-bold text-xxl'>Exemples de rapports</p>
        </div>
      </section>
      <RapportsRow className='tarifs-rapports-row' />
    </>
  );
}

export default Tarifs;
