import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { headerSEOValues } from "../../../uploads/headerSEOValues";
import { useLocation } from "react-router-dom";

function HeaderSEO({ specialValue, setSpecialValue }) {
  const [activePageSEO, setActivePageSEO] = useState(headerSEOValues["/"]);

  const location = useLocation();

  const defaultBreadcrumb = {
    name: "Accueil, fonds de commerce, titres de société, murs commerciaux",
    item: "/",
  };

  useEffect(() => {
    let pathname = location.pathname;

    if (pathname.includes("/plan-financement")) pathname = "/plan-financement";
    if (pathname.includes("/conseils-pros/") && pathname.length > 15) pathname = "/conseils-pros/:article";
    if (pathname.includes("/guides-agents-immos/") && pathname.length > 21) pathname = "/guides-agents-immos/:article";
    if (pathname.includes("/synthese")) pathname = "/synthese";
    if (pathname.includes("/mon-compte")) pathname = "/mon-compte";

    if (!headerSEOValues[pathname]) return console.warn("Aucun Header SEO pour cette URL");

    if (headerSEOValues[pathname].useSpecialValue && specialValue) setActivePageSEO(specialValue);
    else {
      setActivePageSEO(headerSEOValues[pathname]);
      setSpecialValue(null);
    }
  }, [location, specialValue]);

  return (
    <Helmet>
      <meta charset='UTF-8' />
      <meta http-equiv='X-UA-Compatible' content='IE=edge' />
      <meta name='content-language' content='fr' />
      <meta name='viewport' content='width=device-width, initial-scale=1.0' />
      <meta name='author' content={activePageSEO?.author} />
      <meta name='theme-color' content='#252b41' />

      <title>{activePageSEO?.title}</title>
      <meta name='description' content={activePageSEO?.description} />

      <meta property='og:type' content='website' />
      <meta property='og:title' content={activePageSEO?.title} />
      <meta property='og:description' content={activePageSEO?.description} />
      <meta property='og:image' content={activePageSEO?.image} />
      <meta property='og:image:width' content='1200' />
      <meta property='og:image:height' content='286' />
      <meta property='og:url' content={process.env.REACT_APP_URL + activePageSEO?.url} />

      <meta name='twitter:title' content={activePageSEO?.title} />
      <meta name='twitter:description' content={activePageSEO?.description} />
      <meta name='twitter:image' content={activePageSEO?.image} />
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:site' content='@mon_twitter' />

      {/* index, follow ||noindex, nofollow */}
      <meta name='robots' content={activePageSEO?.robots} />
      <link rel='canonical' href={process.env.REACT_APP_URL + activePageSEO?.url} />

      {/* follow ||nofollow */}
      <link rel={activePageSEO?.follow} href={process.env.REACT_APP_URL + activePageSEO?.url} />

      <link rel='icon' type='image/png' href='./assets/img/Favicon.svg' />

      <script type='application/ld+json'>
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": activePageSEO?.author,
          "url": process.env.REACT_APP_URL + activePageSEO?.url,
          "logo": activePageSEO?.image,
        })}
      </script>

      <script type='application/ld+json'>
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [defaultBreadcrumb, ...(activePageSEO?.breadcrumb ? activePageSEO?.breadcrumb : [])]
            .map(
              (route, index) =>
                route && {
                  "@type": "ListItem",
                  "position": index + 1,
                  "name": route.name,
                  "item": process.env.REACT_APP_URL + route.item,
                },
            )
            .filter(route => route),
        })}
      </script>

      {activePageSEO.faq && (
        <script type='application/ld+json'>
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": activePageSEO.faq.map(entity => ({
              "@type": "Question",
              "name": entity.title,
              "acceptedAnswer": {
                "@type": "Answer",
                "text": entity.answer,
              },
            })),
          })}
        </script>
      )}
    </Helmet>
  );
}

export default HeaderSEO;
