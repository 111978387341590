import ReactPlayer from "react-player";
import "./IncrustedVideo.css";
import React, { useEffect, useRef, useState } from "react";
import UnfilledButton from "../../molecules/buttons/unfilledButton/UnfilledButton";
import FilledButton from "../../molecules/buttons/filledButton/FilledButton";
import { useWindowSize } from "@uidotdev/usehooks";

function IncrustedVideo({
  className = "",
  videoUri,
  onWatchLater = () => {},
  onCloseVideo = () => {},
  isVideoVisible,
  defaultVideoSize = 500,
}) {
  const [isResizing, setIsResizing] = useState(false);
  const [videoWidth, setVideoWidth] = useState(defaultVideoSize + "px");
  const [mousePosition, setMousePosition] = useState({
    lastX: null,
    lastY: null,
    updatedSize: 0,
  });
  const videoContainerRef = useRef(null);
  const videoRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mouseup", handleStopResizing);
    document.addEventListener("touchend", handleStopResizing);

    return () => {
      document.removeEventListener("mouseup", handleStopResizing);
      document.removeEventListener("touchend", handleStopResizing);
    };
  }, []);

  useEffect(() => {
    if (mousePosition.updatedSize === 0) return setVideoWidth(defaultVideoSize + "px");

    setVideoWidth(defaultVideoSize + mousePosition.updatedSize + "px");
  }, [mousePosition]);

  function handleStartResizing(e) {
    e.preventDefault();
    e.stopPropagation();

    setIsResizing(true);
  }

  function handleStopResizing() {
    setIsResizing(false);
    setMousePosition(value => ({
      lastX: null,
      lastY: null,
      updatedSize: value.updatedSize,
    }));
  }

  function handleResizing(e, isResizing, mousePosition) {
    if (!isResizing) return;

    e.preventDefault();
    e.stopPropagation();

    const prevMousePosition = mousePosition;

    if (prevMousePosition.lastX === null)
      return setMousePosition({
        lastX: e.screenX,
        lastY: e.screenY,
        updatedSize: prevMousePosition.updatedSize,
      });

    let largetMovement;

    if (Math.abs(prevMousePosition.lastX - e.screenX) > Math.abs(prevMousePosition.lastY - e.screenY))
      largetMovement = (prevMousePosition.lastX - e.screenX) * 1.05;
    else largetMovement = (prevMousePosition.lastY - e.screenY) * 1.45;

    setMousePosition({
      lastX: e.screenX,
      lastY: e.screenY,
      updatedSize: Math.max(-200, prevMousePosition.updatedSize + largetMovement),
    });
  }

  return (
    <div
      className={`inscrusted-video-container ${className} ${isVideoVisible ? "active" : ""}`}
      onMouseMove={e => handleResizing(e, isResizing, mousePosition)}
      onTouchMove={e => handleResizing(e, isResizing, mousePosition)}
      ref={videoContainerRef}>
      <div
        className='incrusted-video-resizer-container'
        onTouchStart={handleStartResizing}
        onMouseDown={handleStartResizing}>
        <div className='incrusted-video-resizer'>
          <div />
          <div />
          <div />
        </div>
      </div>
      <ReactPlayer
        width={`min(calc(100vw - 78px), ${videoWidth})`}
        height='auto'
        ref={videoRef}
        url={videoUri}
        config={{
          file: {
            attributes: {
              controlsList: "nodownload",
              controls: true,
              preload: "auto",
              disablePictureInPicture: true,
            },
          },
        }}
      />
      <div className='incrusted-video-buttons'>
        <UnfilledButton
          onClick={onWatchLater}
          // bgColor='var(--white)'
          // hoverColor='var(--blue)'
          padding='5px 10px'
          fontSize='0.8rem'
          borderRadius='3px'>
          Regarder plus tard
        </UnfilledButton>
        <FilledButton
          onClick={onCloseVideo}
          // bgColor='var(--white)'
          // hoverColor='var(--white)'
          // color='var(--blue)'
          padding='5px 10px'
          fontSize='0.8rem'
          borderRadius='3px'>
          J'ai compris
        </FilledButton>
      </div>
    </div>
  );
}

export default IncrustedVideo;
